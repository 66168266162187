import {
  Code,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-fetching-library';
import { BiCodeCurly, BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { getDevice, getDeviceConfigs, getDeviceStates } from '../../apis';
import { PageContent, PageHeader } from '../../layout';
import { Pre } from '../../shared';
import { GcpUtils, tryParse } from '../../lib';

export const GcpDeviceDetails: React.FC = () => {
  const { deviceId, registryId } =
    useParams<{ deviceId: string; registryId: string }>();
  const getDeviceQuery = getDevice(registryId, deviceId);
  const getDeviceConfigsQuery = getDeviceConfigs(registryId, deviceId);
  const getDeviceStatesQuery = getDeviceStates(registryId, deviceId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    payload: device,
    query: refreshDevice,
    loading: isRefreshingDevice,
  } = useQuery(getDeviceQuery);
  const { payload: configs, query: refreshConfigs } = useQuery(
    getDeviceConfigsQuery
  );
  const { payload: states, query: refreshStates } =
    useQuery(getDeviceStatesQuery);

  if (!device) return null;

  /* <Breadcrumbs
            crumbs={[
              { title: 'IoT' },
              { title: 'Registries' },
              { title: 'Registry', subtitle: ({ registryId: id }) => id },
              { title: 'Device', subtitle: ({ deviceId: id }) => id },
            ]}
          /> */

  return (
    <>
      <PageHeader heading="Device">
        <Spacer />
        <HStack spacing={[2, 4, 8]}>
          <IconButton
            aria-label="refresh device"
            icon={<Icon as={BiRefresh} boxSize={7} />}
            size="md"
            colorScheme="gray"
            isLoading={isRefreshingDevice}
            onClick={refreshDevice}
            variant="ghost"
          />
          <IconButton
            aria-label="show config"
            icon={<Icon as={BiCodeCurly} boxSize={7} />}
            size="md"
            colorScheme="gray"
            onClick={() => onOpen()}
            variant="ghost"
          />
        </HStack>
      </PageHeader>
      <PageContent>
        <VStack spacing={8} align="stretch">
          <Heading as="h4" size="md">
            Profile
          </Heading>

          <SimpleGrid columns={[1, 2, 3]} columnGap={6} rowGap={[4, 6, 8]}>
            <Stat>
              <StatLabel>Name</StatLabel>
              <StatNumber>
                <Code>{device.name}</Code>
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>ID</StatLabel>
              <StatNumber>
                <Code>{device.id}</Code>
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Numeric ID</StatLabel>
              <StatNumber>
                <Code>{device.numId}</Code>
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Communication</StatLabel>
              <StatNumber>{device.blocked ? 'DISABLED' : 'ENABLED'}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Log Level</StatLabel>
              <StatNumber>{device.logLevel?.toString()}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Last Heartbeat</StatLabel>
              <StatNumber>
                {GcpUtils.timestampToText(device.lastHeartbeatTime)}
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Last Event</StatLabel>
              <StatNumber>
                {GcpUtils.timestampToText(device.lastEventTime)}
              </StatNumber>
            </Stat>
          </SimpleGrid>
        </VStack>

        <VStack spacing={8} align="flex-start">
          <Heading as="h4" size="md">
            State
          </Heading>
          <Stack direction={['column', 'row']} spacing={[6, 16]}>
            <Stat>
              <StatLabel>Update Time</StatLabel>
              <StatNumber>
                {GcpUtils.timestampToText(device.state?.updateTime)}
              </StatNumber>
            </Stat>
            <Pre
              contents={tryParse<object>(
                GcpUtils.binaryDataToString(device.state?.binaryData)
              )}
            />
          </Stack>
        </VStack>

        <VStack spacing={8} align="stretch">
          <Heading as="h4" size="md">
            Config
          </Heading>
          <Stack direction={['column', 'row']} spacing={[6, 16]}>
            <VStack spacing={6} align="flex-start">
              <Stat>
                <StatLabel>Version</StatLabel>
                <StatNumber>{`${device.config?.version}`}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Cloud Update Time</StatLabel>
                <StatNumber>
                  {GcpUtils.timestampToText(device.config?.cloudUpdateTime)}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Acknowledgement Time</StatLabel>
                <StatNumber>
                  {GcpUtils.timestampToText(device.config?.deviceAckTime)}
                </StatNumber>
              </Stat>
            </VStack>
            <Pre
              contents={tryParse<object>(
                GcpUtils.binaryDataToString(device.config?.binaryData)
              )}
            />
          </Stack>
        </VStack>

        <VStack spacing={8} align="stretch">
          <Heading as="h4" size="md">
            Errors
          </Heading>
          <Stack direction={['column', 'row']} spacing={[6, 10]}>
            <Stat>
              <StatLabel>Last Error Time</StatLabel>
              <StatNumber>
                {GcpUtils.timestampToText(device.lastErrorTime)}
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Error Code</StatLabel>
              <StatNumber>{`${device.lastErrorStatus?.code}`}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Error Message</StatLabel>
              <StatNumber>{`${device.lastErrorStatus?.message}`}</StatNumber>
            </Stat>
          </Stack>
        </VStack>

        <Flex align="center">
          <Heading as="h4" size="md">
            State History
          </Heading>
          <Spacer />
          <IconButton
            aria-label="refresh device states"
            icon={<Icon as={BiRefresh} boxSize={7} />}
            size="md"
            colorScheme="gray"
            onClick={refreshStates}
            variant="ghost"
          />
        </Flex>

        <VStack spacing={[3, 6]} align="flex-start">
          {(states ?? []).map((state, i) => (
            <Stack
              key={i}
              direction={['column', 'row']}
              spacing={6}
              maxW="full"
            >
              <Stat flexBasis={['100%', '25%']}>
                <StatLabel>Update Time</StatLabel>
                <StatNumber>
                  {GcpUtils.timestampToText(state.updateTime)}
                </StatNumber>
              </Stat>
              <Pre
                hideCopyButton
                contents={
                  GcpUtils.binaryDataToString(state.binaryData) ||
                  'NO STATE DATA'
                }
              />
            </Stack>
          ))}
        </VStack>

        <Flex direction="row" align="center">
          <Heading>Config History</Heading>
          <Spacer />
          <IconButton
            aria-label="refresh device configs"
            icon={<Icon as={BiRefresh} boxSize={7} />}
            size="md"
            colorScheme="gray"
            onClick={refreshConfigs}
            variant="ghost"
          />
        </Flex>

        <Stack direction={['column', 'row']} spacing={[6, 10]}>
          <Stat>
            <StatLabel>Last Config Acknowledgement</StatLabel>
            <StatNumber>
              {GcpUtils.timestampToText(device.lastConfigAckTime)}
            </StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Last Config Send</StatLabel>
            <StatNumber>
              {GcpUtils.timestampToText(device.lastConfigSendTime)}
            </StatNumber>
          </Stat>
        </Stack>

        <VStack spacing={[6, 12]} align="flex-start">
          {(configs ?? []).map(config => (
            <Stack
              key={`${config.version}`}
              direction={['column', 'row']}
              spacing={[6, 16]}
            >
              <VStack spacing={6} align="flex-start">
                <Stat>
                  <StatLabel>Version</StatLabel>
                  <StatNumber>{`${config.version}`}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Update Time</StatLabel>
                  <StatNumber>
                    {GcpUtils.timestampToText(config.cloudUpdateTime)}
                  </StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Acknowledgement Time</StatLabel>
                  <StatNumber>
                    {GcpUtils.timestampToText(config.deviceAckTime)}
                  </StatNumber>
                </Stat>
              </VStack>
              <Pre
                contents={
                  GcpUtils.binaryDataToString(config.binaryData) ||
                  'NO CONFIG DATA'
                }
              />
            </Stack>
          ))}
        </VStack>
      </PageContent>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Device Config</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Pre contents={device} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
