import {
  chakra,
  HTMLChakraProps,
  SystemProps,
  SystemStyleObject,
  useStyles,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { CheckIcon, OptionIcon } from './OptionIcon';

export const OptionComponentId = '__OPTION__';

interface Props extends Omit<HTMLChakraProps<'button'>, 'as'> {
  children?: React.ReactNode;
  icon?: React.ReactElement;
  iconSpacing?: SystemProps['mr'];
  isChecked?: boolean;
  isDisabled?: boolean;
  value?: string;
}

export const Option = forwardRef<'button', Props>(
  (
    { children, icon, iconSpacing, isChecked, isDisabled, value, ...htmlProps },
    ref
  ) => {
    const styles = useStyles();

    const buttonStyles: SystemStyleObject = {
      alignItems: 'center',
      color: 'inherit',
      cursor: 'pointer',
      display: 'flex',
      flex: '0 0 auto',
      outline: 0,
      px: '0.8rem',
      py: '0.4rem',
      textAlign: 'start',
      textDecoration: 'none',
      userSelect: 'none',
      width: '100%',
      ...styles.option,
    };

    return (
      <chakra.button
        type="button"
        __css={buttonStyles}
        disabled={isDisabled}
        ref={ref as any}
        role="option"
        aria-checked={isChecked}
        tabIndex={-1}
        {...htmlProps}
      >
        <OptionIcon
          fontSize="0.8em"
          marginEnd={iconSpacing ?? 3}
          opacity={isChecked ? 1 : 0}
        >
          {icon || <CheckIcon color="accent.500" fill="accent.500" />}
        </OptionIcon>
        <span style={{ flex: 1 }}>{children}</span>
      </chakra.button>
    );
  }
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
(Option as any).id = OptionComponentId;
