import * as ui from '@chakra-ui/react';
import React from 'react';
import { BiCaretLeft } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';

type Props = ui.FlexProps & {
  backButton?: { label: string; to: string };
  heading?: React.ReactNode;
  hideHeading?: boolean;
  children?: React.ReactNode;
};

export const PageHeader: React.FC<Props> = ({
  backButton,
  children,
  heading,
  hideHeading = false,
  ...flexProps
}) => {
  return (
    <ui.Flex
      align="center"
      as="header"
      bg="black"
      borderBottom="2px"
      borderColor="whiteAlpha.50"
      direction="row"
      h={16}
      p={4}
      pos="sticky"
      insetBlockStart={0}
      zIndex="98"
      {...flexProps}
    >
      {backButton && (
        <ui.Tooltip label={backButton.label}>
          <ui.IconButton
            as={NavLink}
            to={backButton.to}
            aria-label={backButton.label}
            colorScheme="gray"
            icon={<ui.Icon as={BiCaretLeft} boxSize={6} />}
            color="whiteAlpha.900"
            mr={2}
            size="md"
            variant="ghost"
          />
        </ui.Tooltip>
      )}
      {!hideHeading && <ui.Heading size="lg">{heading}</ui.Heading>}
      {children}
    </ui.Flex>
  );
};
