import {
  CSSObject,
  Icon,
  // IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ThemingProps,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useMutation } from 'react-fetching-library';
import {
  BiCodeCurly,
  BiDotsVertical,
  BiLinkExternal,
  BiTrashAlt,
  BiX,
} from 'react-icons/bi';
import { deleteProduct, Product } from '../../../apis';
import { useBreakpointQuery } from '../../../hooks';
import { ConfirmDelete, Pre } from '../../../shared';

type Props = {
  menuButtonProps?: MenuButtonProps & ThemingProps<'MenuButton'>;
  item: Product;
  onDeleted: () => void;
  sx?: CSSObject;
};

export const ProductMenu: React.FC<Props> = ({
  menuButtonProps = {},
  item,
  onDeleted,
  sx = {},
}) => {
  const { mutate: deleteItemMutation } = useMutation(deleteProduct);
  const isGteMd = useBreakpointQuery('md');

  const {
    isOpen: configIsOpen,
    onOpen: configOnOpen,
    onClose: configOnClose,
  } = useDisclosure();

  const deleteItem = async (_id: string) => {
    const { error } = await deleteItemMutation(_id);
    if (error) return window.alert('Failed');
    onDeleted();
  };

  return (
    <>
      <Modal
        isOpen={configIsOpen}
        onClose={configOnClose}
        size={isGteMd ? 'xl' : 'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product JSON</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Pre contents={item} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <ConfirmDelete
        header="Delete product?"
        body="Deleting a product from the catalog cannot be undone."
        onConfirm={() => deleteItem(item._id)}
      >
        {({ onOpen }) => (
          <Menu isLazy>
            {({ isOpen }) => (
              <>
                <MenuButton
                  // as={IconButton}
                  aria-label="open menu"
                  icon={<Icon as={isOpen ? BiX : BiDotsVertical} boxSize={6} />}
                  size="lg"
                  sx={{
                    transform: isOpen ? 'rotate(90deg)' : 'none',
                    ...sx,
                  }}
                  colorScheme="gray"
                  variant="ghost"
                  {...menuButtonProps}
                />
                <MenuList>
                  <MenuItem
                    icon={<BiLinkExternal size={18} />}
                    onClick={() => window.open(item.productPageUrl, '_blank')}
                  >
                    Open Product Page
                  </MenuItem>
                  <MenuItem
                    icon={<BiCodeCurly size={18} />}
                    onClick={configOnOpen}
                  >
                    Show JSON
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem icon={<BiTrashAlt size={18} />} onClick={onOpen}>
                    Delete Product
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </ConfirmDelete>
    </>
  );
};
