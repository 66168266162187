import * as ui from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DeviceAction, DeviceActionType, IotApiUtils } from '../../apis';
import { RenderInterval, timeAgoLabel } from '../../lib';

type Props = {
  action: DeviceAction;
  index: number;
};

export const ActionDetails: React.FC<Props> = ({ action, index }) => {
  const [formValue, setFormValue] = useState(action);

  // If the action from parent changes, override the form value
  useEffect(() => {
    if (formValue !== action) setFormValue(action);
  }, [action]); // eslint-disable-line

  return (
    <ui.VStack
      spacing={8}
      align="stretch"
      borderBottom="2px"
      borderColor="whiteAlpha.50"
      pb={8}
    >
      <ui.Flex direction="row" justify="space-between" align="center">
        <ui.Heading as="h4" size="md">
          Metadata
        </ui.Heading>
      </ui.Flex>
      <ui.Flex direction={['column', null, 'row']} flexGrow={1} wrap="wrap">
        <ui.Stat w="full" mb={4}>
          <ui.StatLabel>ID</ui.StatLabel>
          <ui.StatNumber>
            <ui.Code>{action.actionId}</ui.Code>
          </ui.StatNumber>
        </ui.Stat>
        <ui.Stat w={['100%', null, '25%']}>
          <ui.StatLabel>Created</ui.StatLabel>
          <ui.StatNumber>
            <RenderInterval>
              {() => timeAgoLabel(action.createdAt)}
            </RenderInterval>
          </ui.StatNumber>
        </ui.Stat>
        <ui.Stat w={['100%', null, '75%']}>
          <ui.StatLabel>Updated</ui.StatLabel>
          <ui.StatNumber>
            <RenderInterval>
              {() => timeAgoLabel(action.updatedAt)}
            </RenderInterval>
          </ui.StatNumber>
        </ui.Stat>
      </ui.Flex>
      <ui.Heading as="h4" size="md">
        {IotApiUtils.actionLabel(action.actionType)} Config
      </ui.Heading>
      <ActionConfigDetails action={action} />
    </ui.VStack>
  );
};

export const ActionConfigDetails: React.FC<{ action: DeviceAction }> = ({
  action,
}) => {
  switch (action.actionType) {
    case DeviceActionType.Noop: {
      return (
        <ui.Text>The Noop action does not have anything to configure.</ui.Text>
      );
    }
    case DeviceActionType.SendSms: {
      return (
        <ui.Flex direction={['column', null, 'row']} flexGrow={1} wrap="wrap">
          <ui.Stat w={['100%', null, '50%']} mb={4}>
            <ui.StatLabel>Name</ui.StatLabel>
            <ui.StatNumber>
              <ui.Code>{action.name}</ui.Code>
            </ui.StatNumber>
          </ui.Stat>
          <ui.Stat w={['100%', null, '50%']}>
            <ui.StatLabel>Phone Number</ui.StatLabel>
            <ui.StatNumber>{action.to}</ui.StatNumber>
          </ui.Stat>
          <ui.Stat w="full">
            <ui.StatLabel>Body</ui.StatLabel>
            <ui.StatNumber>{action.body}</ui.StatNumber>
          </ui.Stat>
        </ui.Flex>
      );
    }
  }
};
