import {
  Box,
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-fetching-library';
import { NavLink } from 'react-router-dom';
import { getUsers, useAuthedFetch } from '../../apis';
import { PageContent, PageHeader } from '../../layout';

type Props = {};

export const Profiles: React.FC<Props> = ({}) => {
  const { userId } = useAuthedFetch();
  const { payload: users = [] } = useQuery(getUsers());

  return (
    <>
      <PageHeader heading="Users" />

      <PageContent p={0}>
        <Flex direction="row" flexWrap="wrap" justify="stretch">
          {users.map(user => (
            <Box key={user._id} flexBasis={['100%', '50%', '33%']} p={4}>
              <LinkBox
                as={Flex}
                direction="column"
                align="center"
                border="2px"
                borderColor="whiteAlpha.50"
                p={8}
                sx={{
                  _hover: { borderColor: 'white' },
                  _active: { bgColor: 'whiteAlpha.50' },
                }}
              >
                <LinkOverlay as={NavLink} to={`/users/${user._id}`} />

                <VStack spacing={8} align="center">
                  <Image
                    objectFit="cover"
                    boxSize="120px"
                    src={user.imageUrl}
                  />
                  <VStack spacing={2}>
                    <Heading size="lg">{user.username}</Heading>
                    {user._id === userId && (
                      <Heading
                        size="xs"
                        fontWeight="black"
                        color="whiteAlpha.700"
                      >
                        YOU
                      </Heading>
                    )}
                  </VStack>
                </VStack>
              </LinkBox>
            </Box>
          ))}
        </Flex>
      </PageContent>
    </>
  );
};
