import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  body?: React.ReactNode;
  cancelText?: string;
  children: (props: { onOpen: () => void }) => React.ReactElement;
  confirmText?: string;
  header: React.ReactNode;
  onConfirm: () => void;
};

export const ConfirmDelete: React.FC<Props> = ({
  body,
  cancelText = 'Cancel',
  children,
  confirmText = 'Delete',
  header,
  onConfirm,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <>
      {children({ onOpen })}
      <AlertDialog
        leastDestructiveRef={cancelRef as any}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{header}</AlertDialogHeader>
          {!!body && <AlertDialogBody>{body}</AlertDialogBody>}
          <AlertDialogFooter>
            <Button
              ref={cancelRef as any}
              onClick={onClose}
              size="md"
              variant="ghost"
              colorScheme="gray"
            >
              {cancelText}
            </Button>
            <Button colorScheme="danger" ml={6} size="md" onClick={onConfirm}>
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
