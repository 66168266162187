import { VStack, StackProps } from '@chakra-ui/react';
import React from 'react';

type Props = StackProps & {
  children?: React.ReactNode;
};

export const PageContent: React.FC<Props> = ({ children, ...vStackProps }) => {
  return (
    <VStack
      as="main"
      className="page-content"
      spacing={16}
      maxW="5xl"
      align="stretch"
      pos="relative"
      p={4}
      {...vStackProps}
    >
      {children}
    </VStack>
  );
};
