import * as ui from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { MongoUtils, secondsFromDate } from 'lib';
import React, { useEffect, useState } from 'react';
import { Query, useMutation, useQuery } from 'react-fetching-library';
import { BiLinkExternal, BiScan } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  getProduct,
  getUser,
  ProductDto,
  productToDto,
  updateProduct,
} from '../../../apis';
import { PageContent, PageHeader } from '../../../layout';
import { QueryParamUtils } from '../../../lib';
import { Pre } from '../../../shared';
import { ProductForm } from '../ProductForm';
import { ProductMenu } from '../ProductMenu';
import { ProductScraper } from '../ProductScraper';

export const ProductDetails: React.FC = () => {
  const history = useHistory();
  const { id: _id } = useParams<{ id: string }>();
  const [formValue, setFormValue] = useState<ProductDto | null>(null);

  const {
    payload: item,
    error: getError,
    errorObject: getErrorObject,
    query: getItem,
  } = useQuery(getProduct(_id));

  const {
    loading: saving,
    mutate: updateItem,
    error: updateError,
    errorObject: updateErrorObject,
  } = useMutation(updateProduct);

  useEffect(() => {
    if (item) setFormValue(productToDto(item));
  }, [item]);

  const saveItem = async () => {
    if (!formValue) return;

    const { error } = await updateItem({ _id, ...formValue });
    if (!error) getItem();
  };

  return (
    <>
      <PageHeader
        backButton={{ to: '/inventorio/catalog', label: 'Back to Catalog' }}
        hideHeading
      >
        <ui.VStack align="flex-start" spacing={0.5}>
          <ui.Heading size="lg">Edit Product</ui.Heading>
        </ui.VStack>
        <ui.Spacer />
        {item && (
          <ProductMenu
            menuButtonProps={{ m: 4, size: 'md' }}
            item={item}
            onDeleted={() => history.replace(`/inventorio/catalog`)}
          />
        )}
      </PageHeader>

      {formValue && (
        <ProductScraper
          isOpen={
            'scrape' in
            QueryParamUtils.queryParamsFromSearch(history.location.search)
          }
          onClose={() => history.replace({ search: '?' })}
          onUseValue={async (k, v) => {
            const { error } = await updateItem({ _id, ...formValue, [k]: v });
            if (!error) getItem();
          }}
          value={formValue}
        />
      )}
      <PageContent align="flex-start">
        <ui.Stack
          direction={['column', null, 'row']}
          align="flex-start"
          spacing={12}
          w="full"
        >
          <ui.VStack
            spacing={8}
            maxW={['100%', null, '40%']}
            w={['100%', null, '40%']}
            minW={['100%', null, '40%']}
            alignSelf={['center', null, 'flex-start']}
            align="flex-start"
          >
            <ui.Image
              alt="product"
              pos="relative"
              objectFit="cover"
              alignSelf={['center', null, 'flex-start']}
              boxSize={['150px', '150px', '200px']}
              minW={['150px', '150px', '200px']}
              src={item?.productImageUrl}
            />

            <ui.Heading>{item?.title}</ui.Heading>

            {item && (
              <ui.VStack align="flex-start" spacing={4}>
                <ui.Link
                  as={ui.Link}
                  aria-label="open scraper"
                  fontSize="lg"
                  onClick={() =>
                    history.replace({
                      search: QueryParamUtils.searchFromQueryParams({
                        scrape: true,
                      }),
                    })
                  }
                >
                  <ui.Icon as={BiScan} boxSize={6} mr={2} />
                  Scrape Product Page
                </ui.Link>
                <ui.Link
                  aria-label="open product page"
                  target="_blank"
                  href={item.productPageUrl}
                  fontSize="lg"
                >
                  <ui.Icon as={BiLinkExternal} boxSize={6} mr={2} />
                  Go to Product Page
                </ui.Link>
              </ui.VStack>
            )}

            {item && (
              <ui.VStack align="flex-start" spacing={4}>
                <ui.Stat>
                  <ui.StatLabel>Created</ui.StatLabel>
                  <ui.StatNumber>
                    {`${formatDistanceToNow(
                      secondsFromDate(MongoUtils.objectIdToDate(item._id)),
                      {
                        addSuffix: true,
                        includeSeconds: true,
                      }
                    )} by `}
                    <Query action={getUser(item.createdBy)} initFetch>
                      {({ payload }) => (
                        <ui.Link
                          as={NavLink}
                          color="accent.500"
                          to={`/users/${item.createdBy}`}
                        >
                          {payload?.username}
                        </ui.Link>
                      )}
                    </Query>
                  </ui.StatNumber>
                </ui.Stat>
                <ui.Stat>
                  <ui.StatLabel>Updated</ui.StatLabel>
                  <ui.StatNumber>
                    {`${formatDistanceToNow(
                      secondsFromDate(new Date(item.updated)),
                      {
                        addSuffix: true,
                        includeSeconds: true,
                      }
                    )} by `}
                    <Query action={getUser(item.updatedBy)} initFetch>
                      {({ payload }) => (
                        <ui.Link
                          as={NavLink}
                          color="accent.500"
                          to={`/users/${item.updatedBy}`}
                        >
                          {payload?.username}
                        </ui.Link>
                      )}
                    </Query>
                  </ui.StatNumber>
                </ui.Stat>
              </ui.VStack>
            )}
          </ui.VStack>

          <ui.VStack align="flex-start" spacing={8} w="full">
            {getError && <Pre contents={getErrorObject} />}
            {updateError && <Pre contents={updateErrorObject} />}
            {formValue && (
              <ProductForm
                isDisabled={saving}
                isLoading={saving}
                onChange={value =>
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  setFormValue(formValue => ({ ...formValue!, ...value }))
                }
                onSave={saveItem}
                value={formValue}
              />
            )}
          </ui.VStack>
        </ui.Stack>
      </PageContent>
    </>
  );
};
