import * as ui from '@chakra-ui/react';
import React, { useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { FiActivity, FiPower, FiZap, FiZapOff } from 'react-icons/fi';
import { MdPlayArrow, MdPlayDisabled } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import {
  ActionTriggerEventStatus,
  DeviceEvent,
  DeviceEventType,
  eventStreamUrl,
  getDeviceEvents,
} from '../../apis';
import { useEventSource } from '../../hooks';
import { RenderInterval, secondsAgo, timeAgoLabel } from '../../lib';

type Props = {};

export const DeviceEventListing: React.FC<Props> = ({}) => {
  const { configId }: { configId: string } = useParams();
  const { payload: events = [] } = useQuery(getDeviceEvents(configId));
  const [newEvents, setNewEvents] = useState<DeviceEvent[]>([]);
  const { isOpen } = useEventSource(eventStreamUrl(configId), e =>
    setNewEvents(es => [JSON.parse(e.data) as DeviceEvent, ...es])
  );

  return (
    <ui.VStack align="stretch" spacing={8} flexGrow={1}>
      <ui.Flex align="center" direction="row">
        <ui.Heading size="2xl">Events</ui.Heading>
        <ui.Spacer />
        <ui.Heading
          px={4}
          py={0}
          className={isOpen ? 'fade-in-out' : ''}
          size="md"
          color={isOpen ? 'success.500' : 'danger.500'}
        >
          <ui.Icon as={isOpen ? MdPlayArrow : MdPlayDisabled} mr={4} />
          {isOpen ? 'Live' : 'Not Streaming'}
        </ui.Heading>
      </ui.Flex>

      <ui.VStack w="full" align="stretch">
        {[...newEvents, ...events].map(event => (
          <ui.Flex
            key={event.eventId}
            direction="row"
            w="full"
            bg={
              secondsAgo(new Date(event.createdAt)) < 5
                ? 'whiteAlpha.300'
                : 'transparent'
            }
            px={4}
            py={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <DeviceEventIcon {...event} />
            <ui.Stat ml={4}>
              <ui.StatNumber>
                {event.eventType} - Config v{event.configVersion}
              </ui.StatNumber>
              <ui.StatLabel>
                <RenderInterval>
                  {() => timeAgoLabel(event.createdAt)}
                </RenderInterval>
              </ui.StatLabel>
            </ui.Stat>
          </ui.Flex>
        ))}
      </ui.VStack>
    </ui.VStack>
  );
};

const DeviceEventIcon: React.FC<DeviceEvent> = event => {
  const [Icon, color] = (() => {
    switch (event.eventType) {
      case DeviceEventType.Activation:
        return [FiPower, 'success.500'];
      case DeviceEventType.ActionTrigger: {
        const success = event.status === ActionTriggerEventStatus.Success;
        return [
          success ? FiZap : FiZapOff,
          success ? 'primary.500' : 'danger.500',
        ];
      }
      case DeviceEventType.Ping:
        return [FiActivity, 'gray.400'];
    }
  })();

  return <ui.Icon color={color} boxSize={6} as={Icon} size={32} p={0} mr={1} />;
};
