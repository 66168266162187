export class MongoUtils {
  static objectIdToTimestamp(id = ''): number {
    const timestamp = parseInt(id.slice(0, 8), 16) * 1000;
    return Number.isSafeInteger(timestamp) ? timestamp : 0;
  }

  static objectIdToDate(id = ''): Date {
    const date = new Date();
    date.setTime(MongoUtils.objectIdToTimestamp(id));
    return date;
  }
}
