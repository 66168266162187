export class ReactUtils {
  static cx = (...classNames: any[]) => {
    return classNames.filter(Boolean).join(' ');
  };

  static runIfFn<T, U extends any[]>(
    valueOrFn: T | ((...fnArgs: U) => T),
    ...args: U
  ): T {
    return typeof valueOrFn === 'function'
      ? (valueOrFn as (...fnArgs: U) => T)(...args)
      : valueOrFn;
  }
}
