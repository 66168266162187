import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useHistory } from 'react-router';
import { createProduct, ProductDto } from '../../../apis';
import { PageContent, PageHeader } from '../../../layout';
import { Pre } from '../../../shared';
import { ProductForm } from '../ProductForm';

export const CreateProduct: React.FC = () => {
  const history = useHistory();
  const { loading, mutate, error, errorObject } = useMutation(createProduct);

  const [value, setValue] = useState<ProductDto>({
    productPageUrl: '',
    productImageUrl: '',
    tags: [],
    title: '',
    vendor: '',
  });

  const createItem = async () => {
    const { error, payload } = await mutate(value);
    if (error || !payload) return;
    history.replace(`/inventorio/catalog/${payload._id}`);
  };

  return (
    <>
      <PageHeader heading="New Product" />
      <PageContent>
        {error && <Pre contents={errorObject} />}

        <ProductForm
          isDisabled={loading}
          isLoading={loading}
          onChange={setValue}
          onSave={createItem}
          value={value}
        />
      </PageContent>
    </>
  );
};
