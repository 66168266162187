import { equals } from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { Product, useResourceParams, UseResourceParamsProps } from '../../apis';
import { CatalogSearchParamsContext } from './useCatalogSearchParams';

export const CatalogSearchParamsProvider: React.FC<{
  children: React.ReactNode;
  resourceParamProps: UseResourceParamsProps<Product>;
}> = ({ children, resourceParamProps }) => {
  const { params: activeParams, set: setActiveParams } =
    useResourceParams<Product>(resourceParamProps);

  const isFiltering = useMemo(
    () => Object.values(activeParams.q).filter(val => !!val).length > 0,
    [activeParams.q]
  );

  const {
    params: pendingParams,
    set: setPendingParams,
    setFilter: setPendingFilter,
  } = useResourceParams<Product>({ value: activeParams });

  // Override the pending params whenever actve params change
  useEffect(() => {
    setPendingParams(activeParams);
  }, [activeParams, setPendingParams]);

  const isFiltersEqual = useMemo(
    () => equals(pendingParams.q, activeParams.q),
    [pendingParams.q, activeParams.q]
  );

  return (
    <CatalogSearchParamsContext.Provider
      value={{
        activeParams,
        applyParams: setActiveParams,
        isFiltering,
        isFiltersEqual,
        pendingParams,
        setPendingFilter,
        setPendingParams,
      }}
    >
      {children}
    </CatalogSearchParamsContext.Provider>
  );
};
