import { cssVar, ThemeComponents } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { colors } from './colors';

export const components: ThemeComponents = {
  Alert: {
    baseStyle: {
      container: {
        border: 'none',
        borderRadius: 'none',
      },
    },
    defaultProps: {
      variant: 'solid',
    },
    variants: {
      solid: ({ colorScheme, status, ...props }) => {
        const [c, color] = (() => {
          switch (status) {
            case 'success':
              return ['success', 'black'] as const;
            case 'error':
              return ['danger', 'white'] as const;
            case 'warning':
              return ['accent', 'black'] as const;
            default:
              return ['primary', 'white'] as const;
          }
        })();

        return {
          container: {
            bg: `${c}.500`,
            color,
          },
        };
      },
    },
  },
  Badge: {
    baseStyle: {
      borderRadius: 'none',
      textTransform: 'none',
    },
    variants: {
      outline: ({ colorScheme: c }) => ({
        boxShadow: 'none',
        color: c === 'gray' ? 'white' : `${c}.500`,
        border: '2px',
        borderColor: c === 'gray' ? 'white' : `${c}.500`,
      }),
      solid: {
        display: 'none',
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: 'none',
      fontWeight: 'bold',
    },
    defaultProps: {
      colorScheme: 'primary',
      size: 'sm',
    },
    variants: {
      ghost: ({ colorScheme, ...props }) => ({
        bg: 'transparent',
        border: 'none',
        color: colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
        _hover: {
          bg: transparentize(
            colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
            0.1
          )(props.theme),
        },
        _active: {
          bg: transparentize(
            colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
            0.24
          )(props.theme),
          boxShadow: 'none',
        },
      }),
      nav: ({ colorSchema, ...props }) => ({
        fontSize: 'sm',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'superwide',
        '&:hover,&.active': {
          textDecoration: 'none',
          color: 'primary.500',
        },
        _active: {
          boxShadow: 'none',
          color: 'primary.200',
        },
      }),
      outline: ({ colorScheme, ...props }) => ({
        bg: 'transparent',
        border: '2px',
        borderColor: colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
        color: colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
        _hover: {
          bg: transparentize(
            colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
            0.1
          )(props.theme),
        },
        _active: {
          bg: transparentize(
            colorScheme === 'gray' ? 'white' : `${colorScheme}.500`,
            0.24
          )(props.theme),
          boxShadow: 'none',
        },
      }),
      solid: ({ colorScheme, ...props }) => ({
        bg: `${colorScheme}.500`,
        color: colorScheme === 'accent' ? 'black' : 'white',
        border: 'none',
        _hover: { bg: `${colorScheme}.400` },
        _active: { bg: `${colorScheme}.200`, boxShadow: 'none' },
      }),
    },
  },
  CloseButton: {
    baseStyle: {
      borderRadius: 'none',
    },
  },
  Code: {
    baseStyle: {
      borderRadius: 'none',
    },
    variants: {
      subtle: ({ colorScheme: c, theme }) => ({
        bg:
          c === 'gray'
            ? 'whiteAlpha.100'
            : transparentize(`${c}.200`, 0.15)(theme),
        color: c === 'gray' ? 'white' : `${c}.50`,
      }),
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: 'xs',
      fontWeight: 'bold',
      mb: 1.5,
    },
  },
  FormHelperText: {
    baseStyle: {
      color: 'white',
    },
  },
  Divider: {
    baseStyle: {
      borderColor: 'whiteAlpha.800',
    },
    variants: {
      solid: {
        borderBottom: '2px',
        borderBottomWidth: '2px',
        borderColor: 'whiteAlpha.800',
      },
    },
  },
  Drawer: {
    baseStyle: {
      overlay: {
        bg: 'blackAlpha.700',
      },
      dialogContainer: {},
      dialog: {
        bg: 'black',
        boxShadow: 'none',
      },
      header: {
        fontSize: '4xl',
        fontWeight: 'bolder',
        letterSpacing: 'tighter',
      },
      closeButton: {},
      body: {},
      footer: {},
    },
  },
  Editable: {
    baseStyle: props => {
      return {
        input: {
          // eslint-disable-next-line
          ...props.theme.components.Input.variants.outline?.(props)?.field,
          px: 4,
          borderColor: 'primary.500',
          _focus: {
            boxShadow: 'none',
            borderColor: `${colors.primary[500]} !important`,
          },
        },
        preview: {
          p: 0,
          fontSize: 'md',
          fontWeight: 700,
        },
      };
    },
    defaultProps: {
      size: 'sm',
      errorBorderColor: 'danger.500',
      focusBorderColor: 'primary.500',
    },
  },
  Heading: {
    baseStyle: {
      fontSize: '4xl',
      letterSpacing: 'tighter',
    },
    defaultProps: {
      size: 'lg',
    },
  },
  Input: {
    parts: ['addon', 'field', 'element'],
    variants: {
      outline: ({ theme }) => ({
        addon: {
          borderRadius: 'none',
          border: 'none',
          bg: 'transparent',
        },
        field: {
          border: '2px',
          borderRadius: 'none',
          borderColor: 'whiteAlpha.700',
          _hover: { borderColor: transparentize('primary.500', 0.5)(theme) },
          _invalid: { boxShadow: 'none' },
          _focus: { boxShadow: 'none' },
        },
      }),
    },
    defaultProps: {
      errorBorderColor: 'danger.500',
      focusBorderColor: 'primary.500',
    },
  },
  Link: {
    variants: {
      navlink: {
        fontSize: 'sm',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'superwide',
        '&:hover,&.active': {
          textDecoration: 'none',
          color: 'primary.500',
        },
        _active: {
          boxShadow: 'none',
          color: 'primary.200',
        },
      },
    },
  },
  Menu: {
    baseStyle: {
      button: {},
      list: {
        bg: 'black',
        boxShadow: 'none',
        border: '2px',
        borderColor: 'white',
        borderRadius: 'none',
      },
      item: {
        py: '0.4rem',
        px: '0.8rem',
        _hover: { bg: 'whiteAlpha.100' },
        _focus: { bg: 'whiteAlpha.100' },
        _active: { bg: 'whiteAlpha.200' },
        _expanded: { bg: 'whiteAlpha.100' },
      },
      groupTitle: {
        py: '0.4rem',
        px: '0.8rem',
        m: 0,
      },
      command: {},
      divider: {
        borderColor: 'whiteAlpha.100',
        border: '2px',
      },
    },
  },
  Modal: {
    baseStyle: {
      overlay: {
        bg: 'blackAlpha.700',
      },
      dialogContainer: {},
      dialog: {
        bg: 'black',
        border: ['none', 'none', '2px'],
        borderRadius: 'none',
        boxShadow: 'none',
      },
      header: {
        fontSize: '4xl',
        fontWeight: 'bolder',
        letterSpacing: 'tighter',
      },
      closeButton: {},
      body: { pb: 6 },
      footer: {},
    },
    defaultProps: {
      motionPreset: 'scale',
      scrollBehavior: 'inside',
      size: 'xl',
    },
  },
  OptionGroup: {
    parts: ['container', 'option'],
    baseStyle: ({ theme, ...props }) => {
      return {
        container: {
          boxShadow: 'none',
          border: '2px',
          borderColor: 'whiteAlpha.700',
          borderRadius: 'none',
          overflow: 'auto',
          fontSize: props.size,
          p: 2,
        },
        option: {
          _disabled: {
            color: 'whiteAlpha.400',
          },
          fontSize: props.size,
          ':hover:not(:disabled)': { bg: 'whiteAlpha.100' },
          ':focus:not(:disabled)': { bg: 'whiteAlpha.100' },
          ':active:not(:disabled)': { bg: 'whiteAlpha.200' },
        },
      };
    },
    variants: {
      outline: ({ theme }) => ({
        container: {},
      }),
    },
  },
  Popover: {
    baseStyle: {
      content: {
        bg: 'black',
        boxShadow: 'none',
        border: '2px',
        borderColor: 'white',
        borderRadius: 'none',
        [cssVar('popper-arrow-size').variable]: '12px',
        [cssVar('popper-arrow-bg').variable]: 'white',
        [cssVar('popper-arrow-shadow-color').variable]: 'transparent',
      },
      header: {
        border: 'none',
      },
      body: {},
      footer: {
        border: 'none',
      },
      arrow: {},
    },
  },
  Select: {
    defaultProps: {
      errorBorderColor: 'danger.500',
      focusBorderColor: 'primary.500',
    },
    variants: {
      outline: props => ({
        addon: {
          // eslint-disable-next-line
          ...props.theme.components.Input.variants.outline?.(props)?.addon,
        },
        field: {
          // eslint-disable-next-line
          ...props.theme.components.Input.variants.outline?.(props)?.field,
        },
      }),
    },
  },
  Stat: {
    parts: ['container', 'label', 'helpText', 'number', 'icon'],
    baseStyle: {
      container: {
        flex: 'unset',
        maxW: 'full',
      },
      label: {
        textTransform: 'uppercase',
        mb: 1,
        opacity: 0.8,
      },
    },
    defaultProps: {
      size: 'sm',
    },
    sizes: {
      sm: {
        label: { fontSize: '2xs' },
        helpText: { fontSize: '2xs' },
        number: { fontSize: 'md' },
      },
      md: {
        label: { fontSize: 'sm' },
        helpText: { fontSize: 'sm' },
        number: { fontSize: '2xl' },
      },
      lg: {
        label: { fontSize: 'md' },
        helpText: { fontSize: 'md' },
        number: { fontSize: '3xl' },
      },
      xl: {
        label: { fontSize: 'xl' },
        helpText: { fontSize: 'xl' },
        number: { fontSize: '6xl' },
      },
    },
  },
  Table: {
    parts: ['table', 'th', 'td', 'caption'],
    variants: {
      simple: props => ({
        thead: {
          '::after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            height: '2px',
            bg: 'whiteAlpha.50',
          },
        },
        th: {
          border: 'none',
          color: 'white',
          fontWeight: 'black',
          whiteSpace: 'nowrap',
        },
        td: {
          border: 'none',
          whiteSpace: 'nowrap',
        },
        tbody: {
          '& > tr:hover,& > tr:focus': {
            cursor: 'pointer',
            bg: 'whiteAlpha.50',
          },
          '& > tr:active': { bg: 'whiteAlpha.200' },
        },
        tr: {},
      }),
    },
  },
  Tag: {
    defaultProps: {
      variant: 'solid',
      borderRadius: 'none',
      boxShadow: 'none',
    },
    variants: {
      outline: ({ colorScheme: c }) => ({
        container: {
          bg: 'black',
          border: '2px',
          borderColor: c === 'gray' ? 'white' : `${c}.500`,
          borderRadius: 'none',
          color: c === 'accent' ? 'black' : 'white',
        },
      }),
      solid: ({ colorScheme: c }) => ({
        container: {
          bg: c === 'gray' ? 'whiteAlpha.200' : `${c}.500`,
          borderRadius: 'none',
          color: c === 'accent' ? 'black' : 'white',
        },
      }),
    },
  },
  Tooltip: {
    baseStyle: () => ({
      bg: 'white',
      [cssVar('popper-arrow-bg').variable]: 'white',
      color: 'black',
      border: 'none',
      fontSize: 'xs',
      boxShadow: 'none',
      borderRadius: 'none',
    }),
  },
};
