import * as ui from '@chakra-ui/react';

export const EditableFieldTheme: ui.ComponentMultiStyleConfig = {
  parts: ['preview', 'input'],
  baseStyle: props => {
    return {
      input: {
        // eslint-disable-next-line
        ...props.theme.components.Input.variants.outline?.(props)?.field,
        px: 4,
        borderColor: 'primary.500',
        _focus: {
          boxShadow: 'none',
          borderColor: `#8f03ff !important`,
        },
      },
      preview: {
        p: 0,
        h: 10,
        lineHeight: 10,
        fontSize: 'md',
        fontWeight: 700,
      },
    };
  },
  defaultProps: {
    errorBorderColor: 'danger.500',
    focusBorderColor: 'primary.500',
  },
};
