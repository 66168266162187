import * as ui from '@chakra-ui/react';
import { MaybeRenderProp } from '@chakra-ui/react-utils';
import { runIfFn } from '@chakra-ui/utils';
import * as React from 'react';
import {
  EditableFieldProvider,
  useEditableField,
  UseEditableFieldProps,
  UseEditableFieldReturn,
} from './useEditableField';

type RenderProps = UseEditableFieldReturn['renderProps'];

export interface EditableProps<T>
  extends UseEditableFieldProps<T>,
    Omit<
      ui.FormControlProps,
      'onChange' | 'onSubmit' | 'defaultValue' | 'value'
    > {
  children?: MaybeRenderProp<RenderProps>;
}

function EditableFieldInner<T = string>(
  props: EditableProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const formControlStyles = ui.useMultiStyleConfig('Form', { size: 'md' });
  const editableFieldStyles = ui.useMultiStyleConfig('EditableField', props);
  const { htmlProps, ...context } = useEditableField(props);

  return (
    <EditableFieldProvider value={context}>
      <ui.FormControl ref={ref} {...(htmlProps as ui.FormControlProps)}>
        <ui.StylesProvider
          value={{ ...formControlStyles, ...editableFieldStyles }}
        >
          {runIfFn(props.children, context.renderProps)}
        </ui.StylesProvider>
      </ui.FormControl>
    </EditableFieldProvider>
  );
}

EditableFieldInner.displayName = 'EditableField';
export const EditableField = React.forwardRef(EditableFieldInner);
