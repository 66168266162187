import * as ui from '@chakra-ui/react';
import { memoizedGet } from '@chakra-ui/utils';
import React, { cloneElement, useEffect } from 'react';
import { GroupBase, Props, Theme as ReactSelectTheme } from 'react-select';
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  GroupHeading,
  IndicatorsContainer,
  IndicatorSeparator,
  Menu,
  MenuList,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  Option,
  Placeholder,
  SelectContainer,
} from './components';

export interface ReactSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  SelectProps extends Props<Option, IsMulti, Group>
> extends ui.ThemingProps<'Select'>,
    Omit<
      ui.SelectFieldProps,
      | 'options'
      | 'value'
      | keyof ui.FormControlOptions
      | keyof SelectEventHandlerProps<Option, IsMulti, Group>
    >,
    SelectEventHandlerProps<Option, IsMulti, Group>,
    ui.FormControlOptions {
  children: React.ReactElement;
  closeMenuOnSelect?: boolean;
  hideSelectedValues?: boolean;
  hideSelectedOptions?: boolean;
  isMulti?: IsMulti;
  options: Props<Option, IsMulti, Group>['options'];
  selectProps?: Omit<
    SelectProps,
    | 'autoFocus'
    | 'hideSelectedOptions'
    | 'closeMenuOnSelect'
    | 'isMulti'
    | 'options'
    | 'value'
    | keyof ui.FormControlOptions
    | keyof SelectEventHandlerProps<Option, IsMulti, Group>
  >;
  value?: Props<Option, IsMulti, Group>['value'];
}

type EventHandlerKey<K extends string> = K extends `on${any}` ? K : never;

type SelectEventHandlerProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = {
  [K in keyof Props as EventHandlerKey<K>]: Props<Option, IsMulti, Group>[K];
};

export function ReactSelect<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  SelectProps extends Props<Option, IsMulti, Group> = Props<
    Option,
    IsMulti,
    Group
  >
>({
  children,
  hideSelectedValues = false,
  selectProps: { inputId, ...selectProps } = {} as SelectProps,

  /** ui.FormControlOptions */
  isDisabled,
  isReadOnly,
  isRequired,
  isInvalid,

  /** These are moved to the top level props but need to be passed back down into <Select> */
  autoFocus = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  isMulti,
  options,
  onBlur,
  onChange,
  onFocus,
  onInputChange,
  onKeyDown,
  onMenuOpen,
  onMenuClose,
  onMenuScrollToTop,
  onMenuScrollToBottom,
  value,
  ...unusedProps
}: ReactSelectProps<Option, IsMulti, Group, SelectProps>) {
  useEffect(() => {
    Object.entries(unusedProps).forEach(([k, v]) => {
      console.error(
        `Prop "${k}" was given to Select, but will have no effect because it's not being used`
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chakraTheme = ui.useTheme() as ui.Theme;

  // Combine the props passed into the component with the props that can be set
  // on a surrounding form control to get the values of `isDisabled` and
  // `isInvalid`
  const inputProps = ui.useFormControl({
    isDisabled,
    isReadOnly,
    isRequired,
    isInvalid,
  });

  const props: Props<Option, IsMulti, Group> = {
    ...selectProps,
    components: {
      Control,
      ClearIndicator,
      DropdownIndicator,
      GroupHeading,
      IndicatorsContainer,
      IndicatorSeparator,
      Menu,
      MenuList,
      MultiValueLabel,
      MultiValueContainer: hideSelectedValues
        ? () => null
        : MultiValueContainer,
      MultiValueRemove,
      Option,
      Placeholder,
      SelectContainer,
    },
    styles: {
      ...selectProps.styles,
      group: base => ({ ...base, padding: 0 }),
      valueContainer: base => ({ ...base, padding: 0 }),
    },
    theme: (baseTheme: ReactSelectTheme) => {
      return {
        ...baseTheme,
        colors: {
          ...baseTheme.colors,
          primary: memoizedGet(chakraTheme, 'colors.primary.500'),
          primary75: memoizedGet(chakraTheme, 'colors.primary.800'),
          primary50: memoizedGet(chakraTheme, 'colors.primary.400'),
          primary25: memoizedGet(chakraTheme, 'colors.primary.100'),
          danger: memoizedGet(chakraTheme, 'colors.danger.500'),
          dangerLight: memoizedGet(chakraTheme, 'colors.danger.200'),
          neutral0: memoizedGet(chakraTheme, 'colors.black'),
          neutral40: memoizedGet(chakraTheme, 'colors.whiteAlpha.500'),
          neutral50: memoizedGet(chakraTheme, 'colors.whiteAlpha.500'),
          neutral80: memoizedGet(chakraTheme, 'colors.white'),
          neutral90: memoizedGet(chakraTheme, 'colors.white'),
        },
      };
    },
    /** ui.FormControlOptions */
    'aria-invalid': inputProps['aria-invalid'],
    isDisabled: inputProps.disabled,
    inputId: inputId || inputProps.id,

    /** Values */
    autoFocus,
    closeMenuOnSelect,
    hideSelectedOptions,
    isMulti,
    options,
    tabSelectsValue: false,
    value,

    /** Event handlers */
    onBlur,
    onChange,
    onFocus,
    onInputChange,
    onKeyDown,
    onMenuOpen,
    onMenuClose,
    onMenuScrollToTop,
    onMenuScrollToBottom,
  };

  return cloneElement(children, props);
}
