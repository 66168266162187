import { safeParse } from '../../../lib';
import { ResourceParams } from './types';

export class ResourceParamUtils {
  static fromDict<T>(queryParams: Record<string, string>): ResourceParams<T> {
    return {
      q: safeParse<ResourceParams<T>['q']>(queryParams.q, {}),
      sort: safeParse<ResourceParams<T>['sort']>(queryParams.sort, {}),
      n: Number.isSafeInteger(+queryParams.n) ? +queryParams.n : 15,
      offset: Number.isSafeInteger(+queryParams.offset)
        ? +queryParams.offset
        : 0,
    };
  }
}
