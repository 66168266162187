import * as ui from '@chakra-ui/react';
import React from 'react';
import { BiCheck, BiChevronDown, BiX } from 'react-icons/bi';
import { components } from 'react-select';

type Components = typeof components;
interface SxProps extends ui.CSSWithMultiValues {
  _disabled: ui.CSSWithMultiValues;
  _focus: ui.CSSWithMultiValues;
}

/**
 * ClearIndicator
 * Control
 * CrossIcon
 * DownChevron
 * DropdownIndicator
 * Group
 * GroupHeading
 * IndicatorsContainer
 * IndicatorSeparator
 * Input
 * LoadingIndicator
 * LoadingMessage
 * Menu
 * MenuList
 * MenuPortal
 * MultiValue
 * MultiValueContainer
 * MultiValueLabel
 * MultiValueRemove
 * NoOptionsMessage
 * Option
 * Placeholder
 * SelectContainer
 * SingleValue
 * ValueContainer
 */

export const ClearIndicator: Components['ClearIndicator'] = ({
  innerProps,
}) => (
  <ui.IconButton
    aria-label="clear values"
    colorScheme="gray"
    color="whiteAlpha.700"
    icon={<BiX size={20} />}
    tabIndex={-1}
    variant="ghost"
    {...(innerProps as JSX.IntrinsicElements['button'])}
  />
);

export const Control: Components['Control'] = ({
  // The children are a ValueContainer and an IndicatorsContainer
  children,
  innerRef,
  innerProps: { ...innerProps },
  isDisabled,
  isFocused,
}) => {
  const inputStyles = ui.useStyleConfig('Select', {}, { isMultiPart: true });
  // console.log('inputStyles: ', inputStyles);
  return (
    <ui.StylesProvider value={inputStyles}>
      <ui.Flex
        ref={innerRef}
        id="Control"
        align="center"
        sx={{
          ...inputStyles.field,
          overflow: 'hidden',
          minH: inputStyles.field.h,
          h: 'auto',
          pr: 0,
        }}
        {...innerProps}
        data-focus={isFocused ? true : undefined}
        // data-invalid={isInvalid ? true : undefined}
        data-disabled={isDisabled ? true : undefined}
      >
        {children}
      </ui.Flex>
    </ui.StylesProvider>
  );
};

export const DropdownIndicator: Components['DropdownIndicator'] = ({
  innerProps,
  selectProps: { menuIsOpen },
}) => {
  const styles = ui.useStyles(); // this comes from the ui.useStyleConfig in Control

  return (
    <ui.Icon
      as={BiChevronDown}
      color={menuIsOpen ? 'primary.500' : 'white'}
      boxSize={4}
      sx={{
        ...styles.icon,
        transform: menuIsOpen ? 'rotate(-180deg)' : 'none',
        transition: 'transform 0.2s',
      }}
      {...(innerProps as any)}
    />
  );
};

export const GroupHeading: Components['GroupHeading'] = ({ children }) => {
  const {
    divider,
    groupTitle,
    list: { bg },
  } = ui.useStyles();

  return (
    <ui.Box
      data-component="GroupHeading"
      sx={{
        ...groupTitle,
        bg,
        borderBottom: divider.borderBottom,
        borderColor: divider.borderColor,
        position: 'sticky',
        pl: 8,
        top: -2,
      }}
    >
      {children}
    </ui.Box>
  );
};

export const IndicatorsContainer: Components['IndicatorsContainer'] = ({
  innerProps,
  children,
}) => (
  <ui.HStack {...innerProps} cursor="pointer" spacing={2}>
    {children}
  </ui.HStack>
);

export const IndicatorSeparator: Components['IndicatorSeparator'] = () =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  null as any;

export const Menu: Components['Menu'] = ({ children, innerProps }) => {
  const menuStyles = ui.useStyleConfig('Menu', {}, { isMultiPart: true });

  return (
    <ui.Box
      data-component="Menu"
      sx={{
        position: 'absolute',
        top: '100%',
        my: 2,
        zIndex: 1,
        overflow: 'hidden',
        rounded: 0,
      }}
      {...innerProps}
    >
      <ui.StylesProvider value={menuStyles}>{children}</ui.StylesProvider>
    </ui.Box>
  );
};

export const MenuList: Components['MenuList'] = ({
  innerRef,
  innerProps,
  children,
  maxHeight,
}) => {
  const { list } = ui.useStyles();
  return (
    <ui.Box
      data-component="MenuList"
      {...innerProps}
      sx={{
        ...list,
        maxH: `${maxHeight}px`,
        overflowY: 'auto',
        borderRadius: 0,
        position: 'relative',
      }}
      ref={innerRef}
    >
      {children}
    </ui.Box>
  );
};

export const MultiValueContainer: Components['MultiValueContainer'] = ({
  children,
}) => (
  <ui.Tag my={1} mr={2}>
    {children}
  </ui.Tag>
);

export const MultiValueLabel: Components['MultiValueLabel'] = ({
  children,
  data,
}) => {
  const inputStyles = ui.useMultiStyleConfig('ui.TagLabel', { size: 'sm' });

  return (
    <ui.StylesProvider value={inputStyles}>
      <ui.TagLabel data-component="MultiValueLabel">{children}</ui.TagLabel>
    </ui.StylesProvider>
  );
};

export const MultiValueRemove: Components['MultiValueRemove'] = ({
  children,
  innerProps: { className, ...innerProps },
}) => (
  <ui.TagCloseButton {...(innerProps as JSX.IntrinsicElements['button'])}>
    {children}
  </ui.TagCloseButton>
);

export const Option: Components['Option'] = ({
  innerRef,
  innerProps,
  children,
  isFocused,
  isDisabled,
  isSelected,
  selectProps: { hideSelectedOptions },
}) => {
  const styles = ui.useStyles();
  const item = styles.item as ui.RecursiveCSSObject<SxProps>;
  return (
    <ui.Box
      as={ui.Flex}
      role="button"
      ref={innerRef}
      align="center"
      sx={{
        ...styles.item,
        pl: 8,
        ...(isFocused && item._focus),
        ...(isDisabled && item._disabled),
      }}
      {...innerProps}
    >
      {isSelected && <ui.Box as={BiCheck} size={24} ml={-8} mr={2} />}
      {children}
    </ui.Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const Placeholder: Components['Placeholder'] = () => null as any;

export const SelectContainer: Components['SelectContainer'] = ({
  children,
  innerProps,
  ...props
}) => {
  return (
    <ui.Box as="div" data-component="SelectContainer" {...innerProps}>
      {children}
    </ui.Box>
  );
};
