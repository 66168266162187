import { useState } from 'react';

const createStorageHook = (storage: Storage) =>
  function useStorage<S>(key: string) {
    // Use the setter function here to trigger a re-run of the hook
    const [, runAgain] = useState(0);

    const clearValue = () => {
      storage.removeItem(key);
      runAgain(n => n + 1);
    };

    const setValue = (value: S | null) => {
      const newValue = JSON.stringify(value);
      storage.setItem(key, newValue);
      runAgain(n => n + 1);
    };

    const value = JSON.parse(storage.getItem(key) ?? 'null') as S | null;

    return [value, setValue, clearValue] as const;
  };

const mockStorage: Storage = ((): Storage => {
  let memory: Record<string, string> = {};
  return {
    clear: () => (memory = {}),
    length: 0,
    getItem: key => memory[key],
    key: i => Object.keys(memory)[i],
    removeItem: key => {
      delete memory[key];
    },
    setItem: (key, value) => (memory[key] = value),
  };
})();

export const useLocalStorage = createStorageHook(
  window?.localStorage ?? mockStorage
);
export const useSessionStorage = createStorageHook(
  window?.sessionStorage ?? mockStorage
);
