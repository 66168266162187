import { Heading, VStack } from '@chakra-ui/react';
import React from 'react';
import { Logo } from '../../shared/Logo';
import { PageContent } from '../PageContent';
import { PageHeader } from '../PageHeader';
import { Colors } from './Colors';

export const ComponentPreview: React.FC = ({}) => {
  return (
    <>
      <PageHeader heading="Preview" />
      <PageContent maxW="full">
        <VStack align="flex-start" spacing={8}>
          <Heading>Logo</Heading>
          <Logo size="lg" color="primary.500" />
          <Logo size="lg" color="accent.500" />
        </VStack>
        <VStack align="flex-start" spacing={8}>
          <Heading>Colors</Heading>
          <Colors />
        </VStack>
      </PageContent>
    </>
  );
};
