import * as ui from '@chakra-ui/react';
import React from 'react';
import { PageSelector } from '../../../shared';
import { useCatalogSearchCounts } from '../useCatalogSearchCounts';
import { useCatalogSearchParams } from '../useCatalogSearchParams';

type Props = {};

export const CatalogPaginator: React.FC<Props> = ({}) => {
  const { applyParams, pendingParams, activeParams } = useCatalogSearchParams();
  const { activeCount } = useCatalogSearchCounts();

  return (
    <ui.HStack
      pos="sticky"
      bottom="0"
      bg="blackAlpha.800"
      p={[2, null, 4]}
      justify="center"
      zIndex={2}
      borderTop="2px"
      borderTopColor="whiteAlpha.50"
    >
      <PageSelector
        onChange={offset => applyParams({ ...pendingParams, offset })}
        value={{ ...activeParams, total: activeCount }}
      />
    </ui.HStack>
  );
};
