import { Theme } from '@chakra-ui/theme';

export const fonts = {
  body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  heading:
    'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  mono: 'Menlo, monospace',
} as const;

export const fontSizes: Theme['fontSizes'] & Record<'3xs' | '2xs', string> = {
  '2xs': '0.625rem',
  '3xs': '0.5rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
};

export const letterSpacings = {
  superwide: '0.2rem',
};
