import React from 'react';
import { VideoCamera } from '../VideoCamera';

export type CapturedBarcode = DetectedBarcode & { data: ImageData };

type Props = {
  onCapture: (values: CapturedBarcode[]) => void;
};

export const BarcodeCapture: React.FC<Props> = ({ onCapture }) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const ctxRef = React.useRef<CanvasRenderingContext2D | null>(null);
  const imageDataRef = React.useRef<ImageData | null>(null);
  const [detector] = React.useState(
    new window.BarcodeDetector({
      formats: [
        'aztec',
        'code_128',
        'code_39',
        'code_93',
        'data_matrix',
        'ean_13',
        'ean_8',
        'itf',
        'pdf417',
        'qr_code',
        'upc_e',
      ],
    })
  );

  const check = async () => {
    if (ctxRef.current && videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) return;
      const { videoWidth, videoHeight } = videoRef.current;
      const bounds = [0, 0, videoWidth, videoHeight] as const;
      ctxRef.current.canvas.height = videoHeight;
      ctxRef.current.canvas.width = videoWidth;
      ctxRef.current.drawImage(videoRef.current, ...bounds);
      const imageData = ctxRef.current.getImageData(...bounds);
      imageDataRef.current = imageData;
      const results = await detector.detect(imageDataRef.current);
      if (results.length > 0) {
        onCapture(results.map(result => ({ ...result, data: imageData })));
      }

      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.requestAnimationFrame(check);
    }
  };

  return (
    <>
      <canvas
        style={{ display: 'none' }}
        ref={canvas => {
          ctxRef.current = canvas?.getContext('2d') ?? null;
        }}
      />

      <VideoCamera ref={videoRef} onPlay={check} />
    </>
  );
};
