import { QueryResponse } from 'react-fetching-library';

export const intoErrorMessage = (
  { error = false, errorObject }: Partial<QueryResponse<unknown>> = {},
  defaultMessage?: string
): string | undefined => {
  if (!error) return defaultMessage;

  const errorMessages = [
    errorObject?.message ?? errorObject?.messages ?? [], // eslint-disable-line
  ].flat() as string[];

  return errorMessages[0] ?? defaultMessage;
};
