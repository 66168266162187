import {
  Badge,
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Product } from '../../../apis';
import { useBreakpointQuery } from '../../../hooks';
import { ProductMenu } from '../ProductMenu';

type Props = {
  onDeleted: () => void;
  product: Product;
};

export const ProductItem: React.FC<Props> = ({ onDeleted, product }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isGteMd = useBreakpointQuery('md');

  return (
    <LinkBox
      as={Flex}
      direction="row"
      align="flex-start"
      key={product._id}
      spacing={3}
      p={[2, 4, 6]}
      border="2px"
      borderColor="transparent"
      sx={{
        _hover: { borderColor: 'whiteAlpha.200' },
        _active: { bgColor: 'whiteAlpha.50' },
      }}
    >
      <Image
        alt="product"
        pos="relative"
        objectFit="cover"
        boxSize={['60px', null, '100px']}
        minW={['60px', null, '100px']}
        src={product.productImageUrl}
      />

      <VStack flexGrow={1} spacing={2} px={[4, 8]} align="flex-start">
        <Heading as="h4" size={isGteMd ? 'sm' : 'xs'}>
          <LinkOverlay as={NavLink} to={`/inventorio/catalog/${product._id}`}>
            {product.title}
          </LinkOverlay>
        </Heading>
        <Text fontSize={isGteMd ? 'md' : 'xs'}>{product.vendor}</Text>
        <Wrap spacing={[2, 4]}>
          {product.tags.map(tag => (
            <Badge
              key={tag}
              variant="outline"
              fontSize={isGteMd ? 'sm' : '2xs'}
              borderColor={isGteMd ? 'whiteAlpha.600' : 'whiteAlpha.300'}
            >
              {tag}
            </Badge>
          ))}
        </Wrap>
      </VStack>
      <ProductMenu
        menuButtonProps={{ variant: 'ghost' }}
        item={product}
        onDeleted={onDeleted}
      />
    </LinkBox>
  );
};
