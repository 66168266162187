import {
  DeviceAction,
  DeviceActionBase,
  DeviceActionType,
  DeviceConfig,
  UpdateDeviceConfigDto,
} from './types';

export class IotApiUtils {
  static actionIsConfigurable = (type: DeviceActionType) => {
    switch (type) {
      case DeviceActionType.Noop:
        return false;
      case DeviceActionType.SendSms:
        return true;
    }
  };

  static actionLabel = (type: DeviceActionType) => {
    switch (type) {
      case DeviceActionType.Noop:
        return 'Noop';
      case DeviceActionType.SendSms:
        return 'Send SMS';
    }
  };

  static extractBase = ({
    actionId,
    actionType,
    configId,
    createdAt,
    latestFailure,
    latestSuccess,
    updatedAt,
  }: DeviceAction): DeviceActionBase => ({
    actionId,
    actionType,
    configId,
    createdAt,
    latestFailure,
    latestSuccess,
    updatedAt,
  });

  static subtractBase = ({
    actionId,
    configId,
    createdAt,
    latestFailure,
    latestSuccess,
    updatedAt,
    ...rest
  }: DeviceAction): Subtract<DeviceAction, DeviceActionBase> => rest;

  static subtractConfigBase = ({
    actions,
    configId,
    createdAt,
    deviceName,
    events,
    latestActivation,
    latestActionTrigger,
    latestPing,
    updatedAt,
    version,
    ...rest
  }: DeviceConfig): UpdateDeviceConfigDto => ({
    actions: actions.map(IotApiUtils.subtractBase),
    ...rest,
  });
}
