import { FormControl, FormLabel, HStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useQuery } from 'react-fetching-library';
import {
  getFieldValues as getFieldValuesQuery,
  getUsers,
  User,
} from '../../../apis';
import { useBreakpointQuery } from '../../../hooks';
import { Option, OptionGroup } from '../../../shared';
import { useCatalogSearchParams } from '../useCatalogSearchParams';

type Option = Record<'label' | 'value', string>;

export const Filters: React.FC = () => {
  const isGteMd = useBreakpointQuery('md');
  const { pendingParams, setPendingParams } = useCatalogSearchParams();
  const { payload: tags = [] } = useQuery(getFieldValuesQuery('tags'));
  const { payload: vendors = [] } = useQuery(getFieldValuesQuery('vendor'));
  const { payload: users } = useQuery(getUsers());

  const usersById: Record<string, User> = useMemo(
    () => Object.fromEntries((users ?? []).map(user => [user._id, user])),
    [users]
  );

  const tagOptions: Option[] = useMemo(
    () =>
      (tags ?? [])
        .filter(tag => tag ?? false)
        .map(tag => ({ label: tag, value: tag })),
    [tags]
  );

  const vendorOptions: Option[] = useMemo(
    () => (vendors ?? []).map(vendor => ({ label: vendor, value: vendor })),
    [vendors]
  );

  const userOptions: Option[] = useMemo(
    () =>
      (users ?? []).map(user => ({ label: user.username, value: user._id })),
    [users]
  );

  const selectedTags: Option[] = (
    (pendingParams.q.tags as { $in?: string[] })?.$in ?? []
  ).map(tag => ({ value: tag, label: tag }));

  const selectedVendors: Option[] = (
    (pendingParams.q.vendor as { $in?: string[] })?.$in ?? []
  ).map(vendor => ({ value: vendor, label: vendor }));

  const selectedUsers: Option[] = (
    (pendingParams.q.createdBy as { $in?: string[] })?.$in ?? []
  )
    .filter(_id => !!usersById[_id])
    .map(_id => ({ value: _id, label: usersById[_id].username }));

  return (
    <HStack
      spacing={4}
      overflow="scroll"
      minH={48}
      sx={{ '& > *': { minW: 72 } }}
      maxW="full"
    >
      <FormControl id="tags">
        <FormLabel>
          Tags {selectedTags.length > 0 && `(${selectedTags.length} selected)`}
        </FormLabel>
        <OptionGroup
          type="checkbox"
          onChange={ts =>
            setPendingParams({
              ...pendingParams,
              q: {
                ...pendingParams.q,
                tags: ts.length > 0 ? { $in: ts } : undefined,
              },
            })
          }
          minH={48}
          maxH={48}
          size={isGteMd ? 'md' : 'sm'}
          value={selectedTags.map(opt => opt.value)}
        >
          {tagOptions.map(opt => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </OptionGroup>
      </FormControl>
      <FormControl id="vendor">
        <FormLabel>
          Vendors{' '}
          {selectedVendors.length > 0 && `(${selectedVendors.length} selected)`}
        </FormLabel>
        <OptionGroup
          type="checkbox"
          onChange={vs =>
            setPendingParams({
              ...pendingParams,
              q: {
                ...pendingParams.q,
                vendor: vs.length > 0 ? { $in: vs } : undefined,
              },
            })
          }
          minH={48}
          maxH={48}
          size={isGteMd ? 'md' : 'sm'}
          value={selectedVendors.map(opt => opt.value)}
        >
          {vendorOptions.map(opt => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </OptionGroup>
      </FormControl>
      <FormControl id="createdBy">
        <FormLabel>
          Created By{' '}
          {selectedUsers.length > 0 && `(${selectedUsers.length} selected)`}
        </FormLabel>
        <OptionGroup
          type="checkbox"
          onChange={vs =>
            setPendingParams({
              ...pendingParams,
              q: {
                ...pendingParams.q,
                createdBy: vs.length > 0 ? { $in: vs } : undefined,
              },
            })
          }
          minH={48}
          maxH={48}
          size={isGteMd ? 'md' : 'sm'}
          value={selectedUsers.map(opt => opt.value)}
        >
          {userOptions.map(opt => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </OptionGroup>
      </FormControl>
    </HStack>
  );
};
