import { Code } from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-fetching-library';
import { useHistory } from 'react-router-dom';
import { DeviceConfig, getAllDeviceConfigs } from '../../apis';
import { PageContent, PageHeader } from '../../layout';
import { Listing } from '../../shared';

export const DeviceListing: React.FC = () => {
  const history = useHistory();
  const { payload: configs = [] } = useQuery(getAllDeviceConfigs());

  return (
    <>
      <PageHeader heading="Devices" />
      <PageContent>
        <Listing<DeviceConfig>
          itemKeyGen={item => item.configId}
          items={configs}
          onRowClick={(event, item) => {
            history.push(`/iot/${item.configId}`);
          }}
          columns={[
            {
              header: () => 'Device Name',
              data: item => item.deviceName,
            },
            {
              header: () => 'Device ID',
              data: item => <Code>{item.configId}</Code>,
            },
            {
              header: () => 'Configured Actions',
              data: item => item.actions.length,
            },
          ]}
        />
      </PageContent>
    </>
  );
};
