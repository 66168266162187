import { useBreakpoint } from '@chakra-ui/react';

export const breakpointLabels = {
  base: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  '2xl': 5,
} as const;

type BreakpointLabel = keyof typeof breakpointLabels;

/**
 * Returns `true` if the window size is larger than or equal to the specific
 * breakpoint.
 */
export const useBreakpointQuery = (label: BreakpointLabel) => {
  const breakpoint = useBreakpoint() as BreakpointLabel;
  const targetSize = breakpointLabels[label];
  const currentSize = breakpointLabels[breakpoint];

  return currentSize >= targetSize;
};
