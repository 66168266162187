import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AuthedFetchProvider } from './apis';
import { Routes } from './Routes';
import { theme } from './theme';
import './index.css';

const Root: React.FC = () => (
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthedFetchProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AuthedFetchProvider>
    </ChakraProvider>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));
