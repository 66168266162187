import {
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useAuthedFetch } from '../../apis';
import { useBreakpointQuery } from '../../hooks';

export const TOPBAR_HEIGHT = '3rem';

export const TopBar: React.FC = () => {
  const { clearSession } = useAuthedFetch();
  const isGteMd = useBreakpointQuery('md');

  const isGcpIot = useRouteMatch('/gcp-iot');
  const isIot = useRouteMatch('/iot');
  const isInventorio = useRouteMatch('/inventorio');
  const isThree = useRouteMatch('/three');
  const isUsers = useRouteMatch('/users');
  const isUser = useRouteMatch<{ id: string }>('/users/:id');
  const isProfile = isUser?.params?.id === 'me';

  const routeLabel = (() => {
    if (isGcpIot) return 'Google IoT';
    if (isInventorio) return 'Inventorio';
    if (isIot) return 'IoT';
    if (isProfile) return 'Profile';
    if (isThree) return 'Three';
    if (isUser) return 'User';
    if (isUsers) return 'Users';
  })();

  return (
    <Flex
      as="nav"
      direction="row"
      align="center"
      borderBottom="2px"
      borderColor="whiteAlpha.50"
      px={4}
      h={TOPBAR_HEIGHT}
      minH={TOPBAR_HEIGHT}
      maxH={TOPBAR_HEIGHT}
      py={[2, 4]}
      zIndex="99"
      maxW="100vw"
    >
      <HStack direction="row" align="center" spacing={4}>
        <HStack direction="row" align="center" spacing={2}>
          <Heading
            as={NavLink}
            to="/"
            size="sm"
            fontWeight="black"
            sx={{ _hover: { color: 'primary.500' } }}
          >
            apedash
          </Heading>
          <Heading size="sm" fontWeight="semibold" color="whiteAlpha.800">
            {process.env.npm_package_version}
          </Heading>
        </HStack>
      </HStack>

      <Spacer />

      <Popover closeOnBlur>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                pr={0}
                rightIcon={<BiChevronDown size={24} />}
                variant="nav"
                fontSize={isGteMd ? 'sm' : 'xs'}
              >
                {routeLabel}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                <VStack my={6} spacing={4}>
                  <Link
                    as={NavLink}
                    to="/iot"
                    variant="navlink"
                    onClick={onClose}
                  >
                    Ape IoT
                  </Link>

                  <Link
                    as={NavLink}
                    to="/gcp-iot"
                    variant="navlink"
                    onClick={onClose}
                  >
                    GCP IoT
                  </Link>

                  <Link
                    as={NavLink}
                    to="/inventorio"
                    variant="navlink"
                    onClick={onClose}
                  >
                    Inventorio
                  </Link>

                  <Link
                    as={NavLink}
                    to="/three"
                    variant="navlink"
                    onClick={onClose}
                  >
                    Three
                  </Link>

                  <Link
                    as={NavLink}
                    to="/users"
                    variant="navlink"
                    onClick={onClose}
                  >
                    Users
                  </Link>

                  <Divider />

                  <Button variant="nav" onClick={clearSession}>
                    Logout
                  </Button>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Flex>
  );
};
