import * as ui from '@chakra-ui/react';
import React from 'react';
import { useEditableFieldContext } from './useEditableField';

export interface EditableFieldInputProps extends ui.HTMLChakraProps<'input'> {}

export const EditableFieldInput = ui.forwardRef<
  EditableFieldInputProps,
  'input'
>((props, ref) => {
  const {
    renderProps: { getInputProps },
  } = useEditableFieldContext();
  const styles = ui.useStyles();
  const inputProps = getInputProps(props, ref) as ui.HTMLChakraProps<'input'>;

  if (React.isValidElement(props.children)) {
    const { onKeyDown, onBlur, onChange, value, hidden } = inputProps; // eslint-disable-line
    if (hidden) return null;
    return React.cloneElement(props.children, {
      onKeyDown,
      onBlur,
      onChange,
      ref,
      value,
    });
  }

  return (
    <ui.chakra.input
      {...inputProps}
      __css={{
        outline: 0,
        fontSize: 'inherit',
        fontWeight: 'inherit',
        textAlign: 'inherit',
        bg: 'transparent',
        ...styles.input,
      }}
    />
  );
});

EditableFieldInput.displayName = 'EditableFieldInput';
