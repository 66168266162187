import { formatDistanceToNow } from 'date-fns';

export const secondsFromDate = (date: Date) => date.getTime();
export const secondsAgo = (date: Date) => (Date.now() - date.getTime()) / 1000;
export const timeAgoLabel = (dateString: string) =>
  formatDistanceToNow(secondsFromDate(new Date(dateString)), {
    addSuffix: true,
    includeSeconds: true,
  });

export const tryParse = <T = any>(value: string): T | string => {
  try {
    return JSON.parse(value) as T;
  } catch {
    return value;
  }
};

export function safeParse<T = any>(value: string, defaultValue: T): T {
  try {
    return JSON.parse(value) as T;
  } catch {
    return defaultValue;
  }
}

export const mapToRange =
  (a: number, b: number, c: number, d: number) => (x: number) => {
    const r = (d - c) / (b - a);
    return (x - a) * r + c;
  };

export const clamp = (min: number, max: number) => (value: number) =>
  Math.max(min, Math.min(max, value));

export const clampLoop = (min: number, max: number) => (value: number) => {
  const len = Math.abs(max - min); // len = 10
  let a = value % len; // a = 1
  if (a > max) a -= len;
  else if (a < min) a += len;
  return a;
};

export function isFunction<T extends Function = Function>(
  value: any
): value is T {
  return typeof value === 'function';
}

export function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

export const urlIsValid = (url: string): boolean => {
  try {
    return !!new URL(url);
  } catch {
    return false;
  }
};
