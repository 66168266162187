import type { Filter } from 'mongodb';
import { Action } from 'react-fetching-library';
import { QueryParamUtils } from '../../lib';
import { Hostname } from '../hostnames';

export interface User {
  _id: string;
  deleted?: string;
  email: string;
  imageUrl: string;
  updated: string;
  username: string;
}

export type UserDto = Omit<User, '_id' | 'updated'>;

export const userToDto = ({ _id, updated, ...user }: User): UserDto => ({
  ...user,
});

export enum UserActionId {
  Delete = 'delete',
}

export const deleteUser: Action<User> = {
  actionId: UserActionId.Delete,
  endpoint: '/users',
  hostname: Hostname.Users,
  method: 'DELETE',
};

export const getUser = (userId: string): Action<User> => ({
  endpoint: `/users/${userId}`,
  hostname: Hostname.Users,
  method: 'GET',
});

export const getUsers = (q: Filter<User> = {}): Action<User[]> => ({
  endpoint: `/users${QueryParamUtils.searchFromQueryParams({ q })}`,
  hostname: Hostname.Users,
  method: 'GET',
});

export const updateUser = (user: UserDto): Action<User> => ({
  body: user,
  endpoint: `/users`,
  hostname: Hostname.Users,
  method: 'PATCH',
  onError: ({ errorMessages }) => ({
    title: 'Failed to save',
    description: errorMessages?.[0],
  }),
  onSuccess: () => ({ title: 'Profile saved' }),
});
