import * as ui from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { getUnaddedProducts as getUnaddedProductsQuery } from '../../../apis';
import { useBreakpointQuery } from '../../../hooks';
import { PageSelector } from '../../../shared';
import { CatalogControls, useCatalogSearchCounts } from '../../catalog';
import { useCatalogSearchParams } from '../../catalog/useCatalogSearchParams';
import { ProductSelectItem } from './ProductSelectItem';

type Selection = {
  [_id: string]: number;
};

type Props = {
  inventoryId: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (ids: Selection) => void;
};

export const ProductSelectDrawer: React.FC<Props> = ({
  inventoryId,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const isGteMd = useBreakpointQuery('md');
  const { applyParams, pendingParams, activeParams } = useCatalogSearchParams();
  const { activeCount } = useCatalogSearchCounts();

  const { query, ...getUnaddedProducts } = useParameterizedQuery(
    getUnaddedProductsQuery
  );
  const products = getUnaddedProducts.payload ?? [];

  const [unfilteredSelection, setSelection] = useState<Selection>({});
  const selection = Object.fromEntries(
    Object.entries(unfilteredSelection).filter(([, q]) => q > 0)
  );
  const selectionCount = Object.keys(selection).length;
  const isSelection = selectionCount > 0;

  // Get products on mount and when the active params change
  React.useEffect(() => {
    query({ inventoryId, params: activeParams });
  }, [activeParams, inventoryId, query]);

  return (
    <ui.Drawer size="xl" isOpen={isOpen} onClose={onClose}>
      <ui.DrawerOverlay />
      <ui.DrawerContent borderLeft="2px" borderLeftColor="whiteAlpha.50">
        <ui.DrawerCloseButton />
        <ui.DrawerHeader p={[2, null, 4]}>Add to Inventory</ui.DrawerHeader>
        <ui.DrawerBody py={0} px={[2, null, 4]}>
          <ui.VStack spacing={8} align="stretch" pos="relative">
            <CatalogControls />

            <ui.VStack
              align="stretch"
              spacing={[2, 4, 6]}
              pos="relative"
              w="full"
            >
              {!getUnaddedProducts.loading && activeCount < 1 && (
                <ui.VStack>
                  <ui.Heading pt={16} size="lg">
                    No Results
                  </ui.Heading>
                </ui.VStack>
              )}

              {activeCount > 0 &&
                [...(products ?? [])].map(item => (
                  <ProductSelectItem
                    key={item._id}
                    item={item}
                    onChange={q => {
                      setSelection(selection => ({
                        ...selection,
                        [item._id]: q,
                      }));
                    }}
                    value={selection[item._id] ?? 0}
                  />
                ))}

              {getUnaddedProducts.loading && (
                <ui.Flex
                  bg="blackAlpha.800"
                  top={-16}
                  bottom={-16}
                  left={-8}
                  right={-8}
                  pos="absolute"
                  display="flex"
                  justify="center"
                >
                  <ui.VStack spacing={8} pt={16}>
                    <ui.CircularProgress
                      color="primary.500"
                      isIndeterminate
                      size={40}
                      thickness={2}
                      trackColor="whiteAlpha.400"
                    >
                      <ui.CircularProgressLabel>
                        <ui.Heading size="md">Searching</ui.Heading>
                      </ui.CircularProgressLabel>
                    </ui.CircularProgress>
                  </ui.VStack>
                </ui.Flex>
              )}
              <ui.HStack
                pos="sticky"
                bottom="0"
                bg="blackAlpha.800"
                py={[2, null, 4]}
                justify="center"
                borderTop="2px"
                borderTopColor="whiteAlpha.50"
              >
                <PageSelector
                  onChange={offset => applyParams({ ...pendingParams, offset })}
                  value={{ ...activeParams, total: activeCount }}
                />
              </ui.HStack>
            </ui.VStack>
          </ui.VStack>
        </ui.DrawerBody>
        <ui.DrawerFooter px={[2, null, 4]}>
          <ui.Flex align="center" justify="space-between" w="full">
            <ui.Heading size="sm" mb={[2, null, 0]}>
              {selectionCount} {selectionCount === 1 ? 'product' : 'products'}{' '}
              selected
            </ui.Heading>

            <ui.Button
              colorScheme="primary"
              isDisabled={!isSelection}
              onClick={() => onConfirm(selection)}
              size={isGteMd ? 'md' : 'sm'}
            >
              Add to Inventory
            </ui.Button>
          </ui.Flex>
        </ui.DrawerFooter>
      </ui.DrawerContent>
    </ui.Drawer>
  );
};
