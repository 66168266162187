import { Action } from 'react-fetching-library';
import { Hostname } from '../hostnames';
import { DeviceConfig, DeviceEvent } from './types';
import { IotApiUtils } from './utils';

/** TOOD: move this stupid hostname protocol logic to a common spot */
const iotHostname = !Hostname.Iot.startsWith('http')
  ? `${location.protocol}//${Hostname.Iot}`
  : Hostname.Iot;

export const eventStreamUrl = (configId: string) =>
  `${iotHostname}/configs/${configId}/events/stream`;

export const deleteDeviceConfig = (configId: string): Action<void> => ({
  endpoint: `/configs/${configId}`,
  hostname: Hostname.Iot,
  method: 'DELETE',
});

export const getDeviceConfig = (configId: string): Action<DeviceConfig> => ({
  endpoint: `/configs/${configId}`,
  hostname: Hostname.Iot,
  method: 'GET',
});

export const getDeviceEvents = (configId: string): Action<DeviceEvent[]> => ({
  endpoint: `/configs/${configId}/events`,
  hostname: Hostname.Iot,
  method: 'GET',
});

export const getAllDeviceConfigs = (): Action<DeviceConfig[]> => ({
  endpoint: `/configs`,
  hostname: Hostname.Iot,
  method: 'GET',
});

export const updateDeviceConfig = (
  config: DeviceConfig
): Action<DeviceConfig> => ({
  endpoint: `/configs/${config.configId}`,
  hostname: Hostname.Iot,
  body: IotApiUtils.subtractConfigBase(config),
  method: 'PATCH',
  onSuccess: () => ({ title: 'Config updated', variant: 'subtle' }),
});
