import { Button, Icon, Spacer, Wrap } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { BiPlus } from 'react-icons/bi';
import { useHistory } from 'react-router';
import {
  createInventory as createInventoryMutation,
  getInventories as getInventoriesQuery,
} from '../../../apis';
import { PageContent, PageHeader } from '../../../layout';
import { CreateInventoryPopover } from '../CreateInventoryPopover';
import { InventoryBox } from './InventoryBox';

export const Inventories: React.FC = () => {
  const history = useHistory();

  const { payload: inventories, query: getInventories } =
    useParameterizedQuery(getInventoriesQuery);

  const { loading: isCreatingInventory, mutate: createInventory } = useMutation(
    createInventoryMutation
  );

  useEffect(() => {
    getInventories({});
  }, [getInventories]);

  return (
    <>
      <PageHeader heading="My Inventories">
        <Spacer />
        <CreateInventoryPopover
          isLoading={isCreatingInventory}
          onSubmit={async name => {
            const { error, payload } = await createInventory({
              name,
              description: '',
            });
            if (error || !payload) return;
            history.push(`/inventorio/inventories/${payload._id}`);
          }}
          trigger={
            <Button
              aria-label="create inventory"
              leftIcon={<Icon as={BiPlus} boxSize={7} />}
              size="sm"
              colorScheme="primary"
            >
              Create Inventory
            </Button>
          }
        />
      </PageHeader>

      <PageContent>
        <Wrap spacing={8}>
          {inventories?.map(inventory => (
            <InventoryBox key={inventory._id} inventory={inventory} />
          ))}
        </Wrap>
      </PageContent>
    </>
  );
};
