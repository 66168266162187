import React, { useEffect, useState } from 'react';

type Props = {
  children?: () => React.ReactNode;
  interval?: number;
};

export const RenderInterval: React.FC<Props> = ({
  children,
  interval = 5000,
}) => {
  const [, rerender] = useState(0);

  useEffect(() => {
    const running = setInterval(() => rerender(i => i + 1), interval);
    return () => clearInterval(running);
  }, []); // eslint-disable-line

  return <>{children?.()}</>;
};
