import {
  chakra,
  Box,
  IconButton,
  Icon,
  Tooltip,
  ThemingProps,
  HTMLChakraProps,
} from '@chakra-ui/react';
import React, { forwardRef, useMemo } from 'react';
import { BiCopy } from 'react-icons/bi';
import { useClipboard } from '../../hooks';

type Props = ThemingProps<'Pre'> &
  HTMLChakraProps<'div'> & {
    contents: any;
    hideCopyButton?: boolean;
  };

export const Pre = forwardRef<HTMLPreElement, Props>(
  ({ contents, hideCopyButton = false, ...htmlProps }, ref) => {
    const { copy } = useClipboard();
    const stringContents = useMemo(() => {
      try {
        return typeof contents !== 'string'
          ? JSON.stringify(contents, null, 2)
          : contents;
      } catch {
        return `Failed to stringify contents (${contents})`;
      }
    }, [contents]);

    return (
      <Box
        as={chakra.pre}
        backgroundColor="whiteAlpha.50"
        fontSize="sm"
        p={[1, 2, 4]}
        pos="relative"
        maxH="xl"
        maxW="full"
        overflow="auto"
        sx={{ '&:hover > button, & > button:focus': { opacity: 1 } }}
        {...htmlProps}
      >
        {!hideCopyButton && (
          <Tooltip label="Copy to clipboard">
            <IconButton
              aria-label="copy"
              className="pre-copy-button"
              colorScheme="gray"
              icon={<Icon as={BiCopy} boxSize={4} />}
              onClick={e => {
                e.preventDefault();
                copy(stringContents);
              }}
              isRound
              pos="absolute"
              right={2}
              opacity={0.32}
              size="sm"
              top={2}
              variant="ghost"
            />
          </Tooltip>
        )}
        {stringContents}
      </Box>
    );
  }
);
