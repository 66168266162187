import type { Filter } from 'mongodb';
import { Action } from 'react-fetching-library';
import { QueryParamUtils } from '../../lib';
import { Hostname } from '../hostnames';
import { ResourceParams } from '../lib';

export interface Product {
  readonly _id: string;
  readonly createdBy: string;
  readonly productId: string;
  readonly productPageUrl: string;
  readonly productImageUrl: string;
  readonly tags: string[];
  readonly title: string;
  readonly vendor: string;
  readonly updated: string;
  readonly updatedBy: string;
}

export type ProductDto = Omit<
  Product,
  '_id' | 'createdBy' | 'productId' | 'updated' | 'updatedBy'
>;

export const productToDto = ({
  _id,
  createdBy,
  productId,
  updated,
  updatedBy,
  ...product
}: Product): ProductDto => ({ ...product });

export const createProduct = (product: ProductDto): Action<Product> => ({
  body: product,
  endpoint: `/catalog`,
  hostname: Hostname.Inventorio,
  method: 'POST',
  onError: ({ errorMessages }) => ({
    title: 'Product creation failed',
    description: errorMessages?.[0],
  }),
  onSuccess: () => ({ title: 'Product created' }),
});

export const deleteProduct = (productId: string): Action<Product> => ({
  endpoint: `/catalog/${productId}`,
  hostname: Hostname.Inventorio,
  method: 'DELETE',
  onSuccess: () => ({ title: 'Product deleted' }),
});

export const getFieldValues = <K extends keyof Product>(
  field: K
): Action<Flatten<Product[K]>> => ({
  endpoint: `/catalog/values/${field}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const getProducts = (
  params: ResourceParams<Product>
): Action<Product[]> => ({
  endpoint: `/catalog${QueryParamUtils.searchFromQueryParams(params)}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const getProductsCount = (q: Filter<Product> = {}): Action<number> => ({
  endpoint: `/catalog/count${QueryParamUtils.searchFromQueryParams({ q })}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const getProduct = (productId: string): Action<Product> => ({
  endpoint: `/catalog/${productId}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const updateProduct = ({
  _id,
  ...product
}: ProductDto & { _id: string }): Action<Product> => ({
  body: product,
  endpoint: `/catalog/${_id}`,
  hostname: Hostname.Inventorio,
  method: 'PATCH',
  onError: ({ errorMessages }) => ({
    title: 'Failed to save',
    description: errorMessages?.[0],
  }),
  // onSuccess: () => ({ title: 'Product saved' }),
});
