import { Button, Icon, Spacer, VStack } from '@chakra-ui/react';
import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { ResourceParamUtils } from '../../../apis';
import { useQueryParams } from '../../../hooks';
import { PageHeader } from '../../../layout';
import { CatalogControls } from '../CatalogControls';
import { CatalogPaginator } from '../CatalogPaginator';
import { ProductList } from '../ProductList';
import { CatalogSearchParamsProvider } from '../CatalogSearchParamsProvider';
import { CatalogSearchCountsProvider } from './CatalogSearchCountsProvider';

export const Catalog: React.FC = () => {
  const { replace: replaceParams, value: queryParams } = useQueryParams();

  return (
    <CatalogSearchParamsProvider
      resourceParamProps={{
        value: ResourceParamUtils.fromDict(queryParams),
        onChange: replaceParams,
      }}
    >
      <CatalogSearchCountsProvider>
        <PageHeader heading="Catalog">
          <Spacer />
          <Button
            as={NavLink}
            aria-label="create product"
            leftIcon={<Icon as={BiPlus} boxSize={5} />}
            size="sm"
            to={({ pathname }: { pathname: string }) => `${pathname}/new`}
            colorScheme="primary"
          >
            Add Product
          </Button>
        </PageHeader>
        <VStack
          direction="column"
          align="stretch"
          pos="relative"
          spacing={8}
          zIndex="1"
          p={4}
        >
          <CatalogControls />
          <ProductList />
        </VStack>
        <CatalogPaginator />
      </CatalogSearchCountsProvider>
    </CatalogSearchParamsProvider>
  );
};
