import React, { isValidElement } from 'react';
import { Column } from './types';

/**
 * Apply the default values to a column configuration object
 */
export function normalizeColumn<T>(column: Partial<Column<T>>): Column<T> {
  return {
    header: () => null,
    data: () => null,
    align: 'left',
    grow: false,
    width: 1,
    ...column,
  };
}

/**
 * If a cell's contents are already a valid element, don't modify them.
 * Otherwise, wrap them in the default styling for the cell type (header or data).
 */
export const headerElementFromContents = (
  headerContents: React.ReactNode
): React.ReactElement =>
  isValidElement(headerContents) ? (
    headerContents
  ) : (
    <label>{headerContents}</label>
  );

export const dataElementFromContents = (
  dataContents: React.ReactNode
): React.ReactElement =>
  isValidElement(dataContents) ? dataContents : <p>{dataContents}</p>;
