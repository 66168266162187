import * as ui from '@chakra-ui/react';
import React from 'react';
import { useEditableFieldContext } from './useEditableField';

export interface EditableFieldPreviewProps extends ui.HTMLChakraProps<'div'> {}

export const EditableFieldPreview = ui.forwardRef<
  EditableFieldPreviewProps,
  'span'
>((props, ref) => {
  const { getPreviewProps } = useEditableFieldContext();
  const styles = ui.useStyles();
  const previewProps = getPreviewProps(props, ref);

  if (React.isValidElement(props.children)) {
    const { children, ...customPreviewProps } = previewProps; // eslint-disable-line
    return React.cloneElement(props.children, customPreviewProps);
  }

  return (
    <ui.chakra.span
      {...previewProps}
      __css={{
        cursor: 'text',
        display: 'inline-block',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        textAlign: 'inherit',
        bg: 'transparent',
        ...styles.preview,
      }}
    />
  );
});

EditableFieldPreview.displayName = 'EditableFieldPreview';
