/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@chakra-ui/button';
import { HStack } from '@chakra-ui/layout';
import React from 'react';
import { PaginationParams } from '../../apis';

type Props = {
  onChange: (offest: number) => void;
  value: PaginationParams & { total: number };
};

export const PageSelector: React.FC<Props> = ({
  onChange,
  value: { n, offset, total },
}) => {
  const pageCount = Math.ceil(total / n);
  const currentPage = Math.floor(offset / n);

  return (
    <>
      {[...Array(pageCount).keys()].map(i => (
        <Button
          key={i}
          onClick={() => onChange(i * n)}
          colorScheme={i === currentPage ? 'primary' : 'gray'}
          variant={i === currentPage ? 'solid' : 'ghost'}
          fontSize="md"
        >
          {i + 1}
        </Button>
      ))}
    </>
  );
};
