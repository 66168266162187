import { Center, Heading } from '@chakra-ui/react';
import React from 'react';
import { PageHeader, PageContent } from '../../../layout';

type Props = {};

export const Projects: React.FC<Props> = ({}) => {
  return (
    <>
      <PageHeader heading="My Projects"></PageHeader>

      <PageContent>
        <Center>
          <Heading fontSize="9xl">WIP</Heading>
        </Center>
      </PageContent>
    </>
  );
};
