import React, { useRef } from 'react';
import {
  default as SimpleSelect,
  Props as SimpleProps,
  GroupBase,
} from 'react-select';
import AsyncReactSelect, { AsyncProps } from 'react-select/async';
import AsyncCreatableReactSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable';
import CreatableReactSelect, { CreatableProps } from 'react-select/creatable';
import { ReactSelect, ReactSelectProps } from './ReactSelect';

export function AsyncCreatableSelect<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: Omit<
    ReactSelectProps<
      Option,
      IsMulti,
      Group,
      AsyncCreatableProps<Option, IsMulti, Group>
    >,
    'children'
  >
) {
  const ref = useRef();
  return (
    <ReactSelect<Option, IsMulti, Group> {...props}>
      <AsyncCreatableReactSelect ref={ref as any} />
    </ReactSelect>
  );
}

export function AsyncSelect<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: Omit<
    ReactSelectProps<
      Option,
      IsMulti,
      Group,
      AsyncProps<Option, IsMulti, Group>
    >,
    'children'
  >
) {
  const ref = useRef();
  return (
    <ReactSelect<Option, IsMulti, Group> {...(props as any)}>
      <AsyncReactSelect ref={ref as any} />
    </ReactSelect>
  );
}

function CreatableSelectInner<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: Omit<
    ReactSelectProps<
      Option,
      IsMulti,
      Group,
      CreatableProps<Option, IsMulti, Group>
    >,
    'children'
  >,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  return (
    <ReactSelect<Option, IsMulti, Group> {...props}>
      <CreatableReactSelect ref={ref as any} />
    </ReactSelect>
  );
}

CreatableSelectInner.displayName = 'CreatableSelect';
export const CreatableSelect = React.forwardRef(CreatableSelectInner);

export function Select<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: Omit<
    ReactSelectProps<
      Option,
      IsMulti,
      Group,
      SimpleProps<Option, IsMulti, Group>
    >,
    'children'
  >
) {
  const ref = useRef();
  return (
    <ReactSelect<Option, IsMulti, Group> {...props}>
      <SimpleSelect ref={ref as any} />
    </ReactSelect>
  );
}
