import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const SuperTableTheme: ComponentMultiStyleConfig = {
  parts: [
    'container',
    'table',
    'th',
    'td',
    'tbody',
    'thead',
    'tfooter',
    'row', // incorporates `tr`, `td`
    'column', // incorporates `th`, `td`
  ],
  baseStyle: {
    table: {
      fontVariantNumeric: 'lining-nums tabular-nums',
      borderCollapse: 'separate',
      borderSpacing: 0,
      width: 'full',
      tableLayout: 'fixed',
      pos: 'relative',
      '.pin-top': {
        boxShadow: `0px 0px 2px 2px rgb(255 255 255 / 0.04)`,
      },
      '&.not-at-top .pin-top': {
        boxShadow: `0px 0px 10px 0 rgb(255 255 255 / 0.36)`,
        transition: `box-shadow .333s`,
      },
      '.pin-left::after, .pin-right::after': {
        content: '""',
        pos: 'absolute',
        top: 0,
        bottom: 0,
        width: '10px',
        transition: `box-shadow .333s`,
      },
      '.pin-left::after': { right: 0, transform: 'translateX(100%)' },
      '&.not-at-left .pin-left::after': {
        boxShadow: `inset 8px 0 8px -8px rgba(255, 255, 255, 0.36)`,
      },
      '.pin-right::after': { left: 0, transform: 'translateX(-100%)' },
      '&.not-at-right .pin-right::after': {
        boxShadow: `inset -8px 0 8px -8px rgba(255, 255, 255, 0.36)`,
      },
    },
    thead: {},
    th: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      letterSpacing: 'wider',
      position: 'relative',
      textAlign: 'start',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    td: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  sizes: {
    sm: {
      th: { px: '4', py: '1', lineHeight: '4', fontSize: 'xs' },
      td: { px: '4', py: '2', fontSize: 'sm', lineHeight: '4' },
      caption: { px: '4', py: '2', fontSize: 'xs' },
    },
    md: {
      th: { px: '4', py: '4', lineHeight: '5', fontSize: 'xs' },
      td: { px: '4', py: '2', lineHeight: '5', fontSize: 'sm' },
      caption: { px: '6', py: '2', fontSize: 'sm' },
    },
    lg: {
      th: { px: '8', py: '4', lineHeight: '5', fontSize: 'sm' },
      td: { px: '8', py: '5', lineHeight: '6' },
      caption: { px: '6', py: '2', fontSize: 'md' },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
