/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Flex,
  LinkBox,
  LinkOverlay,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { NavLink } from 'react-router-dom';
import { getInventoryItemCount, Inventory } from '../../../apis';

type Props = {
  inventory: Inventory;
};

export const InventoryBox: React.FC<Props> = ({ inventory }) => {
  const {
    payload: count,
    query: getCount,
    loading,
  } = useParameterizedQuery(getInventoryItemCount);

  useEffect(() => {
    getCount(inventory._id);
  }, [getCount, inventory._id]);

  return (
    <LinkBox
      key={inventory._id}
      as={Flex}
      direction="column"
      align="center"
      p={12}
      border="2px"
      borderColor="whiteAlpha.50"
      sx={{
        _hover: { borderColor: 'white' },
        _active: { bgColor: 'whiteAlpha.50' },
      }}
    >
      <LinkOverlay
        as={NavLink}
        to={`/inventorio/inventories/${inventory._id}`}
      />

      <Stat pointerEvents="none" size="xl" align="center">
        <StatLabel textTransform="none">{inventory.name}</StatLabel>
        <StatNumber filter={loading ? 'blur(0.2rem)' : ''}>
          {count ?? '00'} items
        </StatNumber>
      </Stat>
    </LinkBox>
  );
};
