import {
  // Button,
  ButtonProps,
  HTMLChakraProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  ThemingProps,
} from '@chakra-ui/react';
import React from 'react';
import { Product, ResourceParams } from '../../../apis';

type Props = ThemingProps<'Button'> &
  ButtonProps &
  HTMLChakraProps<'button'> & {
    activeParams: ResourceParams<Product>;
    activeSortKey: string;
    activeSortOrder: -1 | 1;
    applyParams: (args: ResourceParams<Product>) => void;
  };

const sortOptions: Array<{ label: string; value: keyof Product }> = [
  { value: '_id', label: 'Creation Date' },
  { value: 'title', label: 'Title' },
  { value: 'updated', label: 'Update Time' },
  { value: 'vendor', label: 'Vendor' },
];

export const SortMenu: React.FC<Props> = ({
  activeParams,
  activeSortKey,
  activeSortOrder,
  applyParams,
  ...buttonProps
}) => (
  <Menu>
    <MenuButton
      // as={Button}
      {...buttonProps}
    >
      {sortOptions.find(opt => opt.value === activeSortKey)?.label}
    </MenuButton>
    <MenuList>
      <MenuOptionGroup
        value={`${activeSortOrder}`}
        onChange={order =>
          applyParams({
            ...activeParams,
            offset: 0,
            sort: { [activeSortKey]: +order as 1 | -1 },
          })
        }
        title="Order"
        type="radio"
      >
        <MenuItemOption value="1">Ascending</MenuItemOption>
        <MenuItemOption value="-1">Descending</MenuItemOption>
      </MenuOptionGroup>
      <MenuDivider />
      <MenuOptionGroup
        value={activeSortKey}
        onChange={sortKey =>
          applyParams({
            ...activeParams,
            offset: 0,
            sort: { [sortKey as string]: activeSortOrder },
          })
        }
        type="radio"
      >
        {sortOptions.map(opt => (
          <MenuItemOption key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </MenuList>
  </Menu>
);
