import * as ui from '@chakra-ui/react';
import * as DREI from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useState } from 'react';
import { BiMove, BiRefresh, BiTrashAlt } from 'react-icons/bi';
import * as THREE from 'three';
import { useMergeRefs } from '../../hooks';
import { Item, Part, PartDirection } from '../types';
import { TILE_SIZE, useStore } from '../useStore';
import { directionAngle } from '../utils';

export type PartObjectProps = {
  dir: PartDirection;
  x: number;
  y: number;
  items: Item[];
  isHovered: boolean;
  isSelected: boolean;
  onMoveStart: () => void;
  onSelect: () => void;
  onRemove: () => void;
  onRotate: () => void;
  placement: {
    isPending: boolean;
  } & (
    | {
        isNew: true;
      }
    | {
        isNew: false;
        referenceComponent: Part;
      }
  );
};

type Props = Part & {
  children?: (args: PartObjectProps) => React.ReactNode;
};

export const PartObject = React.forwardRef<THREE.Group, Props>(
  ({ children, dir, x, y, id }, ref) => {
    const groupRef = React.useRef<THREE.Group>(null!);
    const { items, selectedPart, parts, partPendingPlacement, actions } =
      useStore();
    const [isHovered, setIsHovered] = useState(false);

    const isSelected = selectedPart?.id === id;
    const isPending = partPendingPlacement?.id === id;
    const referenceComponent = parts.find(
      piece => piece.id === partPendingPlacement?.originId
    );
    const isNew = partPendingPlacement?.originId === id;
    const isCopyOfPiece = isPending && partPendingPlacement.originId;
    const originPiece = parts.find(
      piece => piece.id === partPendingPlacement?.originId
    );

    const shouldLevitate =
      !!partPendingPlacement && partPendingPlacement.originId === id;

    // Don't show if I'm pending placement and I'm in the same position
    // as my origin piece
    const shouldHide =
      isPending &&
      isCopyOfPiece &&
      originPiece?.x === x &&
      originPiece?.y === y;

    const showHtml = isSelected && !isPending && !isNew;

    useFrame(() => {
      if (groupRef) {
        groupRef.current.visible = !shouldHide;
        const { position, rotation } = groupRef.current;
        const newX = THREE.MathUtils.lerp(position.x, x * TILE_SIZE, 0.2);
        const newY = THREE.MathUtils.lerp(position.y, y * TILE_SIZE, 0.2);
        const newZ = THREE.MathUtils.lerp(
          position.z,
          shouldLevitate ? TILE_SIZE : 0,
          0.05
        );
        position.set(newX, newY, newZ);
        const newRot = directionAngle(dir) + (shouldLevitate ? 2 * Math.PI : 0);
        const currentZ =
          newRot < rotation.z ? (rotation.z -= 2 * Math.PI) : rotation.z;
        rotation.z = THREE.MathUtils.lerp(currentZ, newRot, 0.1);
      }
    });

    return (
      <group
        ref={useMergeRefs(ref, groupRef)}
        onClick={e => {
          if (partPendingPlacement) return;
          e.stopPropagation();
          actions.selectPartById(id);
        }}
        onPointerOver={e => {
          if (partPendingPlacement) return;
          setIsHovered(true);
        }}
        onPointerOut={e => {
          setIsHovered(false);
        }}
      >
        {children?.({
          dir,
          x,
          y,
          isHovered,
          isSelected,
          items: items.filter(i => i.x === x && i.y === y),
          onMoveStart: actions.setSelectedAsPendingPlacement,
          onRemove: actions.removeSelectedPart,
          onRotate: actions.rotatedSelectedPart,
          onSelect: () => actions.selectPartById(id),
          placement: {
            isPending: isPending,
            isNew,
            referenceComponent: !isNew ? undefined! : referenceComponent!,
          },
        })}
        {showHtml && (
          <DREI.Html>
            <ui.Flex
              bg="black"
              direction="column"
              sx={{
                padding: '0.5rem 1rem',
                borderRadius: '1rem',
                transform: `translate3d(-50%, -200%, 0)`,
              }}
            >
              <ui.HStack spacing={32}>
                <ui.IconButton
                  aria-label="refresh device"
                  icon={<ui.Icon as={BiRefresh} boxSize={32} />}
                  size="md"
                  colorScheme="gray"
                  onClick={actions.rotatedSelectedPart}
                  variant="ghost"
                />
                <ui.IconButton
                  aria-label="show config"
                  icon={<ui.Icon as={BiMove} boxSize={28} />}
                  colorScheme="gray"
                  onClick={actions.setSelectedAsPendingPlacement}
                  variant="ghost"
                />
                <ui.IconButton
                  aria-label="refresh device"
                  icon={<ui.Icon as={BiTrashAlt} boxSize={28} />}
                  size="md"
                  colorScheme="gray"
                  onClick={actions.removeSelectedPart}
                  variant="ghost"
                />
              </ui.HStack>
            </ui.Flex>
          </DREI.Html>
        )}
      </group>
    );
  }
);
