import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const VideoCameraTheme: ComponentMultiStyleConfig = {
  parts: ['container', 'video'],
  baseStyle: {
    container: {
      border: '2px',
    },
    video: {
      border: '2px',
    },
    // table: {
    //   fontVariantNumeric: 'lining-nums tabular-nums',
    //   borderCollapse: 'separate',
    //   borderSpacing: 0,
    //   width: 'full',
    //   pos: 'relative',
    // },
    // thead: {},
    // th: {
    //   bgColor: 'black',
    //   fontFamily: 'heading',
    //   fontWeight: 'bold',
    //   letterSpacing: 'wider',
    //   position: 'relative',
    //   textAlign: 'start',
    //   textTransform: 'uppercase',
    //   whiteSpace: 'nowrap',
    //   borderBottom: '2px',
    //   borderColor: 'whiteAlpha.200',
    // },
    // td: {
    //   isTruncated: 1,
    //   bgColor: 'black',
    //   whiteSpace: 'nowrap',
    // },
  },
  sizes: {
    // sm: {
    //   th: { px: '4', py: '1', lineHeight: '4', fontSize: 'xs' },
    //   td: { px: '4', py: '2', fontSize: 'sm', lineHeight: '4' },
    //   caption: { px: '4', py: '2', fontSize: 'xs' },
    // },
    md: {
      th: { px: '6', py: '4', lineHeight: '4', fontSize: 'xs' },
      td: { px: '6', py: '4', lineHeight: '5' },
      caption: { px: '6', py: '2', fontSize: 'sm' },
    },
    // lg: {
    //   th: { px: '8', py: '4', lineHeight: '5', fontSize: 'sm' },
    //   td: { px: '8', py: '5', lineHeight: '6' },
    //   caption: { px: '6', py: '2', fontSize: 'md' },
    // },
  },
  defaultProps: {
    size: 'md',
  },
};
