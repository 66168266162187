import { useCallback, useState } from 'react';
import { ResourceParams } from './types';

export type UseResourceParamsProps<T> = {
  /** Called when params are being set; use it to persist values */
  onChange?: (params: ResourceParams<T>) => void;
  /** The initial value of the resource params */
  value: ResourceParams<T>;
};

type UseResourceParams<T> = {
  params: ResourceParams<T>;
  set: (params: ResourceParams<T>) => void;
  setFilter: (filter: ResourceParams<T>['q']) => void;
  setOffset: (offset: ResourceParams<T>['offset']) => void;
  setOrToggleSort: (key: DotNestedKeys<T>) => void;
};

export function useResourceParams<T>({
  onChange,
  value,
}: UseResourceParamsProps<T>): UseResourceParams<T> {
  const [params, setParams] = useState(value);

  const set = useCallback(
    (params: ResourceParams<T>) => {
      setParams(params);
      onChange?.(params);
    },
    [onChange, setParams]
  );

  const setFilter = useCallback(
    (q: ResourceParams<T>['q']) => set({ ...params, q }),
    [params, set]
  );

  const setOffset = useCallback(
    (offset: ResourceParams<T>['offset']) => set({ ...params, offset }),
    [params, set]
  );

  const setOrToggleSort = useCallback(
    (key: DotNestedKeys<T>) =>
      set({
        ...params,
        offset: 0,
        sort: {
          [key]: params.sort[key] === 1 ? -1 : 1,
        } as typeof params['sort'],
      }),
    [params, set]
  );

  return { params, set, setFilter, setOffset, setOrToggleSort };
}
