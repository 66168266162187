import { QueryResponse } from 'react-fetching-library';
import { Cache } from './types';

// const actionCacheKey = (action: Action) =>
//   Buffer.from(JSON.stringify(action)).toString('base64');

export const cacheProvider = (): Cache<QueryResponse<any>> => {
  return {
    add: (action, value) => {
      // console.log('add - action, value: ', action, value);
    },
    remove: action => {
      // console.log('remove - action: ', action);
    },
    get: action => {
      // console.log('get - action: ', action);
      return undefined;
    },
    getItems: () => {
      // console.log('getItems');
      return {};
    },
    setItems: items => {
      // console.log('setItems - items', items);
    },
  };
};
