/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as ui from '@chakra-ui/react';
import React from 'react';
import { Pre } from '../Pre';
import { useMediaSettings } from './useMediaSettings';

type Props = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

export const VideoCamera = React.forwardRef<HTMLVideoElement, Props>(
  ({ ...props }, ref) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const mediaSettings = useMediaSettings();

    React.useEffect(() => {
      (async () => {
        const stream = await window.navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current!.srcObject = stream;
      })();
    }, []);

    return (
      <>
        <video
          autoPlay
          preload="none"
          ref={ui.useMergeRefs(videoRef, ref)}
          {...props}
        />
        <Pre contents={mediaSettings} />
      </>
    );
  }
);
