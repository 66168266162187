import { createContext, useContext } from 'react';

interface CatalogSearchCountsContext {
  activeCount: number;
  activeCountIsLoading: boolean;
  pendingCount: number;
  pendingCountIsLoading: boolean;
}

export const CatalogSearchCountsContext =
  createContext<CatalogSearchCountsContext>({
    activeCount: 0,
    activeCountIsLoading: false,
    pendingCount: 0,
    pendingCountIsLoading: false,
  });

CatalogSearchCountsContext.displayName = 'CatalogSearchCountsContext';

export const useCatalogSearchCounts = () =>
  useContext(CatalogSearchCountsContext);
