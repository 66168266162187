/* eslint-disable @typescript-eslint/no-unused-vars */
import * as ui from '@chakra-ui/react';
import React from 'react';
import { PageContent, PageHeader } from '../../../layout';
import { BarcodeCapture, CapturedBarcode, Pre } from '../../../shared';

type Props = {};

export const CreateProductUsingBarcode: React.FC<Props> = ({}) => {
  const [barcodes, setBarcodes] = React.useState<CapturedBarcode[]>([]);
  return (
    <>
      <PageHeader heading="Barcode Capture" />
      <PageContent align="flex-start">
        {barcodes.length < 1 && <BarcodeCapture onCapture={setBarcodes} />}
        {barcodes.length > 0 && (
          <ui.Button onClick={() => setBarcodes([])}>Scan again</ui.Button>
        )}
        {barcodes.map((barcode, i) => (
          <ui.Box key={i} pos="relative">
            <CapturedBarcodeCanvas {...barcode} />
            <ui.Heading
              pos="absolute"
              bottom="0"
              left="0"
              right="0"
              size="lg"
              p={3}
              bg="blackAlpha.500"
              textAlign="center"
            >
              {barcode.format} - {barcode.rawValue}
            </ui.Heading>
          </ui.Box>
        ))}
      </PageContent>
    </>
  );
};

const CapturedBarcodeCanvas: React.FC<CapturedBarcode> = ({
  data,
  boundingBox,
  cornerPoints,
}) => (
  <canvas
    ref={canvas => {
      if (!canvas) return;
      const ctx = canvas.getContext('2d')!;
      ctx.putImageData(data, 0, 0);
      ctx.lineWidth = 4;
      ctx.strokeStyle = '#8f03ff';
      ctx.beginPath();
      cornerPoints.forEach(({ x, y }) => {
        ctx.lineTo(x, y);
        ctx.ellipse(x, y, 5, 5, 0, 0, 2 * Math.PI);
      });
      ctx.stroke();
    }}
    height={data.height}
    width={data.width}
    style={{ maxWidth: '100%' }}
  />
);
