import { chakra, HTMLChakraProps } from '@chakra-ui/react';
import React from 'react';

export const OptionIcon: React.FC<HTMLChakraProps<'span'>> = ({
  children,
  ...props
}) => {
  const child = React.Children.only(children) as React.ReactChild;

  const clone = React.isValidElement(child)
    ? React.cloneElement(child, {
        focusable: 'false',
        'aria-hidden': true,
        className: child.props.className, // eslint-disable-line
      })
    : null;

  return (
    <chakra.span {...props} __css={{ flexShrink: 0 }}>
      {clone}
    </chakra.span>
  );
};

export const CheckIcon: React.FC<HTMLChakraProps<'svg'>> = ({ ...props }) => (
  <chakra.svg viewBox="0 0 14 14" width="1em" height="1em" {...props}>
    <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
  </chakra.svg>
);
