import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Center,
  Code,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useMutation } from 'react-fetching-library';
import { BiCopy, BiScan } from 'react-icons/bi';
import { ProductDto, scrapeMetadata } from '../../../apis';
import { useClipboard } from '../../../hooks';
import { Loader } from '../../../shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUseValue: <K extends keyof ProductDto>(
    key: K,
    value: ProductDto[K]
  ) => void;
  value: ProductDto;
};

const predictFormKeys = ([k, v]: [string, string]): Array<keyof ProductDto> => {
  const keys: Array<keyof ProductDto> = [];
  /** title */
  if (k.endsWith(':title')) keys.push('title');
  /** productImageUrl */
  if (k.endsWith(':image')) keys.push('productImageUrl');
  /** vendor */
  if (k === 'author' || k.endsWith('site_name') || k.endsWith('site'))
    keys.push('vendor');

  return keys;
};

const getMatchedFormKeys = (
  value: ProductDto,
  [k, v]: [string, string]
): Array<keyof ProductDto> =>
  Object.entries(value)
    .filter(([key, keyValue]) => keyValue === v)
    .map(([key]) => key as keyof ProductDto);

const keyToText = (k: keyof ProductDto): string => {
  switch (k) {
    case 'vendor':
      return 'Vendor';
    case 'productImageUrl':
      return 'Image URL';
    case 'productPageUrl':
      return 'Product URL';
    case 'title':
      return 'Title';
    default:
      return '';
  }
};

export const ProductScraper: React.FC<Props> = ({
  isOpen,
  onClose,
  onUseValue,
  value,
}) => {
  const { copy } = useClipboard();

  const {
    payload: metadata = [],
    mutate: scrape,
    error,
    errorObject,
    reset,
    loading: isScraping,
  } = useMutation(scrapeMetadata);

  useEffect(() => {
    reset();
  }, [value.productPageUrl, reset]);

  useEffect(() => {
    if (isOpen && !isScraping && !error && metadata.length < 1) {
      scrape(value.productPageUrl);
    }
  }, [
    error,
    isOpen,
    isScraping,
    metadata.length,
    value.productPageUrl,
    scrape,
  ]);

  return (
    <>
      <Drawer size="xl" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent borderLeft="2px" borderLeftColor="whiteAlpha.50">
          <DrawerCloseButton />
          <DrawerHeader>Scrape Product Metadata</DrawerHeader>
          <DrawerBody>
            {/* Loading */}
            {isScraping && (
              <Center h="full">
                <VStack spacing={8}>
                  <Loader size={16} color="white" />
                  <Heading size="md">Scraping</Heading>
                </VStack>
              </Center>
            )}

            {/* Error */}
            {!isScraping && error && (
              <Center h="full" px={12}>
                <Alert status="error">
                  <AlertIcon />
                  <Box flex={1}>
                    <AlertTitle>
                      {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        errorObject.error ?? `Something ain't right`
                      }
                    </AlertTitle>
                    <AlertDescription>
                      {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        errorObject?.message
                      }
                    </AlertDescription>
                  </Box>
                </Alert>
              </Center>
            )}

            {/* Not run yet */}
            {!isScraping && !error && metadata.length < 1 && (
              <Center h="full">
                <Button
                  onClick={() => scrape(value.productPageUrl)}
                  leftIcon={<BiScan size={36} />}
                  variant="ghost"
                  colorScheme="gray"
                  p={8}
                  size="lg"
                >
                  Run Scraper
                </Button>
              </Center>
            )}

            {metadata.length > 0 && (
              <Box
                overflow="scroll"
                maxW="full"
                sx={{ '& *': { maxW: 'full' } }}
              >
                <VStack align="flex-start" spacing={6}>
                  {[...metadata]
                    .map(([k, v]) => {
                      const matches = getMatchedFormKeys(value, [k, v]);
                      const suggestions = predictFormKeys([k, v]).filter(
                        key => !matches.includes(key)
                      );
                      return [k, v, suggestions, matches] as const;
                    })
                    .sort(([, , tags1, matches1], [, , tags2, matches2]) =>
                      tags1.length + matches1.length >
                      tags2.length + matches2.length
                        ? -1
                        : 1
                    )
                    .map(([k, value, suggestedKeys, matchedKeys], i) => (
                      <VStack
                        key={`${k}.${value}`}
                        align="flex-start"
                        spacing={1.5}
                      >
                        <HStack spacing={2} px={1}>
                          <Tooltip hasArrow label="Copy value">
                            <IconButton
                              aria-label="copy value"
                              colorScheme="gray"
                              icon={<BiCopy />}
                              onClick={() => copy(value)}
                              size="sm"
                              variant="ghost"
                            />
                          </Tooltip>
                          <Tooltip hasArrow label="Click to copy">
                            <VStack align="stretch" spacing={0.5}>
                              <Text color="whiteAlpha.500" fontSize="xs">
                                {k}
                              </Text>
                              <Code
                                bg="transparent"
                                isTruncated
                                size="sm"
                                fontWeight="bold"
                              >
                                {value}
                              </Code>
                            </VStack>
                          </Tooltip>
                        </HStack>

                        {(suggestedKeys.length > 0 ||
                          matchedKeys.length > 0) && (
                          <HStack pl={12}>
                            {suggestedKeys.map(formKey => (
                              <Button
                                key={formKey}
                                onClick={() => onUseValue(formKey, value)}
                                size="xs"
                              >
                                Use as {keyToText(formKey)}
                              </Button>
                            ))}
                            {matchedKeys.map(key => (
                              <Badge
                                key={key}
                                variant="outline"
                                textTransform="none"
                              >
                                Using as {keyToText(key)}
                              </Badge>
                            ))}
                          </HStack>
                        )}
                      </VStack>
                    ))}
                </VStack>
              </Box>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
