import { CSSWithMultiValues, RecursiveCSSObject } from '@chakra-ui/react';
import { Pin } from './types';

export const pinStyles = ([
  pin,
  ...rest
]: Pin[]): RecursiveCSSObject<CSSWithMultiValues> => {
  const base = { pos: 'sticky', zIndex: 3 };

  switch (pin) {
    case 'bottom':
      return { ...base, insetBlockEnd: 0, zIndex: 4, ...pinStyles(rest) };
    case 'left':
      return { ...base, insetInlineStart: 0, ...pinStyles(rest) };
    case 'right':
      return { ...base, insetInlineEnd: 0, ...pinStyles(rest) };
    case 'top':
      return {
        ...base,
        insetBlockStart: 0,
        zIndex: 4,
        ...pinStyles(rest),
      };
    default:
      return {};
  }
};
