import {
  Box,
  HTMLChakraProps,
  StylesProvider,
  ThemingProps,
  useControllableState,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { getValidChildren } from '@chakra-ui/react-utils';
import { omitThemingProps } from '@chakra-ui/system';
import React from 'react';
import { OptionComponentId } from './Option';

type ValueProps =
  | {
      type: 'checkbox';
      onChange?: (value: string[]) => void;
      value?: string[];
    }
  | {
      type: 'radio';
      onChange?: (value: string) => void;
      value?: string;
    };

type Props = ThemingProps<'Menu'> &
  Omit<HTMLChakraProps<'div'>, 'value' | 'defaultValue' | 'onChange'> &
  ValueProps & { children?: React.ReactNode };

export const OptionGroup: React.FC<Props> = props => {
  /** Anatomy: [container, option] */
  const styles = useMultiStyleConfig('OptionGroup', props);

  const {
    type = 'checkbox',
    value: valueProp,
    onChange: onChangeProp,
    ...htmlProps
  } = omitThemingProps(props);

  const [value, setValue] = useControllableState<string | string[]>({
    defaultValue: type === 'radio' ? '' : [],
    value: valueProp,
    onChange: onChangeProp as any,
  });

  const onChange = React.useCallback(
    (selectedValue: string) => {
      if (type === 'radio' && typeof value === 'string') {
        setValue(selectedValue);
      }

      if (type === 'checkbox' && Array.isArray(value)) {
        const nextValue = value.includes(selectedValue)
          ? value.filter(v => v !== selectedValue)
          : [...value, selectedValue];

        setValue(nextValue);
      }
    },
    [value, setValue, type]
  );

  const children = getValidChildren(props.children).map(child => {
    // eslint-disable-next-line
    if ((child.type as any).id !== OptionComponentId) {
      return child;
    }

    const onClick = (event: MouseEvent) => {
      onChange(child.props.value); // eslint-disable-line

      child.props.onClick?.(event); // eslint-disable-line
    };

    const isChecked =
      type === 'radio'
        ? child.props.value === value // eslint-disable-line
        : value.includes(child.props.value); // eslint-disable-line

    return React.cloneElement(child, {
      onClick,
      isChecked,
    });
  });

  return (
    <StylesProvider value={styles}>
      <Box __css={styles.container} role="group" {...htmlProps}>
        {children}
      </Box>
    </StylesProvider>
  );
};
