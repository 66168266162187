/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

type Devices = {
  cameras: MediaDeviceInfo[];
  microphones: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
};

export const useMediaSettings = () => {
  const [devices, setDevices] = React.useState<Devices>({
    cameras: [],
    microphones: [],
    speakers: [],
  });

  const [audioSettings, setAudioSettings] = React.useState<
    boolean | MediaTrackConstraints
  >(false);
  const [videoSettings, setVideoSettings] = React.useState<
    boolean | MediaTrackConstraints
  >(true);

  const mediaStreamConstraints: MediaStreamConstraints = {
    audio: audioSettings,
    video: videoSettings,
  };

  const onDeviceChange = (e: any) => {
    console.log('device change event!', e);
  };

  /** Listen for `devicechange` events */
  React.useEffect(() => {
    window.navigator.mediaDevices.addEventListener(
      'devicechange',
      onDeviceChange
    );

    return () => {
      window.navigator.mediaDevices.removeEventListener(
        'devicechange',
        onDeviceChange
      );
    };
  }, []);

  /** Enumerate the device on mount */
  React.useEffect(() => {
    (async () => {
      const ds = await window.navigator.mediaDevices.enumerateDevices();
      setDevices({
        cameras: ds.filter(d => d.kind === 'videoinput'),
        microphones: ds.filter(d => d.kind === 'audioinput'),
        speakers: ds.filter(d => d.kind === 'audiooutput'),
      });
    })();
  }, [setDevices]);

  return {
    mediaStreamConstraints,
    devices,
    audioSettings,
    videoSettings,
  };
};
