import {
  Box,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMergeRefs,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import { TableState } from './types';

type Props = HTMLChakraProps<'div'> &
  ThemingProps<'SuperTable'> & { children?: React.ReactNode };

const SuperTableContainerContext = React.createContext<TableState>({
  isAtLeft: true,
  isAtTop: true,
  isAtBottom: true,
  isAtRight: true,
});

export const useSuperTableContainer = (): TableState =>
  React.useContext(SuperTableContainerContext);

export const SuperTableContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...innerProps }, ref) => {
    const internalRef = React.createRef<HTMLDivElement>();
    const styles = useMultiStyleConfig('SuperTable', innerProps);
    const { ...htmlProps } = omitThemingProps(innerProps);
    const [isAtBottom, setIsAtBottom] = React.useState(true);
    const [isAtLeft, setIsAtLeft] = React.useState(true);
    const [isAtRight, setIsAtRight] = React.useState(true);
    const [isAtTop, setIsAtTop] = React.useState(true);

    const handler = React.useCallback((target: HTMLDivElement | null) => {
      if (!target) return;

      setIsAtLeft(target.scrollLeft <= 8);
      setIsAtTop(target.scrollTop <= 8);
      setIsAtBottom(
        target.scrollHeight - (target.clientHeight + target.scrollTop) <= 8
      );
      setIsAtRight(
        target.scrollWidth - (target.clientWidth + target.scrollLeft) <= 8
      );
    }, []);

    React.useEffect(() => {
      const targetElement = internalRef?.current;
      if (!targetElement) return;
      const eventListener = (event: Event) => {
        handler(event.target as HTMLDivElement);
      };

      targetElement.addEventListener('scroll', eventListener);
      return () => targetElement.removeEventListener('scroll', eventListener);
    }, [internalRef, handler]);

    React.useEffect(() => {
      handler(internalRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handler]);

    return (
      <Box
        __css={styles}
        w="full"
        overflow="auto"
        flexGrow={1}
        ref={useMergeRefs(internalRef, ref as any)}
        {...htmlProps}
      >
        <SuperTableContainerContext.Provider
          value={{
            isAtBottom,
            isAtLeft,
            isAtRight,
            isAtTop,
          }}
        >
          {children}
        </SuperTableContainerContext.Provider>
      </Box>
    );
  }
);
