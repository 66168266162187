import { Location, PartDirection } from './types';

export const nextDirection = (dir: PartDirection) =>
  dir === PartDirection.Down ? PartDirection.Right : dir + 1;

export const directionAngle = (dir: PartDirection) => {
  switch (dir) {
    case PartDirection.Right:
      return 0;
    case PartDirection.Up:
      return Math.PI / 2;
    case PartDirection.Left:
      return Math.PI;
    case PartDirection.Down:
      return (3 * Math.PI) / 2;
  }
};

export const nextLocation = (
  x: number,
  y: number,
  dir: PartDirection
): Location => {
  switch (dir) {
    case PartDirection.Right:
      return { x: x + 1, y };
    case PartDirection.Up:
      return { x, y: y + 1 };
    case PartDirection.Left:
      return { x: x - 1, y };
    case PartDirection.Down:
      return { x, y: y - 1 };
  }
};
