import * as ui from '@chakra-ui/react';
import React from 'react';
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { DeviceActionType, DeviceConfig } from '../../apis';
import { ActionForm } from '../ActionForm';

type Props = {
  onChange: (value: DeviceConfig) => void;
  value: DeviceConfig;
};

export const ConfigForm: React.FC<Props> = ({ onChange, value }) => {
  return (
    <ui.VStack spacing={16} align="flex-start">
      <ui.FormControl>
        <ui.FormLabel>Ping Interval</ui.FormLabel>
        <ui.NumberInput
          min={1}
          id="ping-interval-seconds"
          onChange={numString =>
            onChange({
              ...value,
              pingIntervalSeconds: +numString,
            })
          }
          value={value.pingIntervalSeconds}
        >
          <ui.NumberInputField />
          <ui.NumberInputStepper>
            <ui.NumberIncrementStepper />
            <ui.NumberDecrementStepper />
          </ui.NumberInputStepper>
        </ui.NumberInput>
        <ui.FormHelperText>Interval is in seconds</ui.FormHelperText>
      </ui.FormControl>
      {value.actions.map((action, index) => (
        <ui.VStack spacing={4} key={action.actionId} align="stretch">
          <ui.Flex direction="row" alignItems="center">
            <ui.Heading size="md">Action {index + 1}</ui.Heading>
            <ui.Spacer />
            <ui.IconButton
              aria-label="delete action"
              colorScheme="black"
              icon={<ui.Icon as={BiTrashAlt} boxSize={5} color="danger.500" />}
              onClick={() =>
                onChange({
                  ...value,
                  actions: value.actions.filter((_, i) => i !== index),
                })
              }
              size="sm"
            />
          </ui.Flex>
          <ActionForm
            onChange={newAction =>
              onChange({
                ...value,
                actions: value.actions.map((a, i) =>
                  i === index ? newAction : a
                ),
              })
            }
            value={action}
          />
        </ui.VStack>
      ))}
      <ui.Button
        leftIcon={<ui.Icon as={BiPlus} boxSize={5} />}
        colorScheme="blackAlpha"
        disabled={value.actions.length >= 3}
        onClick={() =>
          onChange({
            ...value,
            actions: [
              ...value.actions,
              {
                configId: value.configId,
                actionType: DeviceActionType.Noop,
                actionId: '',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              },
            ],
          })
        }
      >
        Add Action
      </ui.Button>
    </ui.VStack>
  );
};
