import * as ui from '@chakra-ui/react';
import React from 'react';
import {
  DeviceAction,
  DeviceActionType,
  IotApiUtils,
  SendSmsAction,
} from '../../apis';

type Props = {
  onChange: (value: DeviceAction) => void;
  value: DeviceAction;
};

export const ActionForm: React.FC<Props> = ({ onChange, value }) => {
  return (
    <ui.VStack spacing={4} align="stretch">
      <ui.FormControl as="fieldset">
        <ui.FormLabel as="legend">Action Type</ui.FormLabel>
        <ui.RadioGroup
          colorScheme="primary"
          onChange={(actionType: DeviceActionType) => {
            const base = { ...IotApiUtils.extractBase(value), actionType };

            onChange(
              (() => {
                switch (actionType) {
                  case DeviceActionType.Noop:
                    return { ...base, actionType };
                  case DeviceActionType.SendSms: {
                    return {
                      ...base,
                      actionType,
                      name: 'Alex',
                      to: '8168052288',
                      body: 'Hello from the Mamalarm system! Your button has been pressed.',
                    } as SendSmsAction;
                  }
                }
              })()
            );
          }}
          value={value.actionType}
        >
          <ui.Stack direction="row" spacing={4}>
            {Object.values(DeviceActionType).map(type => (
              <ui.Radio key={type} value={type} size="lg">
                {IotApiUtils.actionLabel(type)}
              </ui.Radio>
            ))}
          </ui.Stack>
        </ui.RadioGroup>
      </ui.FormControl>
      {(() => {
        switch (value.actionType) {
          case DeviceActionType.Noop:
            return null;
          case DeviceActionType.SendSms: {
            value.to;
            return (
              <>
                <ui.FormControl>
                  <ui.FormLabel>Contact Name</ui.FormLabel>
                  <ui.Input
                    id="contact-name"
                    onChange={e => onChange({ ...value, name: e.target.value })}
                    value={value.name}
                  />
                </ui.FormControl>
                <ui.FormControl>
                  <ui.FormLabel>Phone Number</ui.FormLabel>
                  <ui.Input
                    id="contact-number"
                    minLength={12}
                    maxLength={12}
                    onChange={e => onChange({ ...value, to: e.target.value })}
                    type="tel"
                    value={value.to}
                  />
                  <ui.FormHelperText>
                    Include the +1 country code.
                  </ui.FormHelperText>
                </ui.FormControl>
                <ui.FormControl>
                  <ui.FormLabel>Message</ui.FormLabel>
                  <ui.Input
                    id="sms-body"
                    onChange={e => onChange({ ...value, body: e.target.value })}
                    value={value.body}
                  />
                </ui.FormControl>
              </>
            );
          }
        }
      })()}
    </ui.VStack>
  );
};
