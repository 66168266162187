import React, { useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import {
  CatalogSearchCountsContext,
  useCatalogSearchParams,
} from '../../catalog';
import { getUnaddedProductsCount } from '../../../apis';
import { useDelay } from '../../../hooks';

export const CatalogSearchCountsProvider: React.FC<{
  inventoryId: string;
  children: React.ReactNode;
}> = ({ children, inventoryId }) => {
  const { activeParams, pendingParams } = useCatalogSearchParams();
  const { query: getActiveCount, ...activeCountQuery } = useParameterizedQuery(
    getUnaddedProductsCount
  );
  const { query: getPendingCount, ...pendingCountQuery } =
    useParameterizedQuery(getUnaddedProductsCount);

  /** Update the active search count when the active params change */
  useEffect(() => {
    getActiveCount({ inventoryId, q: activeParams.q });
  }, [getActiveCount, activeParams.q, inventoryId]);

  /** Update the pending search count when the pending params change */
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const delayedGetPendingCount = useDelay(getPendingCount, 500);

  useEffect(() => {
    delayedGetPendingCount({ inventoryId, q: pendingParams.q });
  }, [delayedGetPendingCount, getPendingCount, inventoryId, pendingParams.q]);

  return (
    <CatalogSearchCountsContext.Provider
      value={{
        activeCount: +(activeCountQuery.payload ?? 0),
        activeCountIsLoading: activeCountQuery.loading,
        pendingCount: +(pendingCountQuery.payload ?? 0),
        pendingCountIsLoading: pendingCountQuery.loading,
      }}
    >
      {children}
    </CatalogSearchCountsContext.Provider>
  );
};
