import * as DREI from '@react-three/drei';
import React, { useState } from 'react';
import * as STD3 from 'three-stdlib';
import { TILES_PER_AXIS, TILE_SIZE } from '../useStore';

export const Controls: React.FC = () => {
  const controlsRef = React.useRef<STD3.MapControls>(null!);
  // useEffect(
  //   () =>
  //     useStore.subscribe(
  //       state => state.mapControlsEnabled,
  //       mapControlsEnabled => (controlsRef.current.enabled = mapControlsEnabled)
  //     ),
  //   []
  // );

  const [target] = useState<[number, number, number]>([
    (TILE_SIZE * TILES_PER_AXIS) / 4,
    (TILE_SIZE * TILES_PER_AXIS) / 4,
    0,
  ]);

  return (
    <DREI.MapControls
      ref={(ref: STD3.MapControls | null) => {
        if (!ref || controlsRef.current) return;
        controlsRef.current = ref;
        ref.setPolarAngle(Math.PI / 4);
        ref.listenToKeyEvents(window.document.body);
      }}
      enableDamping={false}
      keyPanSpeed={100}
      maxDistance={200}
      maxPolarAngle={Math.PI / 2.1}
      minDistance={TILE_SIZE}
      minPolarAngle={Math.PI / 24}
      target={target}
    />
  );
};
