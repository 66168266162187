import * as ui from '@chakra-ui/react';
import { Canvas } from '@react-three/fiber';
import React, { Suspense } from 'react';
import { Controls } from '../Controls';
import { ConveyorObject } from '../ConveyorObject';
import { ItemObject } from '../ItemObject';
import { PartObject } from '../PartObject';
import { TileObject } from '../TileObject';
import { Part, PartDirection } from '../types';
import { TILES_PER_AXIS, TILE_SIZE, useStore } from '../useStore';

export const ThreeCanvas: React.FC = () => {
  const { actions, selectedPart, partPendingPlacement, parts, tiles } =
    useStore();

  const onKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      console.log(e.code);
      switch (e.code) {
        case 'KeyA':
          return actions.addPart('conveyor');
        case 'Backspace':
          return actions.removeSelectedPart();
        case 'KeyR':
          return actions.rotatedSelectedPart();
        case 'KeyM': {
          if (partPendingPlacement) return actions.clearSelections();
          return actions.setSelectedAsPendingPlacement();
        }
        case 'Escape':
          return actions.clearSelections();
      }
    },
    [actions, partPendingPlacement]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return (
    <>
      <Canvas
        camera={{
          fov: 45,
          near: 1,
          position: [0, 0, 200],
          up: [0, 0, 1],
        }}
        onCreated={() => {
          actions.init();
        }}
        onPointerMissed={e => {
          console.log('pointer missed');
          actions.clearSelections();
        }}
      >
        <Controls />
        <ambientLight intensity={0.5} />
        {[...Array(TILES_PER_AXIS).keys()].map(x =>
          [...Array(TILES_PER_AXIS).keys()].map(y => (
            <React.Fragment key={`${x}${y}`}>
              {(x + 2) % 3 === 0 && (y + 2) % 3 === 0 && (
                <pointLight
                  intensity={0.2}
                  position={[x * TILE_SIZE, y * TILE_SIZE, 2 * TILE_SIZE]}
                />
              )}
            </React.Fragment>
          ))
        )}

        <Suspense fallback={null}>
          {tiles.map((tile, i) => (
            <TileObject key={i} {...tile} />
          ))}

          {[...parts, partPendingPlacement]
            .filter((piece): piece is Part => !!piece)
            .map(piece => (
              <PartObject key={piece.id} {...piece}>
                {props => <ConveyorObject {...props} />}
              </PartObject>
            ))}
          <ItemObject />
          {/* {items.map((item, i) => (
          ))} */}
        </Suspense>
        {/* <EffectComposer multisampling={2} autoClear={false}>
          <Outline
            blur
            selection={hoveredRef.current ? (hoveredRef as any) : undefined}
            visibleEdgeColor={0xffffff}
            edgeStrength={1}
            pulseSpeed={1}
            width={500}
          />
        </EffectComposer> */}
      </Canvas>
      <ui.VStack pos="fixed" top="4rem" left="2rem" align="stretch">
        <ui.Button onClick={() => actions.addPart('conveyor')}>
          Add Component
        </ui.Button>
        {!selectedPart && (
          <ui.Stat size="md">
            <ui.StatLabel>
              <ui.Code>A</ui.Code> to Add a Component
            </ui.StatLabel>
          </ui.Stat>
        )}
        {selectedPart && (
          <ui.Stat size="md">
            <ui.StatLabel>Selected</ui.StatLabel>
            <ui.StatNumber>{selectedPart.id}</ui.StatNumber>
            <ui.StatLabel>Direction</ui.StatLabel>
            <ui.StatNumber>{PartDirection[selectedPart.dir]}</ui.StatNumber>
            <ui.StatLabel>
              <ui.Code>M</ui.Code> to Move
            </ui.StatLabel>
            <ui.StatLabel>
              <ui.Code>R</ui.Code> to Rotate
            </ui.StatLabel>
            <ui.StatLabel>
              <ui.Code>Backspace</ui.Code> to Remove
            </ui.StatLabel>
            <ui.StatLabel>
              <ui.Code>Escape</ui.Code> to Cancel
            </ui.StatLabel>
          </ui.Stat>
        )}
        {partPendingPlacement && (
          <ui.Stat size="md">
            <ui.StatLabel>Pending Placement</ui.StatLabel>
            <ui.StatNumber>{partPendingPlacement.id}</ui.StatNumber>
          </ui.Stat>
        )}
      </ui.VStack>
    </>
  );
};
