export const Hostname = {
  Gcp: process.env.REACT_APP_GCP_SERVICE_HOSTNAME!,
  Inventorio: process.env.REACT_APP_INVENTORIO_SERVICE_HOSTNAME!,
  Iot: process.env.REACT_APP_IOT_SERVICE_HOSTNAME!,
  Users: process.env.REACT_APP_USERS_SERVICE_HOSTNAME!,
} as const;

Object.entries(Hostname).forEach(([k, v]) => {
  if (typeof v !== 'string') {
    throw new Error(
      `REACT_APP_${k.toUpperCase()}_SERVICE_HOSTNAME is not defined.`
    );
  }
});
