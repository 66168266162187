import * as ui from '@chakra-ui/react';
import React from 'react';
import { useBulkMutation, useQuery } from 'react-fetching-library';
import { BiPlus } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router';
import {
  createInventoryItem,
  getInventory as getInventoryQuery,
} from '../../../apis';
import { PageHeader } from '../../../layout';
import { QueryParamUtils } from '../../../lib';
import { CatalogSearchParamsProvider } from '../../catalog';
import { InventoryItemTable } from '../InventoryItemTable';
import { ProductSelectDrawer } from '../ProductSelectDrawer';
import { CatalogSearchCountsProvider } from './CatalogSearchCountsProvider';

type Props = {};

export const InventoryDetails: React.FC<Props> = ({}) => {
  const { location, ...history } = useHistory();
  const { id } = useParams<{ id: string }>();

  // Control the opening of the select drawer
  const queryParams = QueryParamUtils.queryParamsFromSearch(location.search);
  const { payload: inventory } = useQuery(getInventoryQuery(id));

  // const { mutate: deleteInventory } = useMutation(deleteInventoryMutation);
  const { mutate: createInventoryItems } = useBulkMutation(createInventoryItem);

  return (
    <>
      {'add' in queryParams && (
        <CatalogSearchParamsProvider
          resourceParamProps={{ value: { n: 20, offset: 0, q: {}, sort: {} } }}
        >
          <CatalogSearchCountsProvider inventoryId={id}>
            <ProductSelectDrawer
              isOpen
              inventoryId={id}
              onClose={() =>
                history.replace({
                  search: QueryParamUtils.searchFromQueryParams({}),
                })
              }
              onConfirm={async value => {
                console.log('confirm!', value);
                const results = await createInventoryItems(
                  Object.entries(value).map(([productId, quantity]) => ({
                    inventoryId: id,
                    body: {
                      quantity,
                      notes: '',
                      productId,
                      projects: [],
                    },
                  }))
                );
                console.log('RESULTS: ', results);
                history.replace({
                  search: QueryParamUtils.searchFromQueryParams({}),
                });
              }}
            />
          </CatalogSearchCountsProvider>
        </CatalogSearchParamsProvider>
      )}

      <PageHeader
        backButton={{
          to: '/inventorio/inventories',
          label: 'Back to Inventories',
        }}
        heading={`${inventory?.name ?? ''} Inventory`}
        mb={4}
      >
        <ui.Spacer />
        {/* <ConfirmDelete
          header="Delete inventory?"
          body="Deleting an inventory cannot be undone. All items contained in the inventory will also be deleted."
          onConfirm={async () => {
            const { error } = await deleteInventory(id);
            if (error) return;
            history.replace('/inventorio/inventories');
          }}
        >
          {({ onOpen }) => (
            <ui.Button colorScheme="danger" onClick={onOpen} variant="ghost">
              Delete
            </ui.Button>
          )}
        </ConfirmDelete> */}
        <ui.Button
          aria-label="create product"
          leftIcon={<ui.Icon as={BiPlus} boxSize={5} />}
          size="sm"
          onClick={() =>
            history.replace({
              search: QueryParamUtils.searchFromQueryParams({ add: true }),
            })
          }
          colorScheme="primary"
        >
          Add Items
        </ui.Button>
      </PageHeader>

      <ui.Box w="full" flexGrow={1} flexShrink={0} p={4}>
        {inventory?.name && (
          <ui.FormControl
            as={ui.Editable}
            // onChange={e => console.log('on change!', e)}
            onSubmit={e => console.log('on submit!', e)}
            value={inventory?.name}
          >
            <ui.FormLabel>Title</ui.FormLabel>
            <ui.EditablePreview />
            <ui.EditableInput type="text" as={ui.Input} />
            {/* <ui.FormHelperText color="danger.500">
              Enter a valid URL
            </ui.FormHelperText> */}
          </ui.FormControl>
        )}
      </ui.Box>
      <InventoryItemTable inventoryId={id} />
    </>
  );
};
