import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryParamUtils } from '../lib';

export type UseQueryParams = {
  push: <T>(params: T) => void;
  replace: <T>(params: T) => void;
  value: Record<string, string>;
};

export function useQueryParams(): UseQueryParams {
  const history = useHistory();

  const value = useMemo(
    () =>
      QueryParamUtils.queryParamsFromSearch(decodeURI(history.location.search)),
    [history.location.search]
  );

  const push = useCallback(
    <T>(params: T) => {
      history.push({
        ...history.location,
        search: QueryParamUtils.searchFromQueryParams({
          ...QueryParamUtils.queryParamsFromSearch(history.location.search),
          ...params,
        }),
      });
    },
    [history]
  );

  const replace = useCallback(
    <T>(params: T) => {
      history.replace({
        ...history.location,
        search: QueryParamUtils.searchFromQueryParams({
          ...QueryParamUtils.queryParamsFromSearch(history.location.search),
          ...params,
        }),
      });
    },
    [history]
  );

  return {
    push,
    replace,
    value,
  };
}
