import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { HTMLAttributes, KeyboardEvent, MouseEvent } from 'react';
import { Column } from './types';
import {
  dataElementFromContents,
  headerElementFromContents,
  normalizeColumn,
} from './utils';

/**
 * Type parameter `T` is the data type of the items to be displayed in the listing.
 */
type Props<T> = {
  /**
   * Column definitions.
   */
  columns?: Array<Partial<Column<T>>>;

  /**
   * Function used to optionally generate attributes (classes, styles, etc)
   * to any given data row <Tr> element, based on the row's item.
   */
  dataRowAttributes?: (
    item: T,
    index: number
  ) => HTMLAttributes<HTMLTableRowElement>;

  /**
   * If true, the entire <thead> will not be rendered.
   * @default false
   */
  hideHeaderRow?: boolean;

  /**
   * Function used to generate a unique value for each item in provide in `items`.
   * Defaults to a function that gets the `_id` property of `T`, if it has one.
   */
  itemKeyGen?: (item: T, index: number) => number | string;

  /**
   * An array of objects, each of which is represented by a row
   */
  items: T[];

  /**
   * Callback function fired on row click or touch.
   * Receives the event, the item clicked, and the index of the item clicked as parameters.
   * If no function is provided, the rows will not have their hover/focus styles applied.
   */
  onRowClick?: (
    event: MouseEvent | KeyboardEvent,
    item: T,
    index: number
  ) => void;
};

export function Listing<T>(props: Props<T>) {
  const {
    dataRowAttributes = () => ({}),
    hideHeaderRow = false,
    /** Warn the dev if the default `itemKeyGen` won't work with their data. */
    itemKeyGen = (item: T & { _id?: string }) => {
      if (!('_id' in item) || !item._id) {
        console.error(
          "Items provided do not have a valid `_id` property. You'll need to specify the `itemKeyGen` prop."
        );
      }
      return item._id;
    },
    items = [],
    onRowClick,
  } = props;

  const columns = (props.columns ?? []).map(normalizeColumn);

  const renderHeaders = () => (
    <Tr key="headers">
      {columns.map((column, i) => (
        <Th key={`${i}`} textAlign={column.align} px={[2, 4, 6]}>
          {headerElementFromContents(column.header(items))}
        </Th>
      ))}
    </Tr>
  );

  const renderRow = (item: T, i: number) => (
    <Tr
      key={itemKeyGen(item, i)}
      role="link"
      onKeyDown={
        onRowClick
          ? e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onRowClick(e, item, i);
              }
            }
          : () => null
      }
      onClick={onRowClick ? e => onRowClick(e, item, i) : () => null}
      tabIndex={onRowClick ? 0 : undefined}
      {...dataRowAttributes(item, i)}
    >
      {columns.map((column, k) => (
        <Td
          key={`${i}-${k}`}
          textAlign={column.align}
          isTruncated
          fontSize={['sm', null, 'md']}
          overflow="hidden"
          px={[2, 4, 6]}
        >
          {dataElementFromContents(column.data(item, i, items))}
        </Td>
      ))}
    </Tr>
  );

  return (
    <Table pos="relative">
      {!hideHeaderRow && (
        <Thead pos="sticky" top="0" bg="black">
          {renderHeaders()}
        </Thead>
      )}
      <Tbody>{props.items.map((item, i) => renderRow(item, i))}</Tbody>
    </Table>
  );
}
