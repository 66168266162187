import * as ui from '@chakra-ui/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TOPBAR_HEIGHT } from '../../layout/TopBar';
import { Catalog, CreateProduct, ProductDetails } from '../catalog';
import { CreateProductUsingBarcode } from '../catalog/CreateProductUsingBarcode';
import { Inventories, InventoryDetails } from '../inventories';
import { Projects } from '../projects';
import { InventorioNav } from './InventorioNav';

export const Inventorio: React.FC = () => {
  const height = `calc(100vh - ${TOPBAR_HEIGHT})`;

  return (
    <ui.Stack
      h={height}
      maxH={height}
      minH={height}
      w="100vw"
      minW="100vw"
      maxW="100vw"
      overflow="auto"
      spacing={0}
      pos="relative"
      direction={['column', null, 'row']}
    >
      <InventorioNav />
      <ui.Flex
        id="content-wrapper"
        direction="column"
        w="full"
        pos="relative"
        overflow="auto"
      >
        <Switch>
          <Route
            path="/inventorio/inventories/:id"
            component={InventoryDetails}
          />
          <Route path="/inventorio/inventories" component={Inventories} />
          <Route path="/inventorio/projects" component={Projects} />
          <Route
            path="/inventorio/catalog/new/barcode"
            component={CreateProductUsingBarcode}
          />
          <Route path="/inventorio/catalog/new" component={CreateProduct} />
          <Route path="/inventorio/catalog/:id" component={ProductDetails} />
          <Route path="/inventorio/catalog">
            <Catalog />
          </Route>
          <Route
            path="/inventorio"
            render={({ location }) => (
              <Redirect to={{ ...location, pathname: '/inventorio/catalog' }} />
            )}
          />
        </Switch>
      </ui.Flex>
    </ui.Stack>
  );
};
