import { useMemo, useRef } from 'react';

export function useDelay<T extends (...args: any) => any>(
  fn: T,
  ms: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  const timeout = useRef<number>();

  const call = useMemo(
    () =>
      (...args: Array<Parameters<T>>) => {
        clearTimeout(timeout.current);

        return new Promise<ReturnType<T>>(ok => {
          timeout.current = window.setTimeout(() => {
            ok(fn(...args));
          }, ms);
        });
      },
    [fn, ms]
  );

  return call;
}
