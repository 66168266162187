import { Flex, VStack } from '@chakra-ui/react';
import React from 'react';

export const AuthFormWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Flex direction="row" align="center" justify="center" h="100vh" w="100vw">
      <VStack
        as="form"
        align="stretch"
        direction="column"
        border={['0px', '2px']}
        borderColor="white"
        p={[8, null, 16]}
        spacing="20"
        w="lg"
      >
        {children}
      </VStack>
    </Flex>
  );
};
