/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useControllableState,
  useFocusOnPointerDown,
  useUpdateEffect,
} from '@chakra-ui/hooks';
import {
  createContext,
  EventKeyMap,
  mergeRefs,
  PropGetter,
} from '@chakra-ui/react-utils';
import {
  ariaAttr,
  callAllHandlers,
  contains,
  focus,
  getRelatedTarget,
  isEmpty,
  normalizeEventKey,
} from '@chakra-ui/utils';
import React from 'react';
import { QueryResponse } from 'react-fetching-library';

export interface UseEditableFieldProps<T> {
  /** The value of the Editable in both edit & preview mode */
  value?: T;
  /** If `true`, the Editable will be disabled. */
  isDisabled?: boolean;
  /** If `true`, the Editable will start with edit mode by default. */
  startWithEditView?: boolean;
  /** If `true`, it'll update the value onBlur and turn off the edit mode. */
  submitOnBlur?: boolean;
  /** Callback invoked when user changes input. */
  onChange?: (nextValue: T) => void;
  /**
   * Callback invoked when user cancels input with the `Esc` key.
   * It provides the last confirmed value as argument.
   */
  onCancel?: (previousValue: T) => void;
  /** Callback invoked when user confirms value with `enter` key or by blurring input. */
  onSubmit?: (nextValue: T) => Promise<QueryResponse<unknown>>;
  /** Callback invoked once the user enters edit mode. */
  onEdit?: () => void;
  /** If `true`, the input's text will be highlighted on focus. @default true */
  selectAllOnFocus?: boolean;
  /** The placeholder text when the value is empty. */
  placeholder?: string;
}

export function useEditableField<T>(props: UseEditableFieldProps<T> = {}) {
  const {
    onChange: onChangeProp,
    onCancel: onCancelProp,
    onSubmit: onSubmitProp,
    value: valueProp,
    isDisabled,
    startWithEditView,
    submitOnBlur = true,
    selectAllOnFocus = true,
    placeholder,
    onEdit: onEditProp,
    ...htmlProps
  } = props;

  const defaultIsEditing = Boolean(startWithEditView && !isDisabled);

  const [isEditing, setIsEditing] = React.useState(defaultIsEditing);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submissionResponse, setSubmissionResponse] = React.useState<
    QueryResponse<any> | undefined
  >(undefined);
  const [value, setValue] = useControllableState<T>({
    value: valueProp,
    onChange: onChangeProp,
  });

  /** Keep track of the previous value in case of cancel */
  const [prevValue, setPrevValue] = React.useState(value);

  /** Ref to help focus the input in edit mode */
  const inputRef = React.useRef<HTMLInputElement>(null);
  const previewRef = React.useRef<any>(null);
  const editButtonRef = React.useRef<HTMLButtonElement>(null);
  const cancelButtonRef = React.useRef<HTMLElement>(null);
  const submitButtonRef = React.useRef<HTMLElement>(null);

  useFocusOnPointerDown({
    ref: inputRef,
    enabled: isEditing,
    elements: [cancelButtonRef, submitButtonRef],
  });

  const isInteractive = !isEditing || !isDisabled;

  useUpdateEffect(() => {
    if (!isEditing) {
      focus(editButtonRef.current);
      return;
    }

    focus(inputRef.current, {
      selectTextIfInput: selectAllOnFocus,
    });

    onEditProp?.();
  }, [isEditing, onEditProp, selectAllOnFocus]);

  const onEdit = React.useCallback(() => {
    if (isInteractive) {
      setIsEditing(true);
    }
  }, [isInteractive]);

  const onCancel = React.useCallback(() => {
    setSubmissionResponse(undefined);
    setIsEditing(false);
    setValue(prevValue);
    onCancelProp?.(prevValue);
  }, [onCancelProp, setValue, prevValue]);

  const onSubmit = React.useCallback(async () => {
    setIsSubmitting(true);
    const response = await onSubmitProp?.(value);
    setSubmissionResponse(response);
    setIsSubmitting(false);

    if (response?.error) {
      focus(inputRef.current, {
        selectTextIfInput: selectAllOnFocus,
      });
    } else {
      setIsEditing(false);
      setPrevValue(value);
    }
  }, [onSubmitProp, value, selectAllOnFocus]);

  const onChange = React.useCallback(
    (event: T | React.ChangeEvent<HTMLInputElement>) => {
      if ('target' in event) {
        setValue(event.target.value as any as T);
      } else {
        setValue(event);
      }
    },
    [setValue]
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      const eventKey = normalizeEventKey(event);

      const keyMap: EventKeyMap = {
        Escape: onCancel,
        // Enter: event => {
        //   if (!event.shiftKey && !event.metaKey) {
        //     onSubmit();
        //   }
        // },
      };

      const action = keyMap[eventKey];

      if (action) {
        event.preventDefault();
        action(event);
      }
    },
    [onCancel]
  );

  const isValueEmpty = isEmpty(value);

  const onBlur = React.useCallback(
    (event: React.FocusEvent) => {
      const relatedTarget = getRelatedTarget(event);
      const targetIsCancel = contains(cancelButtonRef.current, relatedTarget);
      const targetIsSubmit = contains(submitButtonRef.current, relatedTarget);
      const isValidBlur = !targetIsCancel && !targetIsSubmit;

      if (isValidBlur) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const getPreviewProps: PropGetter = React.useCallback(
    (props = {}, ref = null) => {
      const tabIndex = isInteractive ? 0 : undefined;
      return {
        ...props,
        ref: mergeRefs(ref, previewRef),
        children:
          React.Children.count(props.children) > 0
            ? props.children
            : isValueEmpty
            ? placeholder
            : value,
        hidden: isEditing,
        'aria-disabled': ariaAttr(isDisabled),
        tabIndex,
        onFocus: callAllHandlers(props.onFocus, onEdit),
      };
    },
    [
      isDisabled,
      isEditing,
      isInteractive,
      isValueEmpty,
      onEdit,
      placeholder,
      value,
    ]
  );

  const getInputProps: PropGetter = React.useCallback(
    (props = {}, ref = null) => ({
      ...props,
      hidden: !isEditing,
      placeholder,
      ref: mergeRefs(ref, inputRef),
      disabled: isDisabled || isSubmitting,
      'aria-disabled': ariaAttr(isDisabled || isSubmitting),
      value,
      onBlur: callAllHandlers(props.onBlur, onBlur),
      onChange: callAllHandlers(props.onChange, onChange as any),
      onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
    }),
    [
      isDisabled,
      isEditing,
      isSubmitting,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      value,
    ]
  );

  const getEditButtonProps: PropGetter = React.useCallback(
    (props = {}, ref = null) => ({
      'aria-label': 'Edit',
      ...props,
      type: 'button',
      onClick: callAllHandlers(props.onClick, onEdit),
      ref: mergeRefs(ref, editButtonRef),
    }),
    [onEdit]
  );

  const getSubmitButtonProps: PropGetter = React.useCallback(
    (props = {}, ref = null) => ({
      ...props,
      'aria-label': 'Submit',
      ref: mergeRefs(submitButtonRef, ref),
      type: 'button',
      onClick: callAllHandlers(props.onClick, onSubmit),
    }),
    [onSubmit]
  );

  const getCancelButtonProps: PropGetter = React.useCallback(
    (props = {}, ref = null) => ({
      'aria-label': 'Cancel',
      id: 'cancel',
      ...props,
      ref: mergeRefs(cancelButtonRef, ref),
      type: 'button',
      onClick: callAllHandlers(props.onClick, onCancel),
    }),
    [onCancel]
  );

  const renderProps = {
    getInputProps,
    getSubmitButtonProps,
    isEditing,
    isSubmitting,
    onCancel,
    onEdit,
    onSubmit,
    submissionResponse,
  };

  return {
    getCancelButtonProps,
    getEditButtonProps,
    getPreviewProps,
    htmlProps,
    isDisabled,
    isValueEmpty,
    renderProps,
    value,
  };
}

export type UseEditableFieldReturn = ReturnType<typeof useEditableField>;

export type EditableFieldContext = Omit<UseEditableFieldReturn, 'htmlProps'>;

export const [EditableFieldProvider, useEditableFieldContext] =
  createContext<EditableFieldContext>({
    name: 'EditableFieldContext',
    errorMessage:
      'useEditableFieldContext: context is undefined. Seems you forgot to wrap the editable components in `<EditableField />`',
  });
