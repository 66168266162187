import { Box, useTheme } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';
import { keyframes } from '@emotion/react';
import React, { forwardRef } from 'react';

type Props = {
  color?: string;
  size?: string | number;
};

const spin = keyframes`
0% {
  stroke-dasharray: 1 98;
  stroke-dashoffset: -105;
}
50% {
  stroke-dasharray: 80 10;
  stroke-dashoffset: -160;
}
100% {
  stroke-dasharray: 1 98;
  stroke-dashoffset: -300;
}
`;

export const Loader = forwardRef<SVGElement, Props>(
  ({ color = 'primary.500', size = 6 }, ref) => {
    const theme = useTheme();
    const stroke = getColor(theme, color, 'primary.500');

    return (
      <Box as="svg" viewBox="0 0 100 100" boxSize={size}>
        <Box
          as="circle"
          id="spinner"
          sx={{
            animation: `${spin} 1s linear infinite`,
            fill: 'transparent',
            stroke,
            strokeWidth: 10,
            transformOrigin: 'center',
          }}
          cx="50"
          cy="50"
          r="45"
        />
      </Box>
    );
  }
);
