import { Action } from 'react-fetching-library';
import { Hostname } from '../hostnames';
import { User } from './users';

export interface DecodedToken {
  email: string;
  sub: string;
  iat: number;
  exp: number;
}

export interface UserSession {
  accessToken: string;
}

export enum AuthActionId {
  Login = 'login',
  Register = 'register',
  Refresh = 'refresh',
}

export const login = (body: {
  email: string;
  password: string;
}): Action<UserSession & User> => ({
  actionId: AuthActionId.Login,
  body,
  endpoint: '/login',
  hostname: Hostname.Users,
  method: 'POST',
  skipAuth: true,
});

export const refreshToken: Action<UserSession> = {
  actionId: AuthActionId.Refresh,
  endpoint: '/refresh',
  hostname: Hostname.Users,
  method: 'GET',
  onSuccess: () => ({ title: 'Token refreshed' }),
};

export const register = (body: {
  email: string;
  password: string;
  username: string;
}): Action<UserSession> => ({
  actionId: AuthActionId.Register,
  body,
  endpoint: '/register',
  hostname: Hostname.Users,
  method: 'POST',
  skipAuth: true,
});
