import {
  Code,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import type { protos } from '@google-cloud/iot';
import React from 'react';
import { useQuery } from 'react-fetching-library';
import { BiCodeCurly } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';
import { getDeviceRegistry, getDevices } from '../../apis';
import { PageContent, PageHeader } from '../../layout';
import { Listing, Pre } from '../../shared';

export const DeviceRegistryDetails: React.FC = () => {
  const { registryId }: { registryId: string } = useParams();
  const { payload: registry } = useQuery(getDeviceRegistry(registryId));
  const { payload: devices } = useQuery(getDevices(registryId));
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <PageHeader heading="Device Registry">
        <Spacer />
        <IconButton
          aria-label="show config"
          icon={<Icon as={BiCodeCurly} boxSize={7} />}
          isRound
          size="md"
          colorScheme="gray"
          onClick={() => onOpen()}
          variant="ghost"
        />
      </PageHeader>
      <PageContent>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Registry Config</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Pre contents={registry} />
            </ModalBody>
          </ModalContent>
        </Modal>

        <Listing<protos.google.cloud.iot.v1.IDevice>
          items={devices ?? []}
          onRowClick={(event, item) =>
            history.push(`/gcp-iot/${registryId}/${item.id}`)
          }
          columns={[
            {
              header: () => 'ID',
              data: item => <Code>{item.id}</Code>,
            },
            {
              header: () => 'Num. ID',
              data: item => <Code>{item.numId}</Code>,
            },
            {
              header: () => 'Communication',
              data: item => (item.blocked ?? false ? 'BLOCKED' : 'ENABLED'),
            },
          ]}
        />
      </PageContent>
    </>
  );
};
