import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  useMutation,
  useParameterizedQuery,
  useQuery,
} from 'react-fetching-library';
import { BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import {
  deleteUser,
  getUser as getUserQuery,
  refreshToken as refreshTokenQuery,
  updateUser as updatedUserMutation,
  useAuthedFetch,
  UserDto,
  userToDto,
} from '../apis';
import { PageContent, PageHeader } from '../layout';
import { MongoUtils, StringUtils } from '../lib';
import { ConfirmDelete } from '../shared';

export const Profile: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { clearSession, tokenExpiration, userId } = useAuthedFetch();

  const { payload: user, query: getUser } = useParameterizedQuery(getUserQuery);

  const { error: updateUserError, mutate: updateUserMutation } =
    useMutation(updatedUserMutation);

  const { query: unregister } = useQuery(deleteUser, false);
  const { query: refreshToken, loading: refreshTokenLoading } = useQuery(
    refreshTokenQuery,
    false
  );
  const [userFormValue, setUserFormValue] = useState<UserDto | null>(null);

  const isMe = id === 'me' || id === userId;
  const isDeleted = !!user?.deleted;

  // Initial load
  useEffect(() => {
    getUser(id);
  }, [getUser, id]);

  // Reset form values on user load
  useEffect(() => {
    if (user) {
      setUserFormValue(userToDto(user));
    }
  }, [user]);

  // Reset form state on update error
  useEffect(() => {
    if (updateUserError && user) {
      setUserFormValue(userToDto(user));
    }
  }, [updateUserError, user]);

  const updateUser: typeof updateUserMutation = async dto => {
    const result = await updateUserMutation(dto);
    if (!result.error) getUser(id);
    return result;
  };

  if (!user)
    return (
      <Flex justify="center">
        <VStack spacing={8} pt={16}>
          <CircularProgress
            color="primary.500"
            isIndeterminate
            size={40}
            thickness={2}
            trackColor="whiteAlpha.400"
          >
            <CircularProgressLabel>
              <Heading size="md">Loading user</Heading>
            </CircularProgressLabel>
          </CircularProgress>
        </VStack>
      </Flex>
    );

  return (
    <>
      {isMe && (
        <PageHeader
          backButton={{ to: '/users', label: 'See All Users' }}
          heading="User Profile"
        />
      )}

      <PageContent align="flex-start">
        <Stack
          direction={['column', null, 'row']}
          align="flex-start"
          spacing={12}
          w="full"
        >
          <VStack
            spacing={8}
            maxW={['100%', null, '25%']}
            w={['100%', null, '25%']}
            minW={['100%', null, '25%']}
            alignSelf={['center', null, 'flex-start']}
            align="flex-start"
          >
            <Image
              pos="relative"
              objectFit="cover"
              rounded="full"
              alignSelf={['center', null, 'flex-start']}
              boxSize={['150px', '150px', '200px']}
              minW={['150px', '150px', '200px']}
              src={user.imageUrl}
            />
            <Stat size="lg">
              <StatNumber>
                {!isMe && user.username}
                {isMe && (
                  <Editable
                    colorScheme="gray"
                    onChange={username =>
                      setUserFormValue(userFormValue => ({
                        ...userFormValue!,
                        username,
                      }))
                    }
                    onCancel={() =>
                      setUserFormValue(userFormValue => ({
                        ...userFormValue!,
                        username: user.username,
                      }))
                    }
                    onSubmit={username => {
                      if (username !== user.username)
                        updateUser({ ...userFormValue! });
                    }}
                    value={userFormValue?.username ?? ''}
                  >
                    <EditableInput />
                    <Tooltip hasArrow label="Click to edit">
                      <EditablePreview cursor="pointer" />
                    </Tooltip>
                  </Editable>
                )}
              </StatNumber>
              <StatHelpText mb="0">
                Joined{' '}
                {formatDistanceToNow(
                  MongoUtils.objectIdToDate(user._id ?? (undefined as any)),
                  { addSuffix: true }
                )}
              </StatHelpText>
              {user.updated && !isDeleted && (
                <StatHelpText mt="0">
                  Updated{' '}
                  {formatDistanceToNow(new Date(user.updated), {
                    addSuffix: true,
                  })}
                </StatHelpText>
              )}
              {isDeleted && (
                <StatHelpText mt="0">
                  Deleted{' '}
                  {formatDistanceToNow(new Date(user.deleted!), {
                    addSuffix: true,
                  })}
                </StatHelpText>
              )}
            </Stat>

            {/* Email */}
            <Stat>
              <StatLabel>Email</StatLabel>
              <StatNumber>{user.email}</StatNumber>
            </Stat>

            {isMe && (
              <Stat>
                <StatLabel>Image URL</StatLabel>
                <StatNumber>
                  <Editable
                    colorScheme="gray"
                    onChange={imageUrl =>
                      setUserFormValue(userFormValue => ({
                        ...userFormValue!,
                        imageUrl,
                      }))
                    }
                    onCancel={() =>
                      setUserFormValue(userFormValue => ({
                        ...userFormValue!,
                        imageUrl: user.imageUrl,
                      }))
                    }
                    onSubmit={imageUrl => {
                      if (imageUrl !== user.imageUrl)
                        updateUser({ ...userFormValue! });
                    }}
                    value={userFormValue?.imageUrl ?? ''}
                  >
                    <EditableInput />
                    <Tooltip hasArrow label="Click to edit">
                      <EditablePreview
                        maxW="full"
                        isTruncated
                        cursor="pointer"
                      />
                    </Tooltip>
                  </Editable>
                </StatNumber>
              </Stat>
            )}
            {isMe && (
              <HStack spacing={[4, 6, 8]}>
                <Stat>
                  <StatLabel>Token Expires</StatLabel>
                  <StatNumber>
                    {StringUtils.capitalize(
                      formatDistanceToNow(
                        new Date(tokenExpiration?.toString() ?? ''),
                        {
                          addSuffix: true,
                          includeSeconds: true,
                        }
                      )
                    )}
                  </StatNumber>
                </Stat>
                <Tooltip label="Refresh token">
                  <IconButton
                    aria-label="refresh token"
                    colorScheme="gray"
                    isRound
                    icon={<BiRefresh size={28} />}
                    onClick={refreshToken}
                    isLoading={refreshTokenLoading}
                    size="md"
                    variant="ghost"
                  />
                </Tooltip>
              </HStack>
            )}

            {isMe && (
              <ConfirmDelete
                header="Delete account?"
                body="Deleting your account cannot be undone. All of your data will be erased."
                onConfirm={async () => {
                  await unregister();
                  clearSession();
                }}
              >
                {({ onOpen }) => (
                  <Button
                    alignSelf="center"
                    colorScheme="danger"
                    onClick={onOpen}
                    variant="ghost"
                  >
                    Delete Account
                  </Button>
                )}
              </ConfirmDelete>
            )}
          </VStack>

          <VStack align="flex-start" spacing={8} w="full">
            <Heading size="md">User activity will go here</Heading>
            {[...Array(10).keys()].map(k => (
              <Box key={k} h="120px" w="full" bg="whiteAlpha.100" />
            ))}
          </VStack>
        </Stack>
      </PageContent>
    </>
  );
};
