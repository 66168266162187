import * as THREE from 'three';
import create, { GetState, SetState } from 'zustand';
import {
  StoreApiWithSubscribeWithSelector,
  subscribeWithSelector,
} from 'zustand/middleware';
import { Item, Location, Part, PartDirection, PartType } from './types';
import { nextDirection } from './utils';

export type State = {
  clock: THREE.Clock;
  items: Item[];
  partPendingPlacement?: Part & { originId?: number };
  parts: Part[];
  selectedPart?: Part;
  startTime: number;
  tiles: Location[];
  actions: {
    addPart: (type: PartType) => void;
    clearSelections: () => void;
    init: () => void;
    movePendingPart: (x: number, y: number) => void;
    placePartAt: (x: number, y: number) => void;
    removeSelectedPart: () => void;
    rotatedSelectedPart: () => void;
    selectPartById: (id?: number) => void;
    setSelectedAsPendingPlacement: () => void;
    updateItems: (items: Item[]) => void;
  };
};

const id = (() => {
  let _id = 1;
  return () => _id++;
})();

export const TILE_HEIGHT = 1;
export const TILE_SIZE = 12;
export const TILES_PER_AXIS = 12;

export const useStore = create<
  State,
  SetState<State>,
  GetState<State>,
  StoreApiWithSubscribeWithSelector<State>
>(
  subscribeWithSelector(
    (set, get): State => ({
      clock: new THREE.Clock(false),
      items: [
        {
          x: 3,
          y: 5,
          id: id(),
          previousLocation: { x: 4, y: 4 },
          position: new THREE.Vector3(TILE_SIZE * 4, TILE_SIZE * 4, 1),
        },
      ],
      parts: [
        { dir: PartDirection.Right, x: 3, y: 5, id: id(), type: 'conveyor' },
        { dir: PartDirection.Down, x: 4, y: 5, id: id(), type: 'conveyor' },
        { dir: PartDirection.Left, x: 4, y: 4, id: id(), type: 'conveyor' },
        { dir: PartDirection.Up, x: 3, y: 4, id: id(), type: 'conveyor' },
        { dir: PartDirection.Up, x: 3, y: 3, id: id(), type: 'conveyor' },
        { dir: PartDirection.Up, x: 8, y: 0, id: id(), type: 'conveyor' },
      ],
      startTime: performance.now(),
      tiles: [...Array(TILES_PER_AXIS).keys()].flatMap(x =>
        [...Array(TILES_PER_AXIS).keys()].map(y => ({ x, y }))
      ),
      actions: {
        addPart: type => {
          const newPiece = {
            id: id(),
            x: 0,
            y: 0,
            dir: PartDirection.Up,
            type,
          };
          set({
            selectedPart: newPiece,
            partPendingPlacement: newPiece,
          });
        },
        clearSelections: () =>
          set({ selectedPart: undefined, partPendingPlacement: undefined }),
        init: () => {
          const { clock } = get();
          clock.start();
          // clock.

          // addEffect(() => {});
        },
        movePendingPart: (x, y) => {
          const pending = get().partPendingPlacement;
          if (!!pending) set({ partPendingPlacement: { ...pending, x, y } });
        },
        placePartAt: (x, y) => {
          const partPendingPlacement = get().partPendingPlacement;
          if (!!partPendingPlacement) {
            // If the pending part has an origin ID, we replace the existing part
            // Otherwise, append it to the parts list as a new part
            const isNew = !partPendingPlacement.originId;
            const parts = isNew
              ? [...get().parts, partPendingPlacement]
              : get().parts.map(part =>
                  part.id !== partPendingPlacement.originId
                    ? part
                    : { ...part, x, y }
                );

            // Auto-select the placed part
            const selectedPart = parts.find(part =>
              isNew
                ? part.id === partPendingPlacement.id
                : part.id === partPendingPlacement.originId
            );

            set({
              selectedPart,
              partPendingPlacement: undefined,
              parts: parts,
            });
          }
        },
        removeSelectedPart: () => {
          const { selectedPart, parts } = get();
          console.log(`removing this part: `, selectedPart?.id);
          if (!!selectedPart)
            set({
              partPendingPlacement: undefined,
              selectedPart: undefined,
              parts: parts.filter(p => p.id !== selectedPart.id),
            });
        },
        rotatedSelectedPart: () => {
          const selectedPart = get().selectedPart;
          if (!!selectedPart) {
            set({
              parts: get().parts.map(part =>
                part.id !== selectedPart.id
                  ? part
                  : { ...part, dir: nextDirection(part.dir) }
              ),
            });
          }
        },
        selectPartById: id =>
          set({ selectedPart: get().parts.find(p => p.id === id) }),
        setSelectedAsPendingPlacement: () => {
          const { selectedPart } = get();
          if (selectedPart) {
            set({
              partPendingPlacement: {
                ...selectedPart,
                id: id(),
                originId: selectedPart.id,
              },
            });
          }
        },
        updateItems: items => {
          const itemsObj = Object.fromEntries(
            items.map(item => [item.id, item])
          );

          set({
            items: get().items.map(item =>
              item.id in itemsObj ? itemsObj[item.id] : item
            ),
          });
        },
      },
    })
  )
);
