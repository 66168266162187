import { useEffect, useRef, useState } from 'react';

export const useEventSource = (
  url: string,
  messageCb: (msg: MessageEvent) => void
) => {
  const eventSourceRef = useRef<EventSource>();
  const [isOpen, setIsOpen] = useState(false);

  // Create event source on mount only
  useEffect(() => {
    try {
      eventSourceRef.current = new EventSource(url);
      eventSourceRef.current.onopen = e => {
        console.log('open event!', e, 'phase: ', e.eventPhase);
        setIsOpen(true);
      };
      eventSourceRef.current.onerror = e => {
        console.log('error event!', e, 'phase: ', e.eventPhase);

        setIsOpen(false);
      };
    } catch (err) {
      console.error(`Failed to open EventSource to URL ${url}`);
    }
  }, []); // eslint-disable-line

  // Close on unmount
  useEffect(() => () => eventSourceRef.current?.close(), []);

  // Set event handler as it changes
  useEffect(() => {
    if (eventSourceRef.current) eventSourceRef.current.onmessage = messageCb;
  }, [messageCb, isOpen]);

  return {
    isOpen,
  };
};
