import { cssVar, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { Theme } from '@chakra-ui/theme';
import { Styles } from '@chakra-ui/theme-tools';
import {
  EditableFieldTheme,
  SuperTableTheme,
  VideoCameraTheme,
} from '../shared';
import { colors } from './colors';
import { components } from './components';
import { fonts, fontSizes, letterSpacings } from './typography';

const config: ThemeConfig = {
  cssVarPrefix: 'ape',
  initialColorMode: 'dark',
};

const shadows: Partial<Theme['shadows']> = {
  outline: '0 0 0px 2px rgba(255, 255, 255, 0.2)',
};

// Global stuff
const styles: Styles = {
  global: props => {
    console.log('global props: ', props.theme);
    return {
      [cssVar('popper-arrow-bg').variable]: 'white',
      html: {
        bg: 'black',
        color: 'white',
      },
      body: {
        bg: 'black',
        color: 'white',
        maxH: '100vh',
        maxW: '100vh',
        overflow: 'hidden',
      },
      '.chakra-form__required-indicator': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        color: `${props.theme.colors.accent[500]} !important`,
      },
    };
  },
};

export const theme = extendTheme({
  components: {
    ...components,
    SuperTable: SuperTableTheme,
    VideoCamera: VideoCameraTheme,
    EditableField: EditableFieldTheme,
  },
  config,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  shadows,
  styles,
});
