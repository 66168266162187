import { Code } from '@chakra-ui/react';
import type { protos } from '@google-cloud/iot';
import React from 'react';
import { useQuery } from 'react-fetching-library';
import { useHistory } from 'react-router-dom';
import { getDeviceRegistries } from '../../apis';
import { PageContent, PageHeader } from '../../layout';
import { Listing } from '../../shared';

export const DeviceRegistryList: React.FC = () => {
  const { payload: registries } = useQuery(getDeviceRegistries);
  const history = useHistory();

  return (
    <>
      <PageHeader heading="Device Registries" />
      <PageContent>
        <Listing<protos.google.cloud.iot.v1.IDeviceRegistry>
          items={registries ?? []}
          onRowClick={(event, item) => {
            history.push(`/gcp-iot/${item.id}`);
          }}
          columns={[
            {
              header: () => 'ID',
              data: item => <Code>{item.id}</Code>,
            },
            {
              header: () => 'Protocols',
              data: item => (
                <Code>
                  {[
                    item.mqttConfig?.mqttEnabledState === 'MQTT_ENABLED'
                      ? 'MQTT'
                      : null,
                    item.httpConfig?.httpEnabledState === 'HTTP_ENABLED'
                      ? 'HTTP'
                      : null,
                  ]
                    .filter(p => typeof p === 'string')
                    .join(', ')}
                </Code>
              ),
            },
            {
              header: () => 'Name',
              data: item => <Code>{item.name}</Code>,
              width: 2,
            },
          ]}
        />
      </PageContent>
    </>
  );
};
