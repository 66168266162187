import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Icon,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { BiPlus } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { getProducts as getProductsQuery } from '../../../apis';
import { useCatalogSearchParams } from '../useCatalogSearchParams';
import { ProductItem } from './ProductItem';

export const ProductList: React.FC = () => {
  const { activeParams } = useCatalogSearchParams();
  const { query, ...getProducts } = useParameterizedQuery(getProductsQuery);

  // Get products on mount and when the active params change
  React.useEffect(() => {
    query(activeParams);
  }, [activeParams, query]);

  const products = getProducts.payload ?? [];

  return (
    <>
      {!getProducts.loading && !getProducts.error && products.length < 1 && (
        <Center>
          <VStack spacing={8}>
            <Heading size="lg">No Results</Heading>
            <Button
              as={NavLink}
              leftIcon={<Icon as={BiPlus} boxSize={7} />}
              size="md"
              to={({ pathname }: { pathname: string }) => `${pathname}/new`}
              colorScheme="primary"
            >
              Add a Product
            </Button>
          </VStack>
        </Center>
      )}

      {products.length > 0 &&
        [...(products ?? [])].map(item => (
          <ProductItem
            key={item._id}
            product={item}
            onDeleted={() => alert('NO LONGER IMPLEMENTED')}
          />
        ))}

      {getProducts.loading && (
        <Flex
          bg="blackAlpha.800"
          backdropFilter="blur(2px)"
          top={-16}
          bottom={-16}
          left={-8}
          right={-8}
          pos="absolute"
          display="flex"
          justify="center"
        >
          <VStack spacing={8} pt={16}>
            <CircularProgress
              color="primary.500"
              isIndeterminate
              size={40}
              thickness={2}
              trackColor="whiteAlpha.400"
            >
              <CircularProgressLabel>
                <Heading size="md">Searching</Heading>
              </CircularProgressLabel>
            </CircularProgress>
          </VStack>
        </Flex>
      )}
      {/* Error */}
      {!getProducts.loading && getProducts.error && (
        <Center h="full" px={12}>
          <Alert status="error">
            <AlertIcon />
            <Box flex={1}>
              <AlertTitle>
                {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  getProducts.errorObject.error ?? `Something ain't right`
                }
              </AlertTitle>
              <AlertDescription>
                {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  getProducts.errorObject?.message
                }
              </AlertDescription>
            </Box>
          </Alert>
        </Center>
      )}
    </>
  );
};
