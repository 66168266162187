import { Action } from 'react-fetching-library';
import { Hostname } from '../hostnames';
import type { protos } from '@google-cloud/iot';

export const getDeviceRegistries: Action<
  protos.google.cloud.iot.v1.IDeviceRegistry[]
> = {
  endpoint: '/iot/device-registries',
  hostname: Hostname.Gcp,
  method: 'GET',
};

export const getDeviceRegistry = (
  registryId: string
): Action<protos.google.cloud.iot.v1.IDeviceRegistry> => ({
  endpoint: `/iot/device-registries/${registryId}`,
  hostname: Hostname.Gcp,
  method: 'GET',
});

export const getDevices = (
  registryId: string
): Action<protos.google.cloud.iot.v1.IDevice[]> => ({
  endpoint: `/iot/device-registries/${registryId}/devices`,
  hostname: Hostname.Gcp,
  method: 'GET',
});

export const getDevice = (
  registryId: string,
  deviceId: string
): Action<protos.google.cloud.iot.v1.IDevice> => ({
  endpoint: `/iot/device-registries/${registryId}/devices/${deviceId}`,
  hostname: Hostname.Gcp,
  method: 'GET',
});

export const getDeviceConfigs = (
  registryId: string,
  deviceId: string
): Action<protos.google.cloud.iot.v1.IDeviceConfig[]> => ({
  endpoint: `/iot/device-registries/${registryId}/devices/${deviceId}/configs`,
  hostname: Hostname.Gcp,
  method: 'GET',
});

export const getDeviceStates = (
  registryId: string,
  deviceId: string
): Action<protos.google.cloud.iot.v1.IDeviceState[]> => ({
  endpoint: `/iot/device-registries/${registryId}/devices/${deviceId}/states`,
  hostname: Hostname.Gcp,
  method: 'GET',
});
