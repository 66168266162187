import * as ui from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { BiLinkExternal } from 'react-icons/bi';
import {
  deleteInventoryItem,
  getInventoryItemCount,
  getInventoryItems,
  InventoryItem,
  ResourceParamUtils,
  updateInventoryItem,
  useResourceParams,
} from '../../../apis';
import { useBreakpointQuery, useQueryParams } from '../../../hooks';
import { MongoUtils, secondsFromDate } from '../../../lib';
import {
  PageSelector,
  SuperTable,
  SuperTbody,
  SuperTd,
  SuperTh,
  SuperThead,
  SuperTr,
} from '../../../shared';
import { SuperTableContainer } from '../../../shared/SuperTable/SuperTableContainer';
import { EditCellValuePopover } from './EditCellValuePopover';
import { InventoryItemMenu } from './InventoryItemMenu';

type Props = {
  inventoryId: string;
};

export const InventoryItemTable: React.FC<Props> = ({ inventoryId }) => {
  const isGteMd = useBreakpointQuery('md');
  const { replace, value } = useQueryParams();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const deleteItem = useMutation(deleteInventoryItem);
  const updateItem = useMutation(updateInventoryItem);
  const {
    payload: items = [],
    loading: isLoading,
    query: getItems,
  } = useParameterizedQuery(getInventoryItems);
  const { payload: itemCount = 0, query: getItemCount } = useParameterizedQuery(
    getInventoryItemCount
  );
  const { params, setOrToggleSort, setOffset } =
    useResourceParams<InventoryItem>({
      onChange: newParams => {
        getItems({ id: inventoryId, params: newParams });
        getItemCount(inventoryId);
        replace(newParams);
      },
      value: ResourceParamUtils.fromDict({ ...value, n: '25' }),
    });

  // Get items when something is deleted or updated, but NOT when the params change
  React.useEffect(
    () => {
      getItems({ id: inventoryId, params });
      getItemCount(inventoryId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getItems,
      deleteItem.payload,
      inventoryId,
      updateItem.payload,
      getItemCount,
    ]
  );

  const [columnWidths, setColumnWidths] = React.useState({
    productImageUrl: 48,
    quantity: 108,
    notes: 200,
    created: 200,
    updated: 200,
    productTitle: 200,
    productVendor: 200,
    productTags: 200,
    menu: 48,
  });

  // Scroll the container back to top on page change
  React.useEffect(() => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [params.offset, params.sort]);

  return (
    <SuperTableContainer ref={containerRef}>
      <SuperTable<InventoryItem>
        items={items}
        itemKey={item => item._id}
        columns={[
          {
            id: 'productImageUrl',
            width: columnWidths['productImageUrl'],
          },
          {
            id: 'quantity',
            width: columnWidths['quantity'],
            isSorting: params.sort.quantity,
            onResize: w => setColumnWidths(ws => ({ ...ws, quantity: w })),
            onSort: () => setOrToggleSort('quantity'),
          },
          {
            id: 'notes',
            width: columnWidths['notes'],
            isSorting: params.sort.notes,
            onResize: w => setColumnWidths(ws => ({ ...ws, notes: w })),
            onSort: () => setOrToggleSort('notes'),
          },
          {
            id: 'created',
            width: columnWidths['created'],
            isSorting: params.sort['_id'],
            onResize: w => setColumnWidths(ws => ({ ...ws, created: w })),
            onSort: () => setOrToggleSort('_id'),
          },
          {
            id: 'updated',
            width: columnWidths['updated'],
            onResize: w => setColumnWidths(ws => ({ ...ws, updated: w })),
            isSorting: params.sort['updated'],
            onSort: () => setOrToggleSort('updated'),
          },
          {
            id: 'productTitle',
            width: columnWidths['productTitle'],
            onResize: w => setColumnWidths(ws => ({ ...ws, productTitle: w })),
            isSorting: params.sort['product.title'],
            onSort: () => setOrToggleSort('product.title'),
          },
          {
            id: 'productVendor',
            width: columnWidths['productVendor'],
            onResize: w => setColumnWidths(ws => ({ ...ws, productVendor: w })),
            isSorting: params.sort['product.vendor'],
            onSort: () => setOrToggleSort('product.vendor'),
          },
          {
            id: 'productTags',
            onResize: w => setColumnWidths(ws => ({ ...ws, productTags: w })),
            width: columnWidths['productTags'],
          },
          {
            id: 'menu',
            width: columnWidths['menu'],
          },
        ]}
      >
        <SuperThead>
          <SuperTr pin="top" bg="black">
            <SuperTh></SuperTh>
            <SuperTh textAlign="right">Qty</SuperTh>
            <SuperTh>Notes</SuperTh>
            <SuperTh>Created</SuperTh>
            <SuperTh>Updated</SuperTh>
            <SuperTh>Product Name</SuperTh>
            <SuperTh>Product Vendor</SuperTh>
            <SuperTh>Product Tags</SuperTh>
            <SuperTh></SuperTh>
          </SuperTr>
        </SuperThead>

        <SuperTbody sx={{ filter: isLoading ? 'blur(2px)' : 'none' }}>
          {items.map(item => (
            <SuperTr key={item._id}>
              <SuperTd
                pin="left"
                backgroundImage={`url("${item.product.productImageUrl}")`}
                backgroundSize="cover"
                backgroundPosition="center"
              />
              <SuperTd isEditable fontWeight="bold" textAlign="right">
                {item.quantity}
                <EditCellValuePopover
                  label="Quantity"
                  type="number"
                  onSave={quantity =>
                    updateItem.mutate({
                      inventoryId,
                      itemId: item._id,
                      body: { quantity },
                    })
                  }
                  min="0"
                  transformOnChange={value =>
                    Number.isSafeInteger(+`${value}`) ? +`${value}` : 0
                  }
                  value={item.quantity}
                />
              </SuperTd>

              <SuperTd isEditable>
                {item.notes}
                <EditCellValuePopover
                  label="Notes"
                  type="text"
                  onSave={notes =>
                    updateItem.mutate({
                      inventoryId,
                      itemId: item._id,
                      body: { notes },
                    })
                  }
                  value={item.notes}
                />
              </SuperTd>

              <SuperTd>
                {formatDistanceToNow(
                  secondsFromDate(MongoUtils.objectIdToDate(item._id)),
                  {
                    addSuffix: true,
                    includeSeconds: true,
                  }
                )}
              </SuperTd>

              <SuperTd>
                {formatDistanceToNow(secondsFromDate(new Date(item.updated)), {
                  addSuffix: true,
                  includeSeconds: true,
                })}
              </SuperTd>

              <SuperTd>{item.product.title}</SuperTd>

              <SuperTd>
                <ui.Link
                  aria-label="open product page"
                  target="_blank"
                  href={item.product.productPageUrl}
                  textDecoration="underline"
                >
                  {item.product.vendor}
                  <ui.Icon as={BiLinkExternal} ml={2} />
                </ui.Link>
              </SuperTd>

              <SuperTd py="0 !important">
                {item.product.tags.map(tag => (
                  <ui.Badge
                    key={tag}
                    variant="outline"
                    fontSize={isGteMd ? 'sm' : '2xs'}
                    borderColor="whiteAlpha.600"
                    mr={2}
                  >
                    {tag}
                  </ui.Badge>
                ))}
              </SuperTd>

              <SuperTd pin="right" bg="black" p="0 !important">
                <InventoryItemMenu
                  item={item}
                  onDelete={() =>
                    deleteItem.mutate({
                      inventoryId,
                      itemId: item._id,
                    })
                  }
                />
              </SuperTd>
            </SuperTr>
          ))}
        </SuperTbody>
      </SuperTable>

      <ui.HStack
        pos="sticky"
        bottom="0"
        left="0"
        bg="blackAlpha.800"
        backdropFilter="blur(2px)"
        p={[2, null, 4]}
        justify="center"
        zIndex={10}
        borderTop="2px"
        borderTopColor="whiteAlpha.50"
      >
        <PageSelector
          onChange={setOffset}
          value={{ ...params, total: itemCount }}
        />
      </ui.HStack>
    </SuperTableContainer>
  );
};
