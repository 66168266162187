import { useToast } from '@chakra-ui/toast';
import { useLocalStorage } from 'hooks';
import decode from 'jwt-decode';
import React, { createContext, useContext } from 'react';
import { ClientContextProvider, createClient } from 'react-fetching-library';
import { cacheProvider } from './cache-provider';
import { AuthActionId, DecodedToken, UserSession, UserActionId } from './users';

interface AuthedFetchContext {
  clearSession: () => void;
  isAuthenticated: boolean;
  session: UserSession | null;
  tokenExpiration: Date | null;
  userId: string | null;
}

const AuthedFetchContext = createContext<AuthedFetchContext>({
  clearSession: () => undefined,
  isAuthenticated: false,
  session: null,
  tokenExpiration: null,
  userId: null,
});

export const AuthedFetchProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const toast = useToast();
  const [session, setSession, clearSession] =
    useLocalStorage<UserSession>('apedash:session');
  const decodedSession = !!session
    ? decode<DecodedToken>(session.accessToken)
    : null;

  const tokenExpiration = !!decodedSession
    ? (() => {
        const date = new Date();
        date.setTime(decodedSession.exp * 1000);
        return date;
      })()
    : null;

  const isAuthenticated = typeof session?.accessToken === 'string'; // @TODO

  const client = createClient({
    /** The cache is used for GET responses that are <1 seconds old */
    cacheProvider: cacheProvider(),
    // : createCache(
    //   action => action.method === 'GET',
    //   ({ timestamp }) => new Date().getTime() - timestamp < 1
    // ),
    requestInterceptors: [
      /** Set the hostname to the appropriate service  */
      client => async action => {
        // const endpoint = `${location.protocol}//${
        //   action.hostname
        // }/${action.endpoint.slice(action.endpoint.startsWith('/') ? 1 : 0)}`;

        return {
          ...action,
          endpoint: !action.hostname.startsWith('http')
            ? `${location.protocol}//${action.hostname}/${action.endpoint.slice(
                action.endpoint.startsWith('/') ? 1 : 0
              )}`
            : `${action.hostname}/${action.endpoint.slice(
                action.endpoint.startsWith('/') ? 1 : 0
              )}`,
        };
      },
      /** Set the authorization header for calls that require auth  */
      _ => async action => {
        const headers = {
          'Content-Type': 'application/json',
          ...(action.skipAuth
            ? {}
            : { Authorization: `Bearer ${session?.accessToken}` }),
        };

        return { ...action, headers };
      },
    ],
    responseInterceptors: [
      /**
       * The error data we want will for some reason be on the `payload`.
       * If there is an error, AND not already an `errorObject` on the response,
       * move `payload` to `errorObject`.
       *  */
      _ => async (action, response) => {
        if (response.error && (response.errorObject ?? true)) {
          return {
            ...response,
            payload: undefined,
            errorObject: response.payload,
          };
        }

        return response;
      },
      /** Authentication session setting and clearing */
      _ => async (action, response) => {
        /** Clear the session immediately in the event of a 401 */
        if (response.status === 401) {
          clearSession();
          setTimeout(() =>
            toast({
              status: 'warning',
              isClosable: true,
              title: 'Session Expired',
              description: `You have been automatically logged out.`,
            })
          );
        }

        if (!response.error) {
          switch (action.actionId) {
            case AuthActionId.Register:
            case AuthActionId.Login:
            case AuthActionId.Refresh:
              setSession(response.payload);
              break;
            case UserActionId.Delete:
              clearSession();
              break;
          }
        }

        return response;
      },
      /** Issue any toasts */
      _ => async (action, response) => {
        /** Error toasts */
        if (response.error && typeof action.onError === 'function') {
          // eslint-disable-next-line
          const errorMessages = [response.errorObject?.message ?? []].flat();
          const toastOptions = action.onError({ ...response, errorMessages });
          if (toastOptions) {
            setTimeout(() =>
              toast({ isClosable: true, status: 'error', ...toastOptions })
            );
          }
        }

        /** Success toasts */
        if (!response.error && typeof action.onSuccess === 'function') {
          const toastOptions = action.onSuccess(response);
          if (toastOptions) {
            setTimeout(
              () =>
                toast({
                  isClosable: true,
                  status: 'success',
                  ...toastOptions,
                }),
              1000
            );
          }
        }

        return response;
      },
    ],
  });

  return (
    <AuthedFetchContext.Provider
      value={{
        clearSession,
        isAuthenticated,
        session,
        tokenExpiration,
        userId: decodedSession?.sub ?? null,
      }}
    >
      <ClientContextProvider client={client}>{children}</ClientContextProvider>
    </AuthedFetchContext.Provider>
  );
};

export const useAuthedFetch = (): AuthedFetchContext =>
  useContext(AuthedFetchContext);
