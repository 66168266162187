import {
  Button,
  Flex,
  Heading,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { useBreakpointQuery } from '../../../hooks';
import { useCatalogSearchParams } from '../useCatalogSearchParams';

type Option = Record<'label' | 'value', string>;

export const FilterTags: React.FC = () => {
  const { activeParams, applyParams, pendingParams } = useCatalogSearchParams();

  const isGteMd = useBreakpointQuery('md');

  const searchText: string = activeParams.q.$text?.$search ?? '';

  const selectedTags: Option[] = (
    (activeParams.q.tags as { $in?: string[] })?.$in ?? []
  ).map(tag => ({ value: tag, label: tag }));

  const selectedVendors: Option[] = (
    (activeParams.q.vendor as { $in?: string[] })?.$in ?? []
  ).map(vendor => ({ value: vendor, label: vendor }));

  const selectedUsers: Option[] = (
    (pendingParams.q.createdBy as { $in?: string[] })?.$in ?? []
  ).map(_id => ({ value: _id, label: _id }));

  return (
    <VStack w="full" align="flex-start" spacing={4}>
      <Flex align="center" justify="space-between" w="full">
        <Heading flexShrink={0} size={isGteMd ? 'md' : 'sm'}>
          Filtering by
        </Heading>
      </Flex>

      <Wrap spacing={2}>
        {searchText && (
          <Filter
            key={searchText}
            kind="text"
            label={searchText}
            onClick={() => {
              applyParams({
                ...activeParams,
                q: { ...activeParams.q, $text: undefined },
              });
            }}
          />
        )}
        {selectedTags.map(({ label, value }) => (
          <Filter
            key={value}
            kind="tag"
            label={label}
            onClick={() => {
              const values = selectedTags.map(opt => opt.value);
              const newVals = values.filter(v => v !== value);
              applyParams({
                ...activeParams,
                q: {
                  ...activeParams.q,
                  tags: newVals.length > 0 ? { $in: newVals } : undefined,
                },
              });
            }}
          />
        ))}
        {selectedVendors.map(({ label, value }) => (
          <Filter
            key={value}
            kind="vendor"
            label={label}
            onClick={() => {
              const values = selectedVendors.map(opt => opt.value);
              const newVals = values.filter(v => v !== value);
              applyParams({
                ...activeParams,
                q: {
                  ...activeParams.q,
                  vendor: newVals.length > 0 ? { $in: newVals } : undefined,
                },
              });
            }}
          />
        ))}
        {selectedUsers.map(({ label, value }) => (
          <Filter
            key={value}
            kind="user id"
            label={label}
            onClick={() => {
              const values = selectedUsers.map(opt => opt.value);
              const newVals = values.filter(v => v !== value);
              applyParams({
                ...activeParams,
                q: {
                  ...activeParams.q,
                  createdBy: newVals.length > 0 ? { $in: newVals } : undefined,
                },
              });
            }}
          />
        ))}
        <Button
          onClick={() => applyParams({ ...pendingParams, q: {}, offset: 0 })}
          colorScheme="gray"
          variant="ghost"
          bg="whiteAlpha.200"
          size={isGteMd ? 'sm' : 'xs'}
        >
          Clear All
        </Button>
      </Wrap>
    </VStack>
  );
};

type FilterTagProps = {
  kind: string;
  label: string;
  onClick: () => void;
};

const Filter: React.FC<FilterTagProps> = ({ kind, label, onClick }) => {
  const isGteMd = useBreakpointQuery('md');

  return (
    <Tag size={isGteMd ? 'lg' : 'md'} pl={0} variant="solid">
      <TagLabel
        px={2}
        bg="primary.900"
        fontSize="2xs"
        minH="inherit"
        fontWeight="800"
        d="flex"
        alignItems="center"
        textTransform="uppercase"
      >
        {kind}
      </TagLabel>
      <TagLabel pl={2}>{label}</TagLabel>
      <TagCloseButton onClick={onClick} />
    </Tag>
  );
};
