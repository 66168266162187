import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { NavLink } from 'react-router-dom';
import { Loader } from '../shared/Loader';
import { login } from '../apis';
import { AuthFormWrapper } from './AuthFormWrapper';

export const Login: React.FC = () => {
  const { error, errorObject, mutate, loading } = useMutation(login);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <AuthFormWrapper>
      <Flex direction="row" align="center" justify="space-between">
        <Heading as="h1">Login</Heading>

        <Link as={NavLink} to="/register" variant="navlink">
          Register
        </Link>
      </Flex>

      <VStack direction="column" spacing={6}>
        {error && (
          <Alert cl status="error">
            <VStack align="start" spacing={4}>
              <Flex direction="row">
                <AlertIcon />
                <AlertTitle>Oh no!</AlertTitle>
              </Flex>
              <pre>{JSON.stringify(errorObject, null, 2)}</pre>
            </VStack>
          </Alert>
        )}

        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            borderColor="white"
            type="email"
            disabled={loading}
            onChange={e => setEmail(e.currentTarget.value)}
            value={email}
          />
        </FormControl>

        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            borderColor="white"
            type="password"
            disabled={loading}
            onChange={e => setPassword(e.currentTarget.value)}
            value={password}
          />
        </FormControl>
      </VStack>

      <Flex direction="row">
        <Button
          disabled={loading}
          isLoading={loading}
          spinner={<Loader color="white" />}
          onClick={e => {
            e.preventDefault();
            mutate({ email, password });
          }}
          type="submit"
        >
          Continue
        </Button>
      </Flex>
    </AuthFormWrapper>
  );
};
