import { Action } from 'react-fetching-library';
import { Hostname } from '../hostnames';

export const scrapeMetadata = (
  url: string
): Action<Array<[string, string]>> => ({
  endpoint: `/scrape?url=${url}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});
