export interface Timestamps {
  createdAt: string;
  updatedAt: string;
}

export interface DeviceConfigBase extends Timestamps {
  actions: DeviceAction[];
  configId: string;
  deviceName: string;
  events: string[];
  latestActivation?: DeviceEvent;
  latestActionTrigger?: DeviceEvent;
  latestPing?: DeviceEvent;
  version: number;
}

export interface DeviceConfig extends DeviceConfigBase {
  pingIntervalSeconds: number;
}

export interface UpdateDeviceConfigDto
  extends Pick<DeviceConfig, 'pingIntervalSeconds'> {
  actions: Array<Subtract<DeviceAction, DeviceActionBase>>;
}

export enum DeviceActionType {
  Noop = 'NOOP',
  SendSms = 'SEND_SMS',
}

export interface DeviceActionBase extends Timestamps {
  actionId: string;
  actionType: DeviceActionType;
  configId: string;
  latestFailure?: ActionTriggerEvent;
  latestSuccess?: ActionTriggerEvent;
}

export type DeviceAction = SendSmsAction | NoopAction;

export interface NoopAction extends DeviceActionBase {
  actionType: DeviceActionType.Noop;
}

export interface SendSmsAction extends DeviceActionBase {
  actionType: DeviceActionType.SendSms;
  body: string;
  name: string;
  to: string;
}

export enum DeviceEventType {
  Activation = 'ACTIVATION',
  ActionTrigger = 'ACTION_TRIGGER',
  Ping = 'PING',
}

export enum ActionTriggerEventStatus {
  Fail = 'FAIL',
  Success = 'SUCCESS',
}

export interface DeviceEventBase extends Timestamps {
  config: string;
  configVersion: number;
  eventId: string;
  eventType: DeviceEventType;
}

export type DeviceEvent = ActionTriggerEvent | ActivationEvent | PingEvent;

export interface ActionTriggerEvent extends DeviceEventBase {
  eventType: DeviceEventType.ActionTrigger;
  action: string;
  status: ActionTriggerEventStatus;
}

export interface ActivationEvent extends DeviceEventBase {
  eventType: DeviceEventType.Activation;
}

export interface PingEvent extends DeviceEventBase {
  eventType: DeviceEventType.Ping;
}
