import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BiChevronDown, BiFilterAlt } from 'react-icons/bi';
import { Product, ResourceParams } from '../../../apis';
import { useBreakpointQuery } from '../../../hooks';
import { useCatalogSearchCounts } from '../useCatalogSearchCounts';
import { useCatalogSearchParams } from '../useCatalogSearchParams';
import { Filters } from './Filters';
import { FilterTags } from './FilterTags';
import { SortMenu } from './SortMenu';

type CatalogControlsValue = Omit<ResourceParams<Product>, 'offset'>;

/**
 * Go here for design inspo
 * https://cdn.dribbble.com/users/2380754/screenshots/15232468/media/f7946cc5dc706c43fcdafe2f72b573e3.mp4
 */
export const CatalogControls: React.FC = () => {
  const {
    activeParams,
    applyParams,
    isFiltering,
    isFiltersEqual,
    pendingParams,
    setPendingParams,
  } = useCatalogSearchParams();
  const {
    activeCount,
    activeCountIsLoading,
    pendingCount,
    pendingCountIsLoading,
  } = useCatalogSearchCounts();

  const isGteMd = useBreakpointQuery('md');
  const { isOpen: isFilterOpen, onToggle: onFilterToggle } = useDisclosure();

  const setFilter = (q: CatalogControlsValue['q']) =>
    setPendingParams({
      ...pendingParams,
      q: { ...pendingParams.q, ...q },
    });

  const searchText: string = pendingParams.q.$text?.$search ?? '';
  const activeSortOrder = Object.values(activeParams.sort)[0] ?? 1;
  const activeSortKey = Object.keys(activeParams.sort)[0] ?? 'title';

  return (
    <>
      <VStack as="form" w="full" maxW="full" spacing={4}>
        <Flex
          align="flex-end"
          justify={['space-between', null, 'flex-start']}
          w="full"
        >
          <FormControl id="searchText" w="auto">
            <FormLabel>Search by Text</FormLabel>
            <Input
              onChange={e => {
                const text = e.target.value;
                setFilter({
                  $text: text.length > 0 ? { $search: text } : undefined,
                });
              }}
              size={isGteMd ? 'md' : 'sm'}
              value={searchText}
            />
          </FormControl>

          <Button
            bg={isFilterOpen ? 'whiteAlpha.500' : 'whiteAlpha.50'}
            ml={4}
            mb={isGteMd ? 1 : 0}
            colorScheme="gray"
            leftIcon={<Icon as={BiFilterAlt} boxSize={[4, null, 5]} mr="1" />}
            rightIcon={
              <Icon
                as={BiChevronDown}
                p={0}
                mr={1}
                boxSize={[4, null, 6]}
                transition="transform .3s"
                transform={isFilterOpen ? 'rotate(-180deg)' : ''}
              />
            }
            onClick={onFilterToggle}
            size="sm"
            variant="ghost"
          >
            Filters
          </Button>
        </Flex>

        <Collapse
          style={{ width: '100%', maxWidth: '100%' }}
          in={isFilterOpen}
          unmountOnExit
        >
          <Filters />
        </Collapse>
        {!isFiltersEqual && (
          <HStack w="full" spacing={4}>
            <Button
              isDisabled={pendingCount < 1}
              onClick={e => {
                e.preventDefault();
                applyParams({ ...pendingParams, offset: 0 });
                onFilterToggle();
              }}
              size="sm"
              type="submit"
            >
              Apply
            </Button>
            <Heading
              size="sm"
              filter={pendingCountIsLoading ? 'blur(0.2rem)' : ''}
            >
              {`${pendingCount} ${pendingCount === 1 ? 'match' : 'matches'}`}
            </Heading>
          </HStack>
        )}
      </VStack>

      {isFiltering && <FilterTags />}

      <Flex align="center" justify="space-between" w="full">
        <Heading
          filter={activeCountIsLoading ? 'blur(0.2rem)' : ''}
          size={isGteMd ? 'md' : 'sm'}
        >
          {`${activeCount} ${activeCount === 1 ? 'product' : 'products'}`}
        </Heading>

        <HStack align="baseline">
          <Text fontSize="sm">Sort by</Text>
          {/* Wrap this in a box to prevent popper warning */}
          <Box>
            <SortMenu
              activeParams={activeParams}
              activeSortKey={activeSortKey}
              activeSortOrder={activeSortOrder}
              applyParams={applyParams}
              colorScheme="gray"
              rightIcon={
                <Icon as={BiChevronDown} p={0} mr={1} boxSize={[4, null, 6]} />
              }
              p={2}
              size="sm"
              variant="ghost"
            />
          </Box>
        </HStack>
      </Flex>
    </>
  );
};
