import { Flex, Heading, HStack } from '@chakra-ui/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuthedFetch } from './apis';
import { Login } from './auth/Login';
import { Register } from './auth/Register';
import { Inventorio } from './inventorio/Inventorio';
import { GcpDeviceDetails } from './gcp-iot/DeviceDetails';
import { DeviceRegistryDetails } from './gcp-iot/DeviceRegistryDetails';
import { DeviceRegistryList } from './gcp-iot/DeviceRegistryList';
import { ComponentPreview } from './layout/ComponentPreview';
import { TopBar } from './layout/TopBar';
import { ThreeCanvas } from './3d';
import { Profiles } from './users';
import { Profile } from './users/Profile';
import { DeviceListing } from './iot/DeviceListing';
import { DeviceDetails } from './iot/DeviceDetails';

const RoutesWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Flex
    id="route"
    direction="column"
    h="100vh"
    maxH="100vh"
    w="100vw"
    maxW="100vw"
    overflow="auto"
    pos="relative"
  >
    {children}
  </Flex>
);

export const Routes: React.FC = () => {
  const { isAuthenticated } = useAuthedFetch();

  if (!isAuthenticated) {
    return (
      <RoutesWrapper>
        <Switch>
          <Route path="/preview" component={ComponentPreview} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route
            render={({ location }) => (
              <Redirect
                to={{
                  ...location,
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )}
          />
        </Switch>
        <HStack
          pos="absolute"
          left="0"
          right="0"
          top={4}
          direction="row"
          align="center"
          justify="center"
          spacing={2}
        >
          <Heading size="sm" fontWeight="black">
            apedash
          </Heading>
          <Heading size="sm" fontWeight="semibold" color="whiteAlpha.800">
            {process.env.npm_package_version}
          </Heading>
        </HStack>
      </RoutesWrapper>
    );
  }

  return (
    <RoutesWrapper>
      <TopBar />
      <Switch>
        {/* Uses */}
        <Route path="/users/:id" component={Profile} />
        <Route path="/users" component={Profiles} />

        {/* Inventorio */}
        <Route path="/inventorio" component={Inventorio} />

        {/* GCP IoT */}
        <Route
          path="/gcp-iot/:registryId/:deviceId"
          component={GcpDeviceDetails}
        />
        <Route path="/gcp-iot/:registryId" component={DeviceRegistryDetails} />
        <Route path="/gcp-iot" component={DeviceRegistryList} />

        {/* IoT */}
        <Route path="/iot/:configId" component={DeviceDetails} />
        <Route path="/iot" component={DeviceListing} />

        {/* Preview */}
        <Route path="/preview" component={ComponentPreview} />

        {/* Three */}
        <Route path="/three" component={ThreeCanvas} />

        {/* Home redirect */}
        <Route
          render={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: '/users/me',
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    </RoutesWrapper>
  );
};
