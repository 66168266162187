import * as ui from '@chakra-ui/react';
import React from 'react';
import {
  BiBookContent,
  BiBox,
  BiChevronDown,
  BiChevronRight,
  BiCollection,
  BiCubeAlt,
} from 'react-icons/bi';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useBreakpointQuery, useLocalStorage } from '../../hooks';

type Props = {};

export const InventorioNav: React.FC<Props> = ({}) => {
  const isGteMd = useBreakpointQuery('md');
  const [shouldHideLinkLabels, setHideLinkLabels] = useLocalStorage<boolean>(
    'inventorio-nav-hide-labels'
  );
  const { isOpen, onToggle, onClose } = ui.useDisclosure();

  // Don't hide when mobile
  const hideLinkLabels = shouldHideLinkLabels && isGteMd;

  return (
    <ui.Flex
      borderRight="2px"
      borderBottom={isGteMd ? 'none' : '2px'}
      direction="column"
      borderColor="whiteAlpha.50"
      minW={['full', null, 'auto']}
      maxW={['full', null, 256]}
      pos="sticky"
      top="0"
    >
      <ui.HStack p={4} borderBottom="2px" h={16} borderColor="whiteAlpha.50">
        <ui.Icon as={BiCubeAlt} boxSize={8} />
        {!hideLinkLabels && (
          <ui.Heading as={RouterNavLink} to="/inventorio" size="lg" pr={2}>
            Inventorio
          </ui.Heading>
        )}
        {!isGteMd && (
          <>
            <ui.Spacer />
            <ui.IconButton
              aria-label="view pages"
              icon={<BiChevronDown size={28} />}
              variant="ghost"
              colorScheme="gray"
              transition="transform .3s"
              transform={isOpen ? 'rotate(-180deg)' : ''}
              p={0}
              mr={1}
              boxSize={8}
              onClick={() => onToggle()}
            />
          </>
        )}
      </ui.HStack>

      <ui.Collapse in={isGteMd || isOpen} unmountOnExit>
        <ui.VStack align="stretch" w="full" py={2}>
          <NavLink
            hideLabel={hideLinkLabels ?? false}
            icon={BiBookContent}
            label="Catalog"
            to="/inventorio/catalog"
            onClick={onClose}
          />

          <NavLink
            hideLabel={hideLinkLabels ?? false}
            icon={BiBox}
            label="Inventories"
            to="/inventorio/inventories"
            onClick={onClose}
          />
          <NavLink
            hideLabel={hideLinkLabels ?? false}
            icon={BiCollection}
            label="Projects"
            to="/inventorio/projects"
          />
        </ui.VStack>
      </ui.Collapse>

      {/* Collapse/expand nav button */}
      {isGteMd && (
        <>
          <ui.Spacer />
          <ui.Flex
            justify={hideLinkLabels ? 'center' : 'flex-end'}
            direction="row"
            p={4}
          >
            <ui.IconButton
              aria-label="view pages"
              icon={<ui.Icon as={BiChevronRight} boxSize={6} />}
              variant="ghost"
              colorScheme="gray"
              transition="transform .3s"
              transform={!hideLinkLabels ? 'rotate(-180deg)' : ''}
              onClick={() =>
                setHideLinkLabels(shouldHideLinkLabels === true ? false : true)
              }
            />
          </ui.Flex>
        </>
      )}
    </ui.Flex>
  );
};

const NavLink: React.FC<{
  icon: ui.As<any>;
  to: string;
  hideLabel: boolean;
  label: string;
  onClick?: () => void;
}> = ({ icon, hideLabel, label, onClick, to }) => (
  <ui.Tooltip isDisabled={!hideLabel} label={label} placement="right">
    <ui.HStack
      as={RouterNavLink}
      to={to}
      p={4}
      pl={5}
      onClick={onClick}
      sx={{
        _hover: { bg: 'whiteAlpha.50' },
        '&.active': { bg: 'whiteAlpha.50' },
        '&.active svg': { color: 'primary.500' },
        _active: { bg: 'whiteAlpha.200' },
      }}
    >
      <ui.Icon as={icon} boxSize={5} mr={2} />
      {!hideLabel && <ui.Heading size="sm">{label}</ui.Heading>}
    </ui.HStack>
  </ui.Tooltip>
);
