/* eslint-disable @typescript-eslint/no-unused-vars */
import * as ui from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { useHistory, useParams } from 'react-router';
import { GroupBase } from 'react-select';
import {
  getFieldValues,
  intoErrorMessage,
  ProductDto,
  updateProduct,
} from '../../../apis';
import { useBreakpointQuery } from '../../../hooks';
import { urlIsValid as urlIsValidUtil } from '../../../lib';
import {
  CreatableSelect,
  EditableField,
  EditableFieldInput,
  EditableFieldPreview,
} from '../../../shared';

type Props = {
  isDisabled?: boolean;
  isLoading: boolean;
  onChange: (value: ProductDto) => void;
  onSave: () => void;
  value: ProductDto;
};

type Option = Record<'label' | 'value', string>;

export const ProductForm: React.FC<Props> = ({
  isDisabled = false,
  isLoading,
  onChange,
  onSave,
  value,
}) => {
  const { id } = useParams<{ id: string }>();
  const isGteMd = useBreakpointQuery('md');

  const { payload: tags = [], loading: loadingTags } = useQuery(
    getFieldValues('tags')
  );

  const { payload: vendors = [], loading: loadingVendors } = useQuery(
    getFieldValues('vendor')
  );

  const { location } = useHistory();
  const [formValue, setFormValue] = React.useState(value);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  const {
    loading,
    mutate: updateItem,
    error: updateError,
    errorObject: updateErrorObject,
  } = useMutation(updateProduct);

  const urlIsValid = useMemo(
    () => urlIsValidUtil(formValue.productPageUrl),
    [formValue.productPageUrl]
  );

  return (
    <ui.VStack align="flex-start" w="full" spacing={12}>
      <ui.VStack align="flex-start" w="full" spacing={6}>
        <EditableField
          id="productPageUrl"
          isRequired
          onCancel={productPageUrl =>
            setFormValue(value => ({ ...value, productPageUrl }))
          }
          onSubmit={productPageUrl =>
            updateItem({ _id: id, ...value, productPageUrl })
          }
          onChange={productPageUrl =>
            setFormValue(value => ({ ...value, productPageUrl }))
          }
          value={formValue.productPageUrl}
        >
          {({ submissionResponse }) => (
            <>
              <ui.FormLabel>Product Page URL</ui.FormLabel>
              <EditableFieldPreview />
              <ui.Input
                as={EditableFieldInput}
                type="text"
                isInvalid={submissionResponse?.error}
              />
              <ui.FormHelperText color="danger.500">
                {intoErrorMessage(submissionResponse)}
              </ui.FormHelperText>
              {!urlIsValid && (
                <ui.FormHelperText color="danger.500">
                  Enter a valid URL
                </ui.FormHelperText>
              )}
            </>
          )}
        </EditableField>

        <EditableField
          id="title"
          onCancel={title => setFormValue(value => ({ ...value, title }))}
          onSubmit={title => updateItem({ _id: id, ...value, title })}
          onChange={title => setFormValue(value => ({ ...value, title }))}
          value={formValue.title}
        >
          {({
            submissionResponse,
            getInputProps,
            getSubmitButtonProps,
            isEditing,
          }) => (
            <>
              <ui.FormLabel>Title</ui.FormLabel>
              <EditableFieldPreview />
              <ui.InputGroup hidden={!isEditing}>
                <ui.Input
                  {...getInputProps()}
                  type="text"
                  isInvalid={submissionResponse?.error}
                />
                <ui.InputRightElement>
                  <ui.Button {...getSubmitButtonProps()}>Save</ui.Button>
                </ui.InputRightElement>
              </ui.InputGroup>
              <ui.FormHelperText color="danger.500">
                {intoErrorMessage(submissionResponse)}
              </ui.FormHelperText>
            </>
          )}
        </EditableField>

        <EditableField
          id="productImageUrl"
          onCancel={productImageUrl =>
            setFormValue(value => ({ ...value, productImageUrl }))
          }
          onSubmit={productImageUrl =>
            updateItem({ _id: id, ...value, productImageUrl })
          }
          onChange={productImageUrl =>
            setFormValue(value => ({ ...value, productImageUrl }))
          }
          value={formValue.productImageUrl}
        >
          {({ submissionResponse }) => (
            <>
              <ui.FormLabel>Product Image URL</ui.FormLabel>
              <EditableFieldPreview />
              <ui.Input
                as={EditableFieldInput}
                type="text"
                isInvalid={submissionResponse?.error}
              />
              <ui.FormHelperText color="danger.500">
                {intoErrorMessage(submissionResponse)}
              </ui.FormHelperText>
            </>
          )}
        </EditableField>

        <EditableField<Option>
          id="vendor"
          onCancel={opt => opt && onChange({ ...value, vendor: opt.value })}
          onSubmit={opt => updateItem({ _id: id, ...value, vendor: opt.value })}
          onChange={opt => {
            console.log('vendor onChange: ', opt);
            return opt && onChange({ ...value, vendor: opt.value });
          }}
          value={{
            label: value.vendor ?? '',
            value: value.vendor ?? '',
          }}
        >
          {({ submissionResponse }) => (
            <>
              <ui.FormLabel>Vendor</ui.FormLabel>
              <EditableFieldPreview>{value.vendor}</EditableFieldPreview>
              <EditableFieldInput>
                <CreatableSelect<Option, false, GroupBase<Option>>
                  autoFocus
                  onChange={opt => {
                    console.log('vendor onChange: ', opt);
                    return opt && onChange({ ...value, vendor: opt.value });
                  }}
                  options={(vendors ?? []).map(vendor => ({
                    label: vendor,
                    value: vendor,
                  }))}
                  value={{
                    label: value.vendor ?? '',
                    value: value.vendor ?? '',
                  }}
                />
              </EditableFieldInput>
              <ui.FormHelperText color="danger.500">
                {intoErrorMessage(submissionResponse)}
              </ui.FormHelperText>
            </>
          )}
        </EditableField>

        <ui.FormControl isDisabled={isDisabled || loadingVendors} id="vendor">
          <ui.FormLabel>Vendor</ui.FormLabel>
          <CreatableSelect<Option, false, GroupBase<Option>>
            onChange={opt => opt && onChange({ ...value, vendor: opt.value })}
            options={(vendors ?? []).map(vendor => ({
              label: vendor,
              value: vendor,
            }))}
            value={{ label: value.vendor ?? '', value: value.vendor ?? '' }}
          />
        </ui.FormControl>

        {/* <ui.FormControl isDisabled={isDisabled || loadingTags} id="vendor">
          <ui.FormLabel>Tags</ui.FormLabel>
          <CreatableSelect<Option, true, GroupBase<Option>>
            isMulti
            closeMenuOnSelect={false}
            onChange={opts =>
              onChange({ ...value, tags: opts.map(opt => opt.value) })
            }
            options={(tags ?? []).filter(Boolean).map(tag => ({
              label: tag,
              value: tag,
            }))}
            value={value.tags.map(tag => ({ label: tag, value: tag }))}
          />
        </ui.FormControl> */}

        <EditableField<Option[]>
          id="tags"
          onCancel={productPageUrl =>
            setFormValue(value => ({ ...value, tags: value.tags }))
          }
          onSubmit={opts =>
            updateItem({ _id: id, ...value, tags: opts.map(opt => opt.value) })
          }
          onChange={opts =>
            onChange({ ...value, tags: opts.map(opt => opt.value) })
          }
          value={value.tags.map(tag => ({ label: tag, value: tag }))}
        >
          {({ submissionResponse }) => (
            <>
              <ui.FormLabel>Tags</ui.FormLabel>
              <EditableFieldPreview>
                <ui.Wrap spacing={[2, 4]}>
                  {value.tags.map(tag => (
                    <ui.Badge
                      key={tag}
                      variant="outline"
                      fontSize={isGteMd ? 'sm' : '2xs'}
                      borderColor={
                        isGteMd ? 'whiteAlpha.600' : 'whiteAlpha.300'
                      }
                    >
                      {tag}
                    </ui.Badge>
                  ))}
                </ui.Wrap>
              </EditableFieldPreview>
              <EditableFieldInput>
                <CreatableSelect<Option, true, GroupBase<Option>>
                  autoFocus
                  isMulti
                  closeMenuOnSelect={false}
                  options={(tags ?? []).filter(Boolean).map(tag => ({
                    label: tag,
                    value: tag,
                  }))}
                />
              </EditableFieldInput>
              <ui.FormHelperText color="danger.500">
                {intoErrorMessage(submissionResponse)}
              </ui.FormHelperText>
            </>
          )}
        </EditableField>
      </ui.VStack>

      <ui.HStack spacing={6}>
        <ui.Button
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={e => {
            e.preventDefault();
            onSave();
          }}
          size="md"
          type="submit"
        >
          Save
        </ui.Button>
      </ui.HStack>
    </ui.VStack>
  );
};
