import * as DREI from '@react-three/drei';
import React, { useState } from 'react';
import { HEXCODES } from '../hexcodes';
import { Location } from '../types';
import { TILE_HEIGHT, TILE_SIZE, useStore } from '../useStore';

type Props = Location;

export const TileObject = React.forwardRef<THREE.Group, Props>(
  ({ x, y }, ref) => {
    const { partPendingPlacement, parts, actions } = useStore();
    const [hovered, hover] = useState(false);

    const isOccupied = parts.some(p => p.x === x && p.y === y);
    const placementIsPending = !!partPendingPlacement;

    return (
      <group position={[x * TILE_SIZE, y * TILE_SIZE, 0]} ref={ref}>
        <DREI.RoundedBox
          args={[TILE_SIZE, TILE_SIZE, TILE_HEIGHT]}
          radius={0.5}
          smoothness={1}
          position={[0, 0, -TILE_HEIGHT / 2]}
          onPointerOver={_ => {
            if (placementIsPending && !isOccupied) {
              hover(true);
              actions.movePendingPart(x, y);
            }
          }}
          onPointerOut={_ => hover(false)}
          onPointerDown={e => {
            if (placementIsPending && !isOccupied) {
              actions.placePartAt(x, y);
              e.stopPropagation();
            }
          }}
        >
          <meshStandardMaterial
            color={
              placementIsPending && !isOccupied
                ? hovered
                  ? HEXCODES.gray['400']
                  : HEXCODES.gray['600']
                : HEXCODES.black
            }
          />
        </DREI.RoundedBox>
      </group>
    );
  }
);
