import * as ui from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { BiEdit, BiTrashAlt } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteDeviceConfig,
  getDeviceConfig,
  updateDeviceConfig,
} from '../../apis';
import { PageContent, PageHeader } from '../../layout';
import { ConfirmDelete, Pre } from '../../shared';
import { ActionDetails } from '../ActionDetails';
import { ConfigForm } from '../ConfigForm';
import { DeviceEventListing } from '../DeviceEventListing';

type Props = {};

export const DeviceDetails: React.FC<Props> = ({}) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = ui.useDisclosure();
  const { configId }: { configId: string } = useParams();
  const { payload: device, query: getConfigAgain } = useQuery(
    getDeviceConfig(configId)
  );
  const {
    loading: updateConfigLoading,
    mutate: updateConfig,
    error: updateConfigError,
  } = useMutation(updateDeviceConfig);
  const {
    loading: deleteConfigLoading,
    mutate: deleteConfig,
    error: deleteConfigError,
  } = useMutation(deleteDeviceConfig);
  const [formValue, setFormValue] = useState(device!);

  // If the config from the query changes, override the form value
  useEffect(() => {
    if (formValue !== device) setFormValue(device!);
  }, [device]); // eslint-disable-line

  const onUpdateConfig = async () => {
    const { error } = await updateConfig(formValue);
    if (error) return;
    await getConfigAgain();
    onClose();
  };

  const onDeleteConfig = async () => {
    const { error } = await deleteConfig(configId);
    if (error) return;
    history.replace(`/iot`);
  };

  return (
    <>
      <ui.Modal isOpen={isOpen} onClose={onClose}>
        <ui.ModalOverlay />
        <ui.ModalContent>
          <ui.ModalHeader>Edit Config</ui.ModalHeader>
          <ui.ModalCloseButton />
          <ui.ModalBody>
            <ConfigForm onChange={setFormValue} value={formValue} />
            {updateConfigError && <ui.Text>Something went wrong...</ui.Text>}
          </ui.ModalBody>
          <ui.ModalFooter>
            <ui.Button
              disabled={updateConfigLoading}
              mr={3}
              onClick={onUpdateConfig}
            >
              Save
            </ui.Button>
          </ui.ModalFooter>
        </ui.ModalContent>
      </ui.Modal>

      <PageHeader
        backButton={{ label: '', to: '/iot' }}
        heading={device ? device.deviceName : 'Device'}
      >
        <ui.Spacer />
        <ui.ButtonGroup spacing={4}>
          <ConfirmDelete
            header="Delete device config?"
            body={
              !deleteConfigError
                ? 'Deleting a config from the registry cannot be undone.'
                : 'Something went wrong...'
            }
            onConfirm={onDeleteConfig}
          >
            {({ onOpen }) => (
              <ui.Button
                aria-label="delete device"
                color="danger.500"
                disabled={deleteConfigLoading}
                leftIcon={<Icon as={BiTrashAlt} boxSize={5} />}
                onClick={onOpen}
                size="sm"
                colorScheme="black"
              >
                Delete Device
              </ui.Button>
            )}
          </ConfirmDelete>
          <ui.Button
            aria-label="delete action"
            colorScheme="black"
            leftIcon={<ui.Icon as={BiEdit} boxSize={5} />}
            onClick={onOpen}
            size="sm"
          >
            Edit Config
          </ui.Button>
        </ui.ButtonGroup>
      </PageHeader>
      <PageContent maxW="full">
        <ui.Flex direction={['column', null, 'row']}>
          <ui.VStack
            align="stretch"
            flex="60%"
            maxW="60%"
            spacing={8}
            mr={[0, null, 16]}
          >
            <ui.Heading size="2xl">Config</ui.Heading>
            {/* <ConfigForm onChange={setFormValue} value={formValue} /> */}

            <Pre contents={device} />
            <ui.Heading size="2xl">Actions</ui.Heading>
            {device?.actions.map((action, i) => (
              <ActionDetails key={action.actionId} action={action} index={i} />
            ))}
          </ui.VStack>
          <DeviceEventListing />
        </ui.Flex>
      </PageContent>
    </>
  );
};
