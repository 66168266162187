import { TinyColor } from '@ctrl/tinycolor';

// Go here to generate color scales
// http://mcg.mbitson.com/#!?mcgpalette0=%238f03ff&mcgpalette1=%23c9ff05&mcgpalette2=%23ff0342
const black = '#0b0b10';
const white = '#ffffff';

export const colors = {
  black,
  white,
  gray: {
    50: new TinyColor(black).mix('#fff', 96).toHexString(),
    100: new TinyColor(black).mix('#fff', 80).toHexString(),
    200: new TinyColor(black).mix('#fff', 64).toHexString(),
    300: new TinyColor(black).mix('#fff', 48).toHexString(),
    400: new TinyColor(black).mix('#fff', 36).toHexString(),
    500: new TinyColor(black).mix('#fff', 24).toHexString(),
    600: new TinyColor(black).mix('#fff', 16).toHexString(),
    700: new TinyColor(black).mix('#fff', 6).toHexString(),
    800: new TinyColor(black).mix('#fff', 2).toHexString(),
    900: black,
  },
  primary: {
    50: new TinyColor('#8f03ff').lighten(44).toHexString(),
    100: new TinyColor('#8f03ff').lighten(36).toHexString(),
    200: new TinyColor('#8f03ff').lighten(28).toHexString(),
    300: new TinyColor('#8f03ff').lighten(16).toHexString(),
    400: new TinyColor('#8f03ff').lighten(8).toHexString(),
    500: '#8f03ff',
    600: new TinyColor('#8f03ff').darken(8).toHexString(),
    700: new TinyColor('#8f03ff').darken(16).toHexString(),
    800: new TinyColor('#8f03ff').darken(24).toHexString(),
    900: new TinyColor('#8f03ff').darken(30).toHexString(),
  },
  accent: {
    50: new TinyColor('#c9ff05').lighten(44).toHexString(),
    100: new TinyColor('#c9ff05').lighten(36).toHexString(),
    200: new TinyColor('#c9ff05').lighten(28).toHexString(),
    300: new TinyColor('#c9ff05').lighten(16).toHexString(),
    400: new TinyColor('#c9ff05').lighten(8).toHexString(),
    500: '#c9ff05',
    600: new TinyColor('#c9ff05').darken(8).toHexString(),
    700: new TinyColor('#c9ff05').darken(16).toHexString(),
    800: new TinyColor('#c9ff05').darken(24).toHexString(),
    900: new TinyColor('#c9ff05').darken(30).toHexString(),
  },
  danger: {
    50: new TinyColor('#ff0342').lighten(44).toHexString(),
    100: new TinyColor('#ff0342').lighten(36).toHexString(),
    200: new TinyColor('#ff0342').lighten(28).toHexString(),
    300: new TinyColor('#ff0342').lighten(16).toHexString(),
    400: new TinyColor('#ff0342').lighten(8).toHexString(),
    500: '#ff0342',
    600: new TinyColor('#ff0342').darken(8).toHexString(),
    700: new TinyColor('#ff0342').darken(16).toHexString(),
    800: new TinyColor('#ff0342').darken(24).toHexString(),
    900: new TinyColor('#ff0342').darken(30).toHexString(),
  },
  success: {
    50: new TinyColor('#1eff00').lighten(44).toHexString(),
    100: new TinyColor('#1eff00').lighten(36).toHexString(),
    200: new TinyColor('#1eff00').lighten(28).toHexString(),
    300: new TinyColor('#1eff00').lighten(16).toHexString(),
    400: new TinyColor('#1eff00').lighten(8).toHexString(),
    500: '#1eff00',
    600: new TinyColor('#1eff00').darken(8).toHexString(),
    700: new TinyColor('#1eff00').darken(16).toHexString(),
    800: new TinyColor('#1eff00').darken(24).toHexString(),
    900: new TinyColor('#1eff00').darken(30).toHexString(),
  },
  blackAlpha: {
    50: 'rgba(11, 11, 16, 0.04)',
    100: 'rgba(11, 11, 16, 0.06)',
    200: 'rgba(11, 11, 16, 0.08)',
    300: 'rgba(11, 11, 16, 0.16)',
    400: 'rgba(11, 11, 16, 0.24)',
    500: 'rgba(11, 11, 16, 0.36)',
    600: 'rgba(11, 11, 16, 0.48)',
    700: 'rgba(11, 11, 16, 0.64)',
    800: 'rgba(11, 11, 16, 0.80)',
    900: 'rgba(11, 11, 16, 0.92)',
  },
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
};
