import {
  chakra,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
} from '@chakra-ui/react';
import * as pop from 'popmotion';
import React from 'react';
import { mapToRange } from '../../lib';

type Props = Omit<ThemingProps<'Logo'>, 'size'> &
  HTMLChakraProps<'svg'> & {
    size?: keyof typeof Sizes;
    strokeWidth?: number;
  };

const Sizes = {
  sm: 1,
  md: 2,
  lg: 3,
} as const;

export const Logo: React.FC<Props> = ({ strokeWidth = 240, ...props }) => {
  const size = Sizes[props.size ?? 'lg'];
  const showFace = size > Sizes.md;
  const htmlProps = omitThemingProps(props);
  const faceMaskId = showFace ? 'faceMask' : 'faceMaskNoFace';
  const tween = React.useRef<ReturnType<typeof pop.animate> | null>(null);

  const tweenValueRef = React.useRef(110);
  const [tweenValue, setTweenValue] = React.useState(110);

  const refresh = React.useCallback(() => {
    setTweenValue(tweenValueRef.current);
    window.requestAnimationFrame(refresh);
  }, []);

  React.useEffect(() => {
    tween.current = pop.animate({
      from: 0,
      to: 1,
      repeatType: 'reverse',
      duration: 500,
      repeat: Infinity,
      onUpdate: x => (tweenValueRef.current = x),
    });

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => tween.current?.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <chakra.svg
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 340 1010 865"
        boxSize={500}
        {...htmlProps}
      >
        <defs>
          <mask
            id="faceMaskNoFace"
            x="0"
            y="0"
            height="1675"
            width="1000"
            maskUnits="userSpaceOnUse"
            maskContentUnits="userSpaceOnUse"
          >
            <rect
              id="logoMaskBackground"
              x="0"
              y="0"
              height="1000"
              width="1000"
              fill="white"
              stroke="none"
              strokeWidth="0"
            />
          </mask>
          <mask
            id="faceMask"
            x="0"
            y="0"
            height="1675"
            width="1000"
            maskUnits="userSpaceOnUse"
            maskContentUnits="userSpaceOnUse"
          >
            <rect
              id="logoMaskBackground"
              x="0"
              y="0"
              height="1000"
              width="1000"
              fill="white"
              stroke="none"
              strokeWidth="0"
            />
            <path
              id="logoMaskFace"
              d="M 780 565 A 110 110 0 0 1 840 521 A 110 110 0 0 1 900 592 A 110 110 0 0 1 850 640 A 90 110 0 1 1 710 640 A 110 110 0 0 1 660 592 A 110 110 0 0 1 720 521 A 110 110 0 0 1 780 565 Z"
              stroke="none"
              strokeWidth="0"
              fill="black"
            />
          </mask>
          <mask
            id="backLegMask"
            x="0"
            y="0"
            height="1675"
            maskUnits="userSpaceOnUse"
          >
            <rect
              id="logoMaskBackground"
              x="0"
              y="0"
              height="1675"
              width="1600"
              fill="white"
              stroke="none"
              strokeWidth="0"
            />
            <circle
              id="logoMaskBody"
              cx="500"
              cy="500"
              r="500"
              fill="black"
              stroke="none"
              strokeWidth="0"
            />
          </mask>
        </defs>
        {/* Leg 1 */}
        <line
          stroke="currentColor"
          x1="230"
          x2={mapToRange(0, 1, 110, 350)(tweenValue)}
          y1="780"
          y2="1090"
          fill="none"
          strokeWidth={strokeWidth - 20}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* Leg 2 */}
        <line
          stroke="currentColor"
          mask="url(#backLegMask)"
          x1="230"
          x2={mapToRange(0, 1, 350, 110)(tweenValue)}
          y1="780"
          y2="1090"
          fill="none"
          strokeWidth={strokeWidth - 20}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* Leg 3 */}
        <line
          stroke="currentColor"
          x1="500"
          x2={mapToRange(0, 1, 610, 390)(tweenValue)}
          y1="675"
          y2="1090"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* Leg 4 */}
        <line
          stroke="currentColor"
          mask="url(#backLegMask)"
          x1="780"
          x2={mapToRange(0, 1, 660, 890)(tweenValue)}
          y1="675"
          y2="1090"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        {/* <!-- Head --> */}
        <polygon
          stroke="currentColor"
          fill="currentColor"
          mask={`url(#${faceMaskId})`}
          points="780,490 860,630 780,680 700,630"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        {/* Nose */}
        <path
          fill="currentColor"
          d="M 780 580 A 110 110 0 0 1 795,630 L 765 630 A 110 110 0 0 1 780,580 Z"
          stroke="none"
          strokeWidth="0"
        />
        {/* Left eye */}
        <path
          fill="currentColor"
          d={`M 780 570 L 685 570 A 110 110 0 0 0 725,${mapToRange(
            0,
            1,
            570,
            600
          )(1)} A 110 110 0 0 1 780 570 Z`}
          stroke="none"
          strokeWidth="0"
        />
        {/* Right eye */}
        <path
          fill="currentColor"
          d={`M 875 570 L 780 570 A 110 110 0 0 1 835,${mapToRange(
            0,
            1,
            570,
            600
          )(1)} A 110 110 0 0 0 875,570 Z`}
          stroke="none"
          strokeWidth="0"
        />
        {/* Mouth */}
        <line
          stroke="currentColor"
          x1="680"
          x2="880"
          y1="700"
          y2="700"
          strokeWidth="20"
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </chakra.svg>
    </>
  );
};
