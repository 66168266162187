import * as ui from '@chakra-ui/react';
import React from 'react';
import {
  BiDetail,
  BiDotsVertical,
  BiLinkExternal,
  BiTrashAlt,
  BiX,
} from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { InventoryItem } from '../../../apis';
import { ConfirmDelete } from '../../../shared';

type Props = ui.MenuButtonProps &
  ui.ThemingProps<'ui.MenuButton'> & {
    item: InventoryItem;
    onDelete?: () => void;
  };

export const InventoryItemMenu: React.FC<Props> = ({
  item,
  onDelete,
  ...props
}) => (
  <ConfirmDelete
    header="You sure?"
    body="Remove an item from your inventory cannot be undone."
    onConfirm={() => onDelete?.()}
  >
    {({ onOpen }) => (
      <ui.Menu isLazy>
        {({ isOpen }) => (
          <>
            <ui.MenuButton
              // as={ui.IconButton}
              aria-label="open menu"
              icon={<ui.Icon as={isOpen ? BiX : BiDotsVertical} boxSize={6} />}
              size="lg"
              colorScheme="gray"
              variant="ghost"
              {...props}
            />
            <ui.Portal>
              <ui.MenuList zIndex={99999}>
                <ui.MenuItem
                  as={NavLink}
                  icon={<BiDetail size={18} />}
                  to={`/inventorio/catalog/${item.productId}`}
                >
                  Product Details
                </ui.MenuItem>

                <ui.MenuItem
                  zIndex={99999}
                  icon={<BiLinkExternal size={18} />}
                  onClick={() =>
                    window.open(item.product.productPageUrl, '_blank')
                  }
                >
                  Open Vendor Page
                </ui.MenuItem>
                {onDelete && (
                  <>
                    <ui.MenuDivider />
                    <ui.MenuItem
                      color="danger.500"
                      icon={<BiTrashAlt size={18} />}
                      onClick={onOpen}
                    >
                      Remove
                    </ui.MenuItem>
                  </>
                )}
              </ui.MenuList>
            </ui.Portal>
          </>
        )}
      </ui.Menu>
    )}
  </ConfirmDelete>
);
