import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as ui from '@chakra-ui/react';
import React from 'react';
import { ReactUtils } from '../../lib';
import { useSuperTableContainer } from './SuperTableContainer';
import { ColumnConfig } from './types';
import { ColumnConfigContext } from './useColumnConfigs';

export type Props<T> = {
  itemKey: (item: T) => React.Key;
  items: T[];
};

export type SuperTableProps<T> = ui.HTMLChakraProps<'table'> &
  ui.ThemingProps<'SuperTable'> &
  Props<T> & {
    columns: ColumnConfig[];
  };

function SuperTableInner<T>(
  { columns, itemKey, items, ...innerProps }: SuperTableProps<T>,
  ref: React.ForwardedRef<HTMLTableElement>
) {
  const tableState = useSuperTableContainer();
  const styles = ui.useMultiStyleConfig('SuperTable', innerProps);
  const { children, ...tableHtmlProps } = ui.omitThemingProps(innerProps);
  const internalRef = React.createRef();

  return (
    <DndProvider backend={HTML5Backend}>
      <ColumnConfigContext.Provider value={columns}>
        <ui.StylesProvider value={styles}>
          <ui.chakra.table
            ref={ui.useMergeRefs(internalRef, ref)}
            __css={{ ...styles.table }}
            className={ReactUtils.cx(
              tableState.isAtLeft && 'is-at-left',
              tableState.isAtRight && 'is-at-right',
              tableState.isAtTop && 'is-at-top',
              tableState.isAtBottom && 'is-at-bottom',
              !tableState.isAtLeft && 'not-at-left',
              !tableState.isAtRight && 'not-at-right',
              !tableState.isAtTop && 'not-at-top',
              !tableState.isAtBottom && 'not-at-bottom'
            )}
            {...tableHtmlProps}
          >
            <colgroup>
              {columns.map(({ id, width }) => (
                <col
                  key={id}
                  width={width ?? 200}
                  style={{ transition: 'width .5s' }}
                />
              ))}
            </colgroup>

            {children}
          </ui.chakra.table>
        </ui.StylesProvider>
      </ColumnConfigContext.Provider>
    </DndProvider>
  );
}

SuperTableInner.displayName = 'SuperTable';
export const SuperTable = React.forwardRef(SuperTableInner);
