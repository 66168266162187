import type { protos } from '@google-cloud/iot';
import { formatDistanceToNow } from 'date-fns';
import { StringUtils } from './string.utils';

type UnsafeGcpTimestamp = protos.google.protobuf.ITimestamp;
type GcpTimestamp = { seconds: number; nanos: number };
type GcpBinaryData = { type: 'Buffer'; data: number[] };

export class GcpUtils {
  static binaryDataToString = (binaryData: unknown): string => {
    if (!GcpUtils.isBinaryData(binaryData)) return '';
    return binaryData.data.map(n => String.fromCodePoint(n)).join('');
  };

  static isBinaryData = (val: unknown): val is GcpBinaryData =>
    typeof val === 'object' &&
    val !== null &&
    'type' in val &&
    (val as any).type === 'Buffer' && // eslint-disable-line
    Array.isArray((val as any).data); // eslint-disable-line

  static timestampToText = (
    unsafeTimestamp?: UnsafeGcpTimestamp | null
  ): string => {
    const timestamp = GcpUtils.normalizeTimestamp(unsafeTimestamp);
    if (timestamp.seconds === 0) return 'Never';

    const date = GcpUtils.gcpTimestampToDate(timestamp);

    return StringUtils.capitalize(
      formatDistanceToNow(date, { addSuffix: true, includeSeconds: true })
    );
  };

  private static normalizeTimestamp = (
    unsafeTimestamp?: UnsafeGcpTimestamp | null
  ): GcpTimestamp => {
    if (!unsafeTimestamp) return { seconds: 0, nanos: 0 };
    return {
      nanos: Number.isSafeInteger(+(unsafeTimestamp.nanos ?? 0))
        ? +(unsafeTimestamp.nanos ?? 0)
        : 0,
      seconds: Number.isSafeInteger(+(unsafeTimestamp.seconds ?? 0))
        ? +(unsafeTimestamp.seconds ?? 0)
        : 0,
    };
  };

  private static gcpTimestampToDate = ({
    seconds,
    nanos,
  }: GcpTimestamp): Date => new Date(seconds * 1000 + nanos / 1000000);
}
