import * as DREI from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import { HEXCODES } from '../hexcodes';
import { TILE_SIZE, useStore } from '../useStore';

const BOX_SIZE = 0.2 * TILE_SIZE;

type Props = {};

export const ItemObject = React.forwardRef<THREE.Mesh, Props>((props, ref) => {
  const { items } = useStore();
  return (
    <>
      {items.map(item => (
        <DREI.Box
          key={item.id}
          args={[BOX_SIZE, BOX_SIZE, BOX_SIZE]}
          ref={ref}
          position={[item.position.x, item.position.y, BOX_SIZE / 2 + 0.5]}
        >
          <meshStandardMaterial transparent color={HEXCODES.primary['500']} />
        </DREI.Box>
      ))}
    </>
  );
});
