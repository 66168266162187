import * as ui from '@chakra-ui/react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import React from 'react';
import { useDrag, XYCoord } from 'react-dnd';
import { ColumnConfig } from '.';

export const ResizeHandleDragSourceType = 'ResizeHandle';

type ResizeHandleProps = {
  columnConfig: ColumnConfig;
};

export const ResizeHandle: React.FC<ResizeHandleProps> = React.memo(
  ({ columnConfig }) => {
    const [{ isDragging }, dragRef, preview] = useDrag(
      () => ({
        type: ResizeHandleDragSourceType,
        item: columnConfig,
        collect: monitor => ({ isDragging: monitor.isDragging() }),
      }),
      [columnConfig]
    );

    React.useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: false });
    }, [preview]);

    return (
      <ui.chakra.div
        ref={dragRef}
        pointerEvents="all"
        cursor="ew-resize"
        zIndex={99999}
        pos="absolute"
        right="0"
        left="calc(100% - 10px)"
        bottom="0"
        top="0"
        sx={{
          'th:hover &::after': {
            bg: 'whiteAlpha.200',
          },
          '&::after': {
            content: '""',
            pos: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            opacity: isDragging ? 0.2 : 1,
            transform: 'opacity 1s',
            bottom: 0,
          },
        }}
      />
    );
  }
);

type ActiveResizeHandleProps = ui.HTMLChakraProps<'tr'> & {
  columnConfig: ColumnConfig;
  initialOffset: XYCoord;
  difference: XYCoord;
};

export const ActiveResizeHandle: React.FC<ActiveResizeHandleProps> = React.memo(
  ({ columnConfig, initialOffset, difference, ...props }) => {
    const minDifference = -1 * ((columnConfig.width ?? 200) - 100);
    const transformX = Math.max(difference.x, minDifference);

    return (
      <ui.chakra.tr
        bg="primary.500"
        pointerEvents="all"
        cursor="ew-resize"
        zIndex={99999}
        pos="fixed"
        left={`${initialOffset.x + 4}px`}
        transform={`translate(${transformX}px, 0)`}
        bottom="0"
        top="0"
        width="4px"
        {...props}
      />
    );
  }
);
