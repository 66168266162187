import {
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { Product } from '../../../apis';

type Props = {
  item: Product;
  onChange: (quantity: number) => void;
  value: number;
};

export const ProductSelectItem: React.FC<Props> = ({
  item,
  onChange,
  value,
}) => {
  const isSelected = value > 0;

  return (
    <Flex bg={isSelected ? 'whiteAlpha.50' : 'transparent'} direction="row">
      {/* <HStack spacing={6}> */}
      <VStack spacing={0} w={10} maxW={10} flexShrink={0}>
        <IconButton
          aria-label="increment quantity"
          colorScheme="gray"
          icon={<Icon as={BiChevronUp} boxSize={6} />}
          onClick={() => onChange(value + 1)}
          role="button"
          size="sm"
          tabIndex={-1}
          variant="ghost"
        />
        <Input
          inputMode="numeric"
          pattern="[0-9]*(.[0-9]+)?"
          role="spinbutton"
          type="text"
          my={0}
          onChange={e => onChange(+e.currentTarget.value)}
          size="sm"
          textAlign="center"
          value={value}
        />
        <IconButton
          aria-label="decrement quantity"
          aria-disabled={value < 1}
          colorScheme="gray"
          icon={<Icon as={BiChevronDown} boxSize={6} />}
          onClick={() => onChange(value - 1)}
          role="button"
          size="sm"
          tabIndex={-1}
          variant="ghost"
        />
      </VStack>
      <HStack
        pl={4}
        spacing={4}
        cursor={isSelected ? 'auto' : 'pointer'}
        onClick={() => {
          if (!isSelected) onChange(1);
        }}
      >
        <Image
          alt="product"
          pos="relative"
          objectFit="cover"
          boxSize={16}
          minW={16}
          src={item.productImageUrl}
        />
        <VStack spacing={2} align="flex-start">
          <Heading fontSize={['sm', null, 'md']}>{item.title}</Heading>
          <Heading fontSize={['xs', null, 'sm']}>{item.vendor}</Heading>
        </VStack>
      </HStack>
      {/* </HStack> */}
    </Flex>
  );
};
