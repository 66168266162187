import React, { useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { CatalogSearchCountsContext } from '../useCatalogSearchCounts';
import { getProductsCount } from '../../../apis';
import { useDelay } from '../../../hooks';
import { useCatalogSearchParams } from '../useCatalogSearchParams';

export const CatalogSearchCountsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { activeParams, pendingParams } = useCatalogSearchParams();
  const { query: getActiveCount, ...activeCountQuery } =
    useParameterizedQuery(getProductsCount);
  const { query: getPendingCount, ...pendingCountQuery } =
    useParameterizedQuery(getProductsCount);

  /** Update the active search count when the active params change */
  useEffect(() => {
    getActiveCount(activeParams.q);
  }, [getActiveCount, activeParams.q]);

  /** Update the pending search count when the pending params change */
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const delayedGetPendingCount = useDelay(getPendingCount, 500);

  useEffect(() => {
    delayedGetPendingCount(pendingParams.q);
  }, [delayedGetPendingCount, getPendingCount, pendingParams.q]);

  return (
    <CatalogSearchCountsContext.Provider
      value={{
        activeCount: +(activeCountQuery.payload ?? 0),
        activeCountIsLoading: activeCountQuery.loading,
        pendingCount: +(pendingCountQuery.payload ?? 0),
        pendingCountIsLoading: pendingCountQuery.loading,
      }}
    >
      {children}
    </CatalogSearchCountsContext.Provider>
  );
};
