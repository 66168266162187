export type Part = Location & {
  dir: PartDirection;
  id: number;
} & (Conveyor | Source);

export type PartType = Part['type'];

export type Location = { x: number; y: number };

export enum PartDirection {
  Right,
  Up,
  Left,
  Down,
}

export type Conveyor = {
  type: 'conveyor';
};

export type Source = {
  type: 'source';
};

export type Item = Location & {
  position: THREE.Vector3;
  previousLocation: Location | null;
  id: number;
};
