import { createContext, useContext } from 'react';
import { Product, ResourceParams } from '../../apis';

interface CatalogSearchParamsContext {
  activeParams: ResourceParams<Product>;
  applyParams: (args: ResourceParams<Product>) => void;
  isFiltering: boolean;
  isFiltersEqual: boolean;
  pendingParams: ResourceParams<Product>;
  setPendingFilter: (filter: ResourceParams<Product>['q']) => void;
  setPendingParams: (args: ResourceParams<Product>) => void;
}

export const CatalogSearchParamsContext =
  createContext<CatalogSearchParamsContext>({
    activeParams: { q: {}, sort: {}, offset: 0, n: 0 },
    applyParams: () => null,
    isFiltering: false,
    isFiltersEqual: false,
    pendingParams: { q: {}, sort: {}, offset: 0, n: 0 },
    setPendingFilter: () => null,
    setPendingParams: () => null,
  });

CatalogSearchParamsContext.displayName = 'CatalogSearchParamsContext';

export const useCatalogSearchParams = () =>
  useContext(CatalogSearchParamsContext);
