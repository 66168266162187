import { VStack, Heading, HStack, Box } from '@chakra-ui/layout';
import { Stat, StatLabel, StatNumber, StatHelpText } from '@chakra-ui/stat';
import React from 'react';
import { colors } from '../../theme';

type ColorProps = {
  name: string;
  value: string;
};

const Color: React.FC<ColorProps> = ({ name, value }) => (
  <Stat align="center" size="md" maxW={24}>
    <StatLabel>{name}</StatLabel>
    <StatNumber as={Box} h={24} w={24} bg={value} />
    <StatHelpText isTruncated>{value}</StatHelpText>
  </Stat>
);

export const Colors: React.FC = () => {
  const singleColorRows: ColorProps[] = Object.entries(colors)
    .map(([name, value]) =>
      typeof value === 'string' ? { name, value } : null
    )
    .filter((color): color is ColorProps => !!color);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mappedColorRows: Array<{ name: string; colors: ColorProps[] }> =
    Object.entries(colors)
      .map(([name, value]) =>
        typeof value === 'string'
          ? null
          : {
              name,
              colors: Object.entries(value).map(([name, value]) => ({
                name,
                value,
              })),
            }
      )
      .filter(
        (color): color is { name: string; colors: ColorProps[] } => !!color
      );

  return (
    <>
      <VStack align="flex-start" spacing={8}>
        <Heading size="lg">Single</Heading>

        <HStack spacing={4}>
          {singleColorRows.map(color => (
            <Color key={color.name} {...color} />
          ))}
        </HStack>
      </VStack>
      {mappedColorRows.map(({ name, colors }) => (
        <VStack key={name} align="flex-start" spacing={8}>
          <Heading size="lg">{name}</Heading>
          <HStack spacing={4}>
            {colors.map(color => (
              <Color key={color.name} {...color} />
            ))}
          </HStack>
        </VStack>
      ))}
    </>
  );
};
