/* eslint-disable @typescript-eslint/no-unused-vars */
import { createDescendantContext } from '@chakra-ui/descendant';
import * as ui from '@chakra-ui/react';
import { omitThemingProps } from '@chakra-ui/system';
import React from 'react';
import { useDragLayer, useDrop } from 'react-dnd';
import { ReactUtils } from '../../lib';
import {
  ActiveResizeHandle,
  ResizeHandle,
  ResizeHandleDragSourceType,
} from './ResizeHandle';
import { ColumnConfig, Pin } from './types';
import { useColumnConfigs } from './useColumnConfigs';
import { pinStyles } from './utils';

const [RowDescendantsProvider, , useRowDescendants, useRowDescendent] =
  createDescendantContext();

/**
 * Tbody
 */
export type SuperTbodyProps = Omit<ui.HTMLChakraProps<'tbody'>, 'as'> &
  ui.ThemingProps<'SuperTable'> & {
    ref?: React.RefObject<HTMLTableSectionElement>;
  };

export function SuperTbody({ children, ref, ...props }: SuperTbodyProps) {
  const parts = ui.useStyles();
  const htmlProps = omitThemingProps(props);

  return (
    <ui.chakra.tbody ref={ref} __css={{ ...parts.tbody }} {...htmlProps}>
      {children}
    </ui.chakra.tbody>
  );
}

SuperTbody.displayName = 'SuperTbody';

/**
 * Td
 */
export type SuperTdProps = Omit<ui.HTMLChakraProps<'td'>, 'as'> &
  ui.ThemingProps<'SuperTable'> & {
    ref?: React.RefObject<HTMLTableCellElement>;
  } & {
    isEditable?: boolean;
    pin?: Pin | Pin[];
  };

export function SuperTd({
  children,
  isEditable = false,
  pin = [],
  ref,
  ...props
}: SuperTdProps) {
  const { index, register } = useRowDescendent();
  const columns = useColumnConfigs();
  const columnConfig: ColumnConfig | undefined = columns[index];
  const isSorting = columnConfig?.isSorting;
  const isSorted =
    typeof columnConfig?.onSort === 'function' &&
    (isSorting === 1 || isSorting === -1)
      ? isSorting
      : false;
  const parts = ui.useStyles();
  const styles = {
    ...parts.column,
    ...parts.row,
    ...parts.td,
    ...pinStyles([pin].flat()),
    ...(isEditable && { pos: 'relative' }),
    ...(isSorted && { bgColor: 'whiteAlpha.50' }),
  };

  const htmlProps = omitThemingProps(props);

  return (
    <ui.chakra.td
      ref={ui.useMergeRefs<HTMLTableCellElement | null>(ref, register)}
      __css={styles}
      className={ReactUtils.cx([pin].flat().map(p => `pin-${p}`))}
      // Pinned cells will have a drop-shadow so we need to show overflow
      overflow={[pin].flat().length > 0 ? 'visible !important' : undefined}
      {...htmlProps}
    >
      {children}
    </ui.chakra.td>
  );
}

SuperTd.displayName = 'SuperTd';

/**
 * SortArrow,
 * Th
 */
const SortArrow: React.FC<ui.HTMLChakraProps<'svg'>> = props => (
  <ui.chakra.svg role="presentation" viewBox="0 0 6 5" {...props}>
    <path fill="currentColor" d="M 0 0 l 3 5 3 -5 z" />
  </ui.chakra.svg>
);

export type SuperThProps = Omit<ui.HTMLChakraProps<'th'>, 'as'> &
  ui.ThemingProps<'SuperTable'> & {
    pin?: Pin | Pin[];
    ref?: React.RefObject<HTMLTableCellElement>;
  };

export function SuperTh({ children, pin = [], ref, ...props }: SuperThProps) {
  const { index, register } = useRowDescendent();
  const columns = useColumnConfigs();
  const columnConfig: ColumnConfig | undefined = columns[index];
  const parts = ui.useStyles();
  const htmlProps = omitThemingProps(props);
  const isResizable = typeof columnConfig?.onResize === 'function';
  const isSortable = typeof columnConfig?.onSort === 'function';
  const isSorting = columnConfig?.isSorting;
  const isSorted =
    isSortable && (isSorting === 1 || isSorting === -1) ? isSorting : false;
  const isSortingDesc = isSorted === -1;

  const styles = {
    ...parts.column,
    ...parts.th,
    bgColor: 'black',
    ...pinStyles([pin].flat()),
    ...(isSorted && { bgColor: 'whiteAlpha.50' }),
  };

  return (
    <ui.chakra.th
      ref={ui.useMergeRefs<HTMLTableCellElement | null>(ref, register)}
      __css={styles}
      className={ReactUtils.cx([pin].flat().map(p => `pin-${p}`))}
      {...htmlProps}
    >
      {children}
      {isSortable && (
        <ui.IconButton
          aria-label="sort"
          icon={
            <ui.Icon
              as={SortArrow}
              boxSize={3}
              color={isSorted ? 'accent.500' : 'whiteAlpha.500'}
              ml={4}
              sx={{ _active: { color: 'accent.200' } }}
              transformOrigin="center"
              transition="transform .3s"
              transform={`scale(${isSorted ? 1.3 : 1}) ${
                isSortingDesc ? 'rotateX(-180deg)' : ''
              }`}
            />
          }
          onClick={columnConfig?.onSort}
          size="md"
          theme="ghost"
        />
      )}
      {isResizable && columnConfig && (
        <ResizeHandle columnConfig={columnConfig} />
      )}
    </ui.chakra.th>
  );
}

SuperTh.displayName = 'SuperTh';

/**
 * Thead
 */
export type SuperTheadProps = Omit<ui.HTMLChakraProps<'thead'>, 'as'> &
  ui.ThemingProps<'SuperTable'> & {
    ref?: React.RefObject<HTMLTableSectionElement>;
  };

export function SuperThead({ children, ref, ...props }: SuperTheadProps) {
  const parts = ui.useStyles();
  const styles = { ...parts.thead, pos: 'relative' };
  const htmlProps = omitThemingProps(props);

  const { itemType, isDragging, item, initialOffset, difference } =
    useDragLayer(monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      difference: monitor.getDifferenceFromInitialOffset(),
      isDragging: monitor.isDragging(),
    }));

  const [, drop] = useDrop(
    () => ({
      accept: ResizeHandleDragSourceType,
      drop: (item: ColumnConfig, monitor) => {
        const difference = monitor.getDifferenceFromInitialOffset();
        const currentWidth = item.width ?? 200;
        const minDifference = -1 * (currentWidth - 100);
        const transformX = Math.max(difference?.x ?? 0, minDifference);

        item?.onResize?.(currentWidth + transformX);
      },
    }),
    []
  );

  return (
    <ui.chakra.thead
      ref={ui.useMergeRefs(ref, drop as any)}
      __css={styles}
      {...htmlProps}
    >
      {isDragging &&
        itemType === ResizeHandleDragSourceType &&
        item &&
        initialOffset &&
        difference && (
          <ActiveResizeHandle
            initialOffset={initialOffset}
            difference={difference}
            columnConfig={item as ColumnConfig}
          />
        )}
      {children}
    </ui.chakra.thead>
  );
}

SuperThead.displayName = 'SuperThead';

/**
 * Tr
 */
export type SuperTrProps = Omit<ui.HTMLChakraProps<'tr'>, 'as'> &
  ui.ThemingProps<'SuperTable'> & {
    pin?: Pin | Pin[];
    ref?: React.RefObject<HTMLTableRowElement>;
  };

export function SuperTr({ children, pin = [], ref, ...props }: SuperTrProps) {
  const descendants = useRowDescendants();
  const parts = ui.useStyles();
  const styles = { ...parts.row, ...parts.tr, ...pinStyles([pin].flat()) };
  const htmlProps = omitThemingProps(props);

  return (
    <RowDescendantsProvider value={descendants}>
      <ui.chakra.tr
        ref={ref}
        className={ReactUtils.cx([pin].flat().map(p => `pin-${p}`))}
        __css={styles}
        {...htmlProps}
      >
        {children}
      </ui.chakra.tr>
    </RowDescendantsProvider>
  );
}

SuperTr.displayName = 'SuperTr';
