import * as ui from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import React from 'react';
import { QueryResponse } from 'react-fetching-library';

type Value = React.InputHTMLAttributes<any>['value'];

type Props<V = string> = ui.InputProps & {
  label?: React.ReactNode;
  onSave: (value: V) => Promise<QueryResponse<any>>;
  transformInputValue?: (value: V) => Value;
  transformOnChange?: (value: Value) => V;
  value: V;
};

export function EditCellValuePopover<V>({
  label = 'Edit',
  onSave,
  transformInputValue = v => v as unknown as Value,
  transformOnChange = v => v as unknown as V,
  value: initValue,
  ...inputProps
}: Props<V>) {
  const initialFocusRef = React.useRef<FocusableElement>();
  const [value, setValue] = React.useState<V>(initValue);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <ui.Popover
        isLazy
        isOpen={isOpen}
        initialFocusRef={initialFocusRef as any}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <ui.PopoverTrigger>
          <ui.chakra.button
            pos="absolute"
            bg="transparent"
            top="2px"
            left="2px"
            w="calc(100% - 2px)"
            h="calc(100% - 2px)"
            tabIndex={1}
            pointerEvents="all"
            border="2px"
            borderColor="transparent"
            sx={{
              cursor: 'text',
              _hover: { borderColor: 'whiteAlpha.700' },
              _active: { borderColor: 'whiteAlpha.900' },
              _focus: { borderColor: 'whiteAlpha.200' },
              _focusVisible: { borderColor: 'whiteAlpha.200', outline: 'none' },
              ...(isOpen && {
                border: 'none',
                bg: 'whiteAlpha.200',
                cursor: 'none',
              }),
            }}
          />
        </ui.PopoverTrigger>
        <ui.Portal>
          <ui.PopoverContent>
            <ui.PopoverArrow />
            <ui.VStack as="form" bg="black" spacing={6} p={6}>
              <ui.FormControl id="popover-control" isDisabled={isLoading}>
                <ui.FormLabel htmlFor="popover-control">{label}</ui.FormLabel>
                <ui.Input
                  type="text"
                  ref={initialFocusRef as any}
                  onChange={e => setValue(transformOnChange(e.target.value))}
                  value={transformInputValue(value)}
                  {...inputProps}
                />
              </ui.FormControl>
              <ui.ButtonGroup d="flex" justifyContent="flex-end" w="full">
                <ui.Button
                  isDisabled={isLoading}
                  onClick={() => setIsOpen(false)}
                  variant="ghost"
                  colorScheme="gray"
                >
                  Cancel
                </ui.Button>
                <ui.Button
                  isLoading={isLoading}
                  onClick={async e => {
                    e.preventDefault();
                    setIsLoading(true);
                    const { error } = await onSave(value);
                    setIsLoading(false);
                    if (!error) setIsOpen(false);
                  }}
                  type="submit"
                  colorScheme="primary"
                >
                  Save
                </ui.Button>
              </ui.ButtonGroup>
            </ui.VStack>
          </ui.PopoverContent>
        </ui.Portal>
      </ui.Popover>
    </>
  );
}
