import { useToast } from '@chakra-ui/react';

export const useClipboard = () => {
  const toast = useToast();

  return {
    copy: async (stringToCopy: string, notificationMessage?: string) => {
      await navigator.clipboard.writeText(stringToCopy);
      toast({
        title: notificationMessage ?? 'Copied to clipboard',
      });
    },
  };
};
