export class QueryParamUtils {
  static readonly queryParamsFromSearch = (
    search?: string
  ): Record<string, string> => {
    const [, query = ''] = (search ?? '').split('?');
    return Object.fromEntries(
      query
        .split('&')
        .map(pairStr => {
          const [k = '', v = ''] = pairStr.split('=');
          return [k, v] as const;
        })
        .filter(([k]) => k.length > 0)
    );
  };

  static readonly searchFromQueryParams = (
    queryParams?: Record<string, any>
  ): string =>
    `?${Object.entries(queryParams ?? {})
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .map(([k, v]) => [
        k,
        typeof v === 'object' && v !== null ? JSON.stringify(v) : v,
      ])
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
}
