import { useFrame } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';
import { HEXCODES } from '../hexcodes';
import { PartObjectProps } from '../PartObject';
import { TILE_SIZE, useStore } from '../useStore';
import { nextLocation } from '../utils';

const CONVEYOR_SIZE = 0.95 * TILE_SIZE;
const CONVEYOR_HEIGHT = 0.5;

type Props = PartObjectProps;

const TEMP_CENTER = new THREE.Vector3();

const createTexture = (beltColor: string, arrowColor: string) => {
  const c = document.createElement('canvas');
  c.width = c.height = 256;
  const ctx = c.getContext('2d')!;
  ctx.fillStyle = beltColor;
  ctx.fillRect(0, 0, c.width, c.height);
  ctx.strokeStyle = arrowColor;
  ctx.lineWidth = 10;

  ctx.beginPath();
  ctx.moveTo(0, -20);
  ctx.lineTo(c.width, c.height + 20);
  ctx.stroke();

  const texture = new THREE.CanvasTexture(c);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.MirroredRepeatWrapping;
  texture.repeat.set(4, 2);

  return texture;
};

const BELT_TEXTURE = createTexture(
  HEXCODES.gray['700'],
  HEXCODES.accent['700']
);
const HOVERED_BELT_TEXTURE = createTexture(
  HEXCODES.gray['400'],
  HEXCODES.accent['700']
);
const SELECTED_BELT_TEXTURE = createTexture(
  HEXCODES.accent['600'],
  HEXCODES.gray['900']
);

const GEOMETRY = (() => {
  const path = new THREE.Path();
  const radius = (0.95 * TILE_SIZE) / 2;
  const height = CONVEYOR_HEIGHT / 2;
  path.absarc(radius, 0, height, Math.PI * 0.5, Math.PI * 1.5, true);
  path.absarc(-1 * radius, 0, height, Math.PI * 1.5, Math.PI * 0.5, true);
  path.closePath();

  const g = new THREE.PlaneGeometry(1, 1, 200, 1);
  path
    .getSpacedPoints(200)
    .reverse()
    .forEach((p, idx) => {
      g.attributes.position.setXYZ(idx, p.x, -CONVEYOR_SIZE / 2, p.y);
      g.attributes.position.setXYZ(idx + 201, p.x, CONVEYOR_SIZE / 2, p.y);
    });

  return g;
})();

export const ConveyorObject = React.forwardRef<THREE.Group, Props>(
  ({ dir, isHovered, isSelected, x, y }, ref) => {
    const { ...store } = useStore();
    const geometryRef = React.useRef(GEOMETRY);
    const materialRef = React.useRef<THREE.MeshStandardMaterial>(null!);
    const boxRef = React.useRef<THREE.Mesh>(null!);
    const previousSecondsRef = React.useRef<number>(null!);
    const millisecondsRef = React.useRef<number>(null!);

    if (boxRef.current) boxRef.current.getWorldPosition(TEMP_CENTER);

    const items = store.items.filter(item => item.x === x && item.y === y);
    useFrame(s => {
      if (materialRef) {
        materialRef.current.map!.offset.x = s.clock.getElapsedTime();
      }

      if (boxRef.current) {
        // console.log(boxRef.current.getWorldPosition());
      }

      const time = s.clock.getElapsedTime();
      const seconds = Math.floor(time);
      millisecondsRef.current = time - seconds;

      // If no time ref yet, set it and wait for next from to act
      if (!previousSecondsRef.current) {
        return (previousSecondsRef.current = seconds);
      }

      // If we're at a new second, move the items to their next positions
      if (previousSecondsRef.current !== seconds) {
        // actions.updateItems(
        //   items.map(({ x, y, ...item }) => {
        //     const next = nextLocation(x, y, dir);
        //     return {
        //       ...item,
        //       ...next,
        //       previousLocation: { x, y },
        //     };
        //   })
        // );
        items.forEach(item => {
          const { x, y } = item;
          const next = nextLocation(x, y, dir);
          item.previousLocation = { x, y };
          item.x = next.x;
          item.y = next.y;
        });
      }

      // Move incoming items toward the center
      if (millisecondsRef.current < 500 && boxRef.current) {
        items.forEach(({ position }) => {
          position.x = THREE.MathUtils.lerp(position.x, TEMP_CENTER.x, 0.5);
          position.y = THREE.MathUtils.lerp(position.y, TEMP_CENTER.y, 0.5);
        });
      }

      previousSecondsRef.current = seconds;
    });

    return (
      <mesh ref={boxRef} position={[0, 0, CONVEYOR_HEIGHT / 2 + 0.001]}>
        <planeGeometry {...geometryRef.current} />
        <meshStandardMaterial
          ref={materialRef}
          map={
            isSelected
              ? SELECTED_BELT_TEXTURE
              : isHovered
              ? HOVERED_BELT_TEXTURE
              : BELT_TEXTURE
          }
          side={THREE.DoubleSide}
        />
      </mesh>
    );
  }
);
