import {
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  VStack,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import React, { useState } from 'react';

type Props = {
  isLoading: boolean;
  onSubmit: (name: string) => void;
  trigger: React.ReactNode;
};

export const CreateInventoryPopover: React.FC<Props> = ({
  isLoading,
  onSubmit,
  trigger,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [name, setName] = useState('');
  const initialFocusRef = React.useRef<FocusableElement>();

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      initialFocusRef={initialFocusRef as any}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <VStack as="form" bg="black" spacing={6} p={6}>
          <FormControl id="inventory-name" isDisabled={isLoading}>
            <FormLabel>Inventory Name</FormLabel>
            <Input
              type="text"
              ref={initialFocusRef as any}
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </FormControl>
          <ButtonGroup d="flex" justifyContent="flex-end" w="full">
            <Button
              isDisabled={isLoading}
              onClick={onClose}
              variant="ghost"
              colorScheme="gray"
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={e => {
                e.preventDefault();
                onSubmit(name);
              }}
              type="submit"
              colorScheme="primary"
            >
              Create
            </Button>
          </ButtonGroup>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};
