import type { Filter } from 'mongodb';
import { Action } from 'react-fetching-library';
import { QueryParamUtils } from '../../lib';
import { Hostname } from '../hostnames';
import { ResourceParams } from '../lib';
import { Product } from './catalog';

export interface Inventory {
  readonly _id: string;
  description: string;
  name: string;
  readonly updated: string;
  readonly userId: string;
}

export type InventoryDto = Pick<Inventory, 'description' | 'name'>;

export const inventoryToDto = ({
  description,
  name,
}: Inventory): InventoryDto => ({ description, name });

export const createInventory = (body: InventoryDto): Action<Inventory> => ({
  body,
  endpoint: `/inventories`,
  hostname: Hostname.Inventorio,
  method: 'POST',
  onError: ({ errorMessages }) => ({
    title: 'Inventory creation failed',
    description: errorMessages?.[0],
  }),
  onSuccess: () => ({ title: 'Inventory created' }),
});

export const deleteInventory = (id: string): Action<{ _id: string }> => ({
  endpoint: `/inventories/${id}`,
  hostname: Hostname.Inventorio,
  method: 'DELETE',
  onSuccess: () => ({ title: 'Inventory deleted' }),
});

export const getInventories = (
  filter: Filter<Inventory> = {}
): Action<Inventory[]> => {
  return {
    endpoint: `/inventories?q=${JSON.stringify(filter)}`,
    hostname: Hostname.Inventorio,
    method: 'GET',
  };
};

export const getInventory = (id: string): Action<Inventory> => ({
  endpoint: `/inventories/${id}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const getInventoryItemCount = (id: string): Action<number> => ({
  endpoint: `/inventories/${id}/items/count`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const updateInventory = ({
  _id,
  inventory,
}: {
  _id: string;
  inventory: InventoryDto;
}): Action<Inventory> => ({
  body: inventory,
  endpoint: `/inventories/${_id}`,
  hostname: Hostname.Inventorio,
  method: 'PATCH',
  onError: ({ errorMessages }) => ({
    title: 'Failed to save',
    description: errorMessages?.[0],
  }),
  onSuccess: () => ({ title: 'Inventory saved' }),
});

export interface InventoryItem {
  readonly _id: string;
  inventoryId: string;
  notes: string;
  productId: string;
  product: Product;
  projects: string[];
  quantity: number;
  updated: string;
  userId: string;
}

export type CreateInventoryItemDto = Omit<
  InventoryItem,
  '_id' | 'inventoryId' | 'product' | 'updated' | 'userId'
>;

export type UpdateInventoryItemDto = Omit<
  InventoryItem,
  '_id' | 'inventoryId' | 'product' | 'productId' | 'updated' | 'userId'
>;

export const getUnaddedProductsCount = ({
  q = {},
  inventoryId,
}: {
  inventoryId: string;
  q: Filter<Product>;
}): Action<Product[]> => ({
  endpoint: `/inventories/${inventoryId}/catalog/count${QueryParamUtils.searchFromQueryParams(
    { q }
  )}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const getUnaddedProducts = ({
  params,
  inventoryId,
}: {
  inventoryId: string;
  params: ResourceParams<Product>;
}): Action<Product[]> => ({
  endpoint: `/inventories/${inventoryId}/catalog${QueryParamUtils.searchFromQueryParams(
    params
  )}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const updateInventoryItemToDto = ({
  _id,
  inventoryId,
  productId,
  updated,
  userId,
  ...product
}: InventoryItem): UpdateInventoryItemDto => ({ ...product });

export const createInventoryItem = ({
  inventoryId,
  body,
}: {
  inventoryId: string;
  body: CreateInventoryItemDto;
}): Action<InventoryItem> => ({
  body,
  endpoint: `/inventories/${inventoryId}/items`,
  hostname: Hostname.Inventorio,
  method: 'POST',
  onError: ({ errorMessages }) => ({
    title: 'Inventory item creation failed',
    description: errorMessages?.[0],
  }),
  onSuccess: () => ({ title: 'Inventory item created' }),
});

export const deleteInventoryItem = ({
  inventoryId,
  itemId,
}: Record<'inventoryId' | 'itemId', string>): Action<{ _id: string }> => ({
  endpoint: `/inventories/${inventoryId}/items/${itemId}`,
  hostname: Hostname.Inventorio,
  method: 'DELETE',
  onSuccess: () => ({ title: 'Item deleted' }),
  onError: ({ errorMessages }) => ({
    title: 'Delete item failed',
    description: errorMessages?.[0],
  }),
});

type GetInventoryItemsAction = {
  id: string;
  params: ResourceParams<InventoryItem>;
};

export const getInventoryItems = ({
  id,
  params,
}: GetInventoryItemsAction): Action<InventoryItem[]> => ({
  endpoint: `/inventories/${id}/items${QueryParamUtils.searchFromQueryParams(
    params
  )}`,
  hostname: Hostname.Inventorio,
  method: 'GET',
});

export const updateInventoryItem = ({
  inventoryId,
  body,
  itemId,
}: {
  inventoryId: string;
  body: Partial<UpdateInventoryItemDto>;
  itemId: string;
}): Action<InventoryItem> => ({
  body,
  endpoint: `/inventories/${inventoryId}/items/${itemId}`,
  hostname: Hostname.Inventorio,
  method: 'PATCH',
  onError: ({ errorMessages }) => ({
    title: 'Inventory item update failed',
    description: errorMessages?.[0],
  }),
});
